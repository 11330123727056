import { Injectable } from '@angular/core';

import {
	ApiService
} from "./api.service";

import {
	PermisosCtrlDocConstante,
} from "./../config/constantes";

@Injectable()
export class PermisosCtrlDocService {
	constructor(
		private _permisosConstante: PermisosCtrlDocConstante,
		private _apiServicio: ApiService) {
	}

	ObtenerPermisosCarpeta(carpetaID) {
		return this._apiServicio.Get(this._permisosConstante.URLObtenerPermisosCarpeta + "?carpetaID=" + carpetaID);
	}

	EliminarPermiso(permisosCDID) {
		return this._apiServicio.Get(this._permisosConstante.URLEliminarPermiso + "?permisosCDID=" + permisosCDID);
	}

	AgregarPermisosCarpeta(permiso) {
		return this._apiServicio.Get(this._permisosConstante.URLAgregarPermisosCarpeta
			+ "?nCarpeta=" + permiso.carpetaID
			+ "&tipoPermiso=" + permiso.tipoPermiso
			+ "&nombrePermiso=" + permiso.nombrePermiso
			+ "&registroID=" + permiso.registroID
			+ "&nombreRegistro=" + permiso.nombreRegistro
			+ "&UsuarioCreacionID=" + permiso.usuarioID
			+ "&PuestoUDN=" + permiso.PuestoUDN
			);
	}
}
