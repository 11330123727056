import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { JuntasConstantes } from '../config/constantes';

@Injectable({
  providedIn: 'root'
})
export class AArqJuntasService {

  constructor(   
    private _constantes: JuntasConstantes,
    private _apiServicio: ApiService) { }

    //CONSULTAR JUNTAS APLICATIVO JUNTAS.

    obtenerJuntaNombreArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaNombreArq
        +'?nombre='+datos.nombre
        +'&rol='+datos.rol)
    }

    obtenerJuntaNumeroArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaNumeroArq
        +'?numero='+datos.numero
        +'&rol='+datos.rol)
    }

    //CONSULTAR JUNTAS MI GESTION AREA ARQ. JUNTAS.

    obtenerJuntaMiGestionNombreArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaMiGestionNombreArq
        +'?numero='+datos.numero
        +'&rol='+datos.rol)
    }

    obtenerJuntaMiGestionNumeroArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaMiGestionNumeroArq
        +'?numero='+datos.numero
        +'&rol='+datos.rol)
    }

    obtenerNombreRolesArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNombreRolesArq+'?IdJunta='+datos)
    }

    ObtenerNombreRolSargentoMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNombreRolSargentoMG+'?IdJunta='+datos)
    }

    obtenerPremisesArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerPremisesArq+'?IdJunta='+datos)
    }

    obtenerAgendaTipoArq(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerAgendaTipoArq+'?IdJunta='+datos)
    }

    validarExisteJunta(datos){
      return this._apiServicio.Get(this._constantes.URLvalidarExisteJunta+'?IdJunta='+datos)
    }

    guardarJuntaenAArq(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarJuntaenAArq)
    }

    //JUNTAS MI GESTION AREA DE ARQ.

    obtenerJuntaNombreMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaNombreMG
        +'?nombre='+datos.nombre
        +'&rol='+datos.rol)
    }

    obtenerJuntaNumeroMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntaNumeroMG
        +'?numero='+datos.numero
        +'&rol='+datos.rol)
    }

    obtenerNombreRolesMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNombreRolesMG+'?IdJunta='+datos)
    }

    obtenertimeMG(){
      return this._apiServicio.Get(this._constantes.URLObtenertimeMG)
    }

    obtenerPremisesMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerPremisesMG+'?IdJunta='+datos)
    }

    obtenerAgendaTipoMG(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerAgendaTipoMG+'?IdJunta='+datos)
    }

    agregarParticipanteMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLAgregarParticipanteMG)
    }

    agregarPremiseMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLAgregarPremiseMG)
    }

    agregarAgendaTipoMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLAgregarAgendaTipoMG)
    }

    guardarJuntaenMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarJuntaenMG)
    }
    
    eliminarJuntaMG(datos){
      return this._apiServicio.Get(this._constantes.URLEliminarJuntaMG+'?IdJunta='+datos)
    }

    eliminarAgendaTipoMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLEliminarAgendaTipoMG)
    }

    eliminarParticipanteMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLEliminarParticipanteMG)
    }

    //CREAR JUNTAS MI GESTION AREA DE ARQ.

    consultaUltimaJunta(){
      return this._apiServicio.Get(this._constantes.URLConsultaUltimaJunta)
    }

    guardarJuntaCreada(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarJuntaCreada)
    }

    //REPORTES JUNTA

    obtenerReporteJuntaNombreMG(datos){
      return this._apiServicio.Post(datos, this._constantes.URLObtenerReporteJuntaNombreMG)
    }

    obtenerReporteJuntaNombreSJ(datos){
      return this._apiServicio.Post(datos, this._constantes.URLObtenerReporteJuntaNombreSJ)
    }

    // obtenerReporteJuntaNumeroMG(datos){
    //   return this._apiServicio.Post(datos, this._constantes.URLObtenerReporteJuntaNumeroMG)
    // }
}