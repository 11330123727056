import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

//Constantes
import { 
	ConfiguracionConstante 
} from '../config/constantes';

@Injectable()
export class ApiService {
  constructor(
    public http: HttpClient,
	private _constante : ConfiguracionConstante,
	private _cockieService: CookieService
  ) {
  }

  Delete(ruta: string) {
    return this.http.delete(this._constante.API_ENDPOINT + ruta, { headers: this.Cabeceros() });
  }

  Put(objeto: any, ruta: string) {
    return this.http.put(this._constante.API_ENDPOINT + ruta, objeto, { headers: this.Cabeceros() });
  }

  Post(objeto: any, ruta: string) {
    return this.http.post(this._constante.API_ENDPOINT + ruta, objeto, { headers: this.Cabeceros() });
  }
  // objeto:any,
  PostConImagen(ruta:string,imagen:File){
    const formData= new FormData();// Crear un objeto FormData para enviar la imagen y otros datos
    formData.append('imagen',imagen);//AGREGAMOS DATOS AL OBJETO
    //formData.append('idCheckList',objeto.idCheckList);

    //CABECEROS
    const headers= new HttpHeaders();
    headers.append('Content-Type','multipart/form-data');/// Seleccionar el tipo de contenido

    return this.http.post(this._constante.API_ENDPOINT + ruta , formData,{headers: headers});

  }

  Get(ruta: string, parametros: HttpParams = new HttpParams()) {

    return this.http.get<any>(this._constante.API_ENDPOINT + ruta, {
      headers: this.Cabeceros(),
      params: parametros
    });
  }


  Upload(objeto: any, ruta: string) {
    return this.http.post(this._constante.API_ENDPOINT + ruta, objeto, { headers: this.Cabecerosarchivo() });
  }


  Download(ruta: string, parametros: HttpParams = new HttpParams()) {
    return this.http.get(this._constante.API_ENDPOINT + ruta, {
      headers: this.Cabeceros(),
      params: parametros,
      responseType: 'blob'
    });
  }

  private Cabeceros() {
    let currentUser = (this._cockieService.get('session')) ? JSON.parse(this._cockieService.get('session')) : null;
    if (currentUser && currentUser.token) {
		return new HttpHeaders({
			'Content-Type':  'application/json',
			'Authorization': 'Bearer ' + currentUser.token 
		});
    }
  }

  private Cabecerosarchivo() {
    let currentUser = (this._cockieService.get('session')) ? JSON.parse(this._cockieService.get('session')) : null;
    if (currentUser && currentUser.token) {
		return new HttpHeaders({
      'Content-Type':  'application/pdf',
      'Authorization': 'Bearer ' + currentUser.token
		});
    }
  }

  PostArchivos(objeto: any, ruta: string) {
    console.log(objeto);
    return this.http.post(this._constante.API_ENDPOINT + ruta, objeto, { headers: this.CabecerosMultiplesArchivos() });
  }

  private CabecerosMultiplesArchivos() {
    let currentUser = (this._cockieService.get('session')) ? JSON.parse(this._cockieService.get('session')) : null;
    if (currentUser && currentUser.token) {
		return new HttpHeaders({
      'Content-Type':  'undefined',
      'Authorization': 'Bearer ' + currentUser.token
		});
    }
  }

  PostCI(objeto: any, ruta: string) {
    return this.http.post(this._constante.API_ENDPOINT_CI + ruta, objeto);
  }
}