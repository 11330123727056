import { Component, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster/angular2-toaster';


@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    toasterConfig: any;
    toasterconfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-bottom-right',
        showCloseButton: true,
        tapToDismiss: false,
        timeout: 0
    });
    //Variable para guardar la session
    public session: any;

    constructor() { }
    
    ngOnInit() { }
    
    public redirect(event){
        // console.log("NOTIFICACION--->",event)
        // console.log("MENSAJE---->", event.path[1].innerText);
    }
}
