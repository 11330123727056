import { Injectable } from '@angular/core';
import {
  ApiService
} from "./api.service";

import {
  SolicitudCtrlDocumentalConstante
} from "./../config/constantes";
import { Buffer } from 'buffer';
@Injectable({
  providedIn: 'root'
})
export class SolicitudCtrlDocumentalService {

  constructor(
    private _solicitudCambioConstantes: SolicitudCtrlDocumentalConstante,
    private _apiServicio: ApiService) {
  }



   Guardar(solicitud, idRelacionados, datosCorreo) { 
     solicitud.tipoCambio = datosCorreo.tipoCambio;
     solicitud.tipoDocumento = datosCorreo.tipoDocumento;
     solicitud.nombreDocumento = datosCorreo.nombreDocumento;
     solicitud.solicitante = datosCorreo.solicitante;
     solicitud.udn = datosCorreo.udn;
     solicitud.IdRelacionados = idRelacionados;
  
    return this._apiServicio.Post(solicitud, this._solicitudCambioConstantes.URLGuardarSolicitud);

  }

  GuardarDocumentoSolicitud(Archivo, NombreDocumentoCargado ) {
    
    return  this._apiServicio.Upload(Archivo, this._solicitudCambioConstantes.URLGuardarDocumentoSolicitud
      + '?NombreDocumentoCargado=' + NombreDocumentoCargado);
  }

  ConsultarSolicitudes(filtro) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLConsultarSolicitudes +
      '?Filtro=' + filtro.Filtro +
      "&IdUsuario=" + filtro.IdUsuario);
  }


  CancelarSolicitud(datos) {
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLCancelarSolicitudes
      + '?IdUsuario=' + datos.IdUsuario +
      '&Id=' + datos.Id);
  }

  ConsultarRelacionados(idDocumento) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLConsultarRelacionados + '?IdDocumento=' + idDocumento);
  }


  ConsultarRelacionadosSolicitud(idSolicitud) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLConsultarRelacionadosSolicitud + '?IdSolicitud=' + idSolicitud);
  }

  ConsultarSolicitudesAutorizacion(idUsuario, idSolicitud, estatus, consultaMatrices, rolId) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerSolicitudesAutorizacion +
      '?IdUsuario=' + idUsuario +
      '&IdSolicitud=' + idSolicitud +
      '&Estado=' + estatus +
      '&ConsultaMatrices=' + consultaMatrices +
      '&RolId=' + rolId
    );
  }

  ObtenerDocumento(ruta) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerDocumento +
      '?Ruta=' + ruta);
  }

  ObtenerDocumentoCtrlDoc(ruta, nombreDocumento, extension) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerDocumentoCtrlDoc +
      '?Ruta=' + ruta +
      '&NombreDocumento=' + nombreDocumento +
      '&extension=' + extension);
  }

  AutorizarSolicitud(datos) { 
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLAutorizarSolicitud   

    );
  }

  ReenviarSolicitud(solicitud) {
    return this._apiServicio.Post(solicitud, this._solicitudCambioConstantes.URLReenviarSolicitud   
    );
  }

  EnviarMatrizSeguimiento(datos) {
    datos.IdSolicitudes = datos.IdSolicitudes;
    datos.IdUsuario = datos.IdUsuario;
    datos.IdDocumentoMatriz = datos.IdDocumento;
    datos.Descripcion = datos.Descripcion;
    datos.NombreDocumento = datos.NombreDocumento;
    datos.UnidadesNegocio = datos.UnidadesNegocio;
    datos.NombreDocumentoCargado = datos.nombreDocumentoCargado;
    datos.TipoCambio = datos.TipoCambio;
    datos.Homologado = datos.Homologado;
    datos.IdAdjuntos = datos.IdAdjuntos;
    datos.RutaDocumento = datos.RutaDocumento;
 
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLEnviarMatrizSeguimiento);
  }

  ReenviarMatrizSeguimiento(idSolicitud, descripcion, IdSolicitudCtrlDoc) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLReenviarMatrizSeguimiento +
      '?IdSolicitud=' + idSolicitud +
      '&Descripcion=' + encodeURIComponent(descripcion) +
      '&IdSolicitudCtrlDoc=' + IdSolicitudCtrlDoc);
  }

  VinculacionSolRechazadasMS(datos) {
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLVinculacionSolRechazadasMS +
      '?IdSolicitud=' + datos.IdSolicitud +
      '&opc=' + datos.opc + 
      '&IdSolicitudCtrlDoc=' + datos.IdSolicitudCtrlDoc);
  }

  ConsultaComentarios(idSolicitud) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLComentariosSeguimiento +
      '?IdSolicitud=' + idSolicitud);
  }

  ConsultaComentariosPorRol(idSolicitud, idRol) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLComentariosSeguimientoPorRol +
      '?IdSolicitud=' + idSolicitud + '&IdRol=' + idRol);
  }

  ActualizarGteArquitectura(idSolicitud, autorizaGteArquitectura) {
    return this._apiServicio.Post("", this._solicitudCambioConstantes.URLActualizarGteArquitectura +
      '?IdSolicitud=' + idSolicitud + '&AutorizaGteArquitectura=' + autorizaGteArquitectura);
  }

  EnviarAutorizacionMatrizSeguimiento(datos) {
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLAutorizarMatrizSeguimiento +
      '?IdSolicitud=' + datos.idSolicitud +
      '&Estatus=' + datos.estatus +
      '&Autorizada=' + datos.autorizada +
      '&IdUsuario=' + datos.usuarioId +
      '&IdRol=' + datos.idRol +
      '&Comentario=' + encodeURIComponent(datos.comentario)
    );
  }

  ActualizarDocumentoSolicitud(datos) {

    return this._apiServicio.Upload(datos.archivo, this._solicitudCambioConstantes.URLActualizarDocumentoSolicitud
      + "?IdSolicitud=" + datos.idSolicitud
      + "&IdUsuario=" + datos.idUsuario
      + "&IdDocumento=" + datos.idDocumento
      + "&RutaArchivoActual=" + datos.rutaArchivoActual
      + "&ExtensionArchivoActual=" + datos.extensionArchivoActual
      + "&ExtensionArchivoNuevo=" + datos.extensionArchivoNuevo
      + "&NombreDocumentoRaiz=" + datos.nombreDocumentoRaiz
      + "&Version=" + datos.version
      + "&VerificarRelacionados=" + datos.tieneRelacionados
      + "&TipoMatrizSeguimiento=" + datos.tipoMatrizSeguimiento
      + "&NombreArchivoOriginal=" + datos.nombreArchivoOriginal
      + "&EsAltaDocumento=" + false
      + "&Codigo=" + datos.codigo
      + "&NombreDocumentoActualizacion=" + datos.nombreActualizacion

      + "&tipoCambio=" + datos.tipoCambio
      + "&tipoDocumento=" + datos.tipoDocumento
      + "&nombreDocumento=" + datos.nombreCompletoDocumento
      + "&autorizador=" + datos.autorizador
      + "&correo=" + datos.correo
      + "&rutaDocumentoRSG=" + datos.rutaDocumentoRSG
      + "&NombreArchivoRSG=" + datos.NombreArchivoRSG
    );
  }

  AltaDocumentoSolicitud(datos) {
    return this._apiServicio.Upload(datos.archivo, this._solicitudCambioConstantes.URLAltaDocumentoSolicitud
      + "?IdSolicitud=" + datos.idSolicitud
      + "&IdUsuario=" + datos.idUsuario
      + "&RutaDestino=" + datos.rutaDestino
      + "&NombreDocumento=" + datos.nombreDocumento
      + "&NombreDocumentoRaiz=" + datos.nombreDocumentoRaiz
      + "&ExtensionDocumento=" + datos.extensionDocumento
      + "&IdCarpeta=" + datos.idCarpeta
      + "&NombreDocumentoCargado=" + datos.nombreDocumentoCargado
      + "&VerificarRelacionados=" + datos.tieneRelacionados
      + "&TipoMatrizSeguimiento=" + datos.tipoMatrizSeguimiento
      + "&EsAltaDocumento=" + true
      + "&Codigo=" + datos.codigo

      + "&tipoCambio=" + datos.tipoCambio
      + "&tipoDocumento=" + datos.tipoDocumento
      + "&nombreDocumento=" + datos.nombreCompletoDocumento
      + "&autorizador=" + datos.autorizador
      + "&correo=" + datos.correo
      + "&Homologado=" + datos.homologado
      + "&rutaDocumentoRSG=" + datos.rutaDocumentoRSG
      + "&NombreArchivoRSG=" + datos.NombreArchivoRSG
    );
  }

  BajaDocumentoSolicitud(datos) {
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLBajaDocumentoSolicitud
      + "?IdSolicitud=" + datos.idSolicitud
      + "&IdUsuario=" + datos.idUsuario
      + "&IdDocumento=" + datos.idDocumento
      + "&RutaArchivoActual=" + datos.rutaArchivoActual
      + "&ExtensionArchivoActual=" + datos.extensionArchivoActual
      + "&NombreDocumentoRaiz=" + datos.nombreDocumentoRaiz

      + "&tipoCambio=" + datos.tipoCambio
      + "&tipoDocumento=" + datos.tipoDocumento
      + "&nombreDocumento=" + datos.nombreCompletoDocumento
      + "&autorizador=" + datos.autorizador
      + "&correo=" + datos.correo
    );
  }

  GuardarAdjuntoSolicitud(archivo, nombreDocumento, idSolicitud, idUsuario, adjuntoDeMatriz, esDocumentoMatriz, idDocumento, nombreDocumentoCargado, idRol) {
    
    return this._apiServicio.Upload(archivo, this._solicitudCambioConstantes.URLGuardarDocumentoAdjunto
      + "?IdSolicitud=" + idSolicitud
      + "&IdUsuario=" + idUsuario
      + "&NombreDocumento=" + nombreDocumento
      + "&NombreDocumentoCargado=" + nombreDocumentoCargado
      + "&AdjuntoDeMatriz=" + adjuntoDeMatriz
      + "&EsDocumentoMatriz=" + esDocumentoMatriz
      + "&IdDocumento=" + idDocumento
      + "&IdRol=" + idRol
    );
  }

  ActualizarReenvioDocumentosSolicitud(archivo, idAdjunto, rutaDocumento, nombreDocumento, extension) {
    return this._apiServicio.Upload(archivo, this._solicitudCambioConstantes.URLActualizarDocumentoMatrizReenvioSolicitud
      + "?IdAdjunto=" + idAdjunto
      + "&RutaDocumento=" + rutaDocumento
      + "&NombreDocumentoCargado=" + nombreDocumento
      + "&Extension=" + extension
    );
  }

  GuardarDocumentoNuevoMatrizSeguimiento(datos) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLGuardarDocumentoMatrizSeguimiento
      + "?IdUsuario=" + datos.idUsuario
      + "&EsActualizacion=" + false
      + "&RutaTemporal=" + datos.rutaTemporal
      + "&RutaDestino=" + datos.rutaDestino
      + "&NombreDocumento=" + datos.nombreDocumento
      + "&IdCarpeta=" + datos.idCarpeta
      + "&ExtensionDocumento=" + datos.extension
      + "&IdSolicitud=" + datos.idSolicitud
      + "&Homologado=" + datos.homologado
    );
  }

  GuardarDocumentoActualizadoMatrizSeguimiento(datos) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLGuardarDocumentoMatrizSeguimiento
      + "?IdUsuario=" + datos.idUsuario
      + "&EsActualizacion=" + true
      + "&RutaTemporal=" + datos.rutaTemporal
      + "&ExtensionArchivoNuevo=" + datos.extensionArchivoNuevo
      + "&IdDocumento=" + datos.idDocumento
      + "&RutaArchivoActual=" + datos.rutaArchivoActual
      + "&NombreDocumentoOriginal=" + datos.nombreDocumentoOriginal
      + "&ExtensionArchivoActual=" + datos.extensionArchivoActual
    );
  }


  ActualizarDatosSolicitudMatriz(idSolicitud) {
    return this._apiServicio.Upload(null, this._solicitudCambioConstantes.URLActualizarDatosSolicitudMatriz
      + "?IdSolicitud=" + idSolicitud
      + "&VerificarRelacionados=" + false
      + "&EsAltaDocumento=" + false
    );

  }

  ConsultarAdjuntos(idSolicitud) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLConsultarAdjuntosSolicitud
      + "?IdSolicitud=" + idSolicitud
    );
  }

  EliminarAdjuntosMatriz(idAdjunto, ruta) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLEliminarDocumentosMatrizSeguimiento
      + "?IdAdjunto=" + idAdjunto
      + "&RutaDocumento=" + ruta
    );
  }

  ObtenerMotivosRechazo() {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerMotivosRechazo);
  }

  ActualizarAsignacionSolicitud(idSolicitud, idusuario) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLActualizarAsignacionSolicitud
      + "?IdSolicitud=" + idSolicitud
      + "&IdUsuario=" + idusuario
    );
  }

  ObtenerSolicitudesDeMatriz(idSolicitud) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerSolicitudesDeMatriz
      + "?IdSolicitud=" + idSolicitud);
  }

  DesligarSolicitudMatriz(idSolicitud) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLDesligarSolicitudMatriz
      + "?IdSolicitud=" + idSolicitud);
  }

  ActualizarSolicitudAplicaMatriz(idSolicitud, aplicaMatriz) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLActualizarSolicitudAplicaMatriz
      + "?IdSolicitud=" + idSolicitud
      + "&AplicaMatriz=" + aplicaMatriz);
  }

  ConsultarRechazosDeSolicitud(idSolicitud, rolId) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLConsultarRechazosDeSolicitud
      + "?IdSolicitud=" + idSolicitud
      + "&RolId=" + rolId
      );
  }

  LiberacionSolicitud(idSolicitud) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLLiberarSolicitudParaRSGC
      + "?IdSolicitud=" + idSolicitud);
  }

  EliminarDocumentoTemporal(rutaDocumento) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLEliminarDocumentoTemporal
      + "?RutaDocumento=" + rutaDocumento);
  }

  VerificarExistenciaDeAutorizador(idArea, IdUDNs, modoSk, especialistaTecnico, especialidad, homologado) {
        
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLVerificarAutorizador
      + "?IdArea=" + idArea
      + "&IdUDNs=" + IdUDNs
      + "&ModoSK=" + modoSk
      + "&Especialista=" + especialistaTecnico
      + "&Especialidad=" + especialidad
      + "&Homologado=" + homologado
    );
  }

  ObtenerUdnSolicitud(idDocumento) {
    return this._apiServicio.Get( this._solicitudCambioConstantes.URLObtenerUdnSolicitud + "?IdDocumento=" + idDocumento);
  }

  GuardarHistorialCargaDeDocumento(datos) {
    return this._apiServicio.Post(datos, this._solicitudCambioConstantes.URLGuardarHistorialCargaDeDocumento);
  }
  
  EnviarCorreoMS(info) {
      return this._apiServicio.Post(info, this._solicitudCambioConstantes.URLEnviarCorreoMatrizSeguimiento);
  }
  
  RevertirEstatusSolicitud(info) {
    return this._apiServicio.Post(info, this._solicitudCambioConstantes.URLRevertirEstatusSolicitud);
  }

  EnviarCorreoNotificacion(info) {
    return this._apiServicio.Post(info, this._solicitudCambioConstantes.URLNotificarUsuario);
  } 

  EliminarComentario(idComentario) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLQuitarComentario+ "?idComentario=" + idComentario);
  }   
  EliminarHistorial(idHistorial) {
    return this._apiServicio.Post(null, this._solicitudCambioConstantes.URLQuitarHistorial+ "?idHistorial=" + idHistorial);
  }  
  VerificarCodigoSolicitud(codigo,idunidad) {
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLVerificarCodigoSolicitud +
      '?codigo=' + codigo +
      "&idunidad=" + idunidad);
  }

  ObtenerIdRolAutorizadorRechazado(IdSolicitud){
    return this._apiServicio.Get(this._solicitudCambioConstantes.URLObtenerIdRolAutorizadorRechazado +
      '?IdSolicitud=' + IdSolicitud);
  }

  GuardarAdjuntoTempoRSG(archivo, nombreDocumento, IdSolicitud) {
    return this._apiServicio.Upload(archivo, this._solicitudCambioConstantes.URLGuardarAdjuntoTempoRSG
      + "?NombreDocumentoCargado=" + nombreDocumento
      + "&IdSolicitud=" + IdSolicitud
    );
  }
}
