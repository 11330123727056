import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	DecisionesConstante
} from "./../config/constantes";

@Injectable()
export class DecisionesService {
    constructor(private _decisionesConstante: DecisionesConstante,
		private _apiServicio: ApiService) {
        
	}

	SubirArchivo(solicitud) {
        return this._apiServicio.Upload(solicitud.fileToUpload, this._decisionesConstante.URLSubirArchivoDecision
            + "?nombreArchivo=" + solicitud.nombreArchivo
			+ "&folio=" + solicitud.folio
			+ "&numeroEmpleado=" + solicitud.numeroEmpleado
			+ "&tipo=" + solicitud.tipo
			+ "&numEmpleado=" + solicitud.numEmpleado
            );
    }
	
	Guardar(solicitud) {
		console.log(solicitud);
        return this._apiServicio.Post(solicitud, this._decisionesConstante.URLGuardarDecision
            // + "?ID=" + solicitud.ID
            // + "&Nombre=" + solicitud.Nombre
            // + "&ModoSK=" + solicitud.ModoSK
            // + "&ModelosRelaciona=" + solicitud.ModelosRelaciona
			// + "&Justificacion=" + solicitud.Justificacion
			// + '&NombreDocumento=' + solicitud.NombreDocumento
			// + '&NumeroEmpleado=' + solicitud.NumeroEmpleado
			// + '&FechaVigencia=' + solicitud.FechaVigencia
			// + "&TipoDecision=" + solicitud.TipoDecision
            // + "&TiposCambios=" + solicitud.TiposCambios
			// + "&UsuarioID=" + solicitud.UsuarioID
			// + '&PosicionID=' + solicitud.PosicionID
			// + '&Nombrecolaborador=' + solicitud.Nombrecolaborador
			// + '&HerramientaID=' + solicitud.HerramientaID
			// + "&lsEmpresas=" + solicitud.lsEmpresas
			// + '&lsDirecciones=' + solicitud.lsDirecciones
			// + '&lsFunciones=' + solicitud.lsFunciones
			// + '&lsPuestos=' + solicitud.lsPuestos
			// + '&lsUnidades=' + solicitud.lsUnidades
            );
	}

	GuardarMultiple(solicitud) {
		console.log(solicitud);
        return this._apiServicio.Post(solicitud, this._decisionesConstante.URLGuardarMultipleDecision);
	}
	
	obtenerDecisionFolio(folio) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerDecision + folio);
	}

	obtenerAllDecisiones() {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerAllDecisiones);
	}

	ConsultarCarpetas(empleadoID, rolCD) {
        
        return this._apiServicio.Get(this._decisionesConstante.URLConsultarCarpetasDecisiones
            + "?empleadoID=" + empleadoID
            + "&rolCD=" + rolCD);
	}
	
	ObtenerDoctosCarpeta(carpetaID) {
        return this._apiServicio.Get(this._decisionesConstante.URLObtenerDoctosCarpetaDecisiones + "?CarpetaID=" + carpetaID);
	}
	
	DescargarDocumento(ruta, documento, extencion) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerDocumentoBase64Decisiones
			+ "?ruta=" + ruta
			+ "&documento=" + documento
			+ "&extencion=" + extencion);
    }

    ConsultarRuta(carpetaID){
        var x = this._apiServicio.Get(this._decisionesConstante.URLConsultarRutaDecisiones + "?carpetaID=" + carpetaID);
        return x;
    }

	ActualizarDecisiones(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLActualizarDecisiones);
	}

	EliminarDocumentoDecisiones(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLEliminarDocumentoDecisiones);
	}

	MoverDocumentoDecisiones(autorizacion) {
		return this._apiServicio.Post(autorizacion, this._decisionesConstante.URLMoverDocumentosDecisiones);
	}

	ObtenerAsignacionDecisiones(numeroEmpleado,posicionEmpleado) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerAsignacionDecisiones
			+ "?numeroEmpleado=" + numeroEmpleado
			+ "&posicionEmpleado=" + posicionEmpleado);
	}

	SubirEvidencia(solicitud) {
        return this._apiServicio.Upload(solicitud.fileToUpload, this._decisionesConstante.URLSubirEvidenciaDecision
            + "?nombreArchivo=" + solicitud.nombreArchivo
			+ "&folio=" + solicitud.folio
			+ "&numeroEmpleado=" + solicitud.numeroEmpleado
			+ "&tipo=" + solicitud.tipo
			+ "&codigo=" + solicitud.codigo
            );
	}
	
	MarcarLeido(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLMarcarLeidosDecisiones);
	}

	CargaAsignacionColaboradores(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLCargaAsignacionColaboradoresDecisiones);
	}

	AsignarUsuario(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLAsignarUsuarioDecisiones);
	}

	ReporteDecisiones(idTipoDecision, idVigencia) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerReporteDecisiones
			+ "?TipoDecision=" + idTipoDecision
			+ "&TipoVigencia=" + idVigencia);
    }

	ReporteDecisionesLectura(idTipoDecision) {
		console.log(idTipoDecision);
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerReporteDecisionesLectura
			+ "?TipoDecision=" + idTipoDecision);
	}
	
	ObtenerCodigoDecisiones(idTipoDecision, folio, empresa, direccion) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerCodigoDecisiones
			+ "?TipoDecision=" + idTipoDecision
			+ "&Folio=" + folio
			+ "&Emp=" + empresa
			+ "&Dir=" + direccion
		);
	}
	
	detalleLecturaDecisiones(folio, tipo) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerDetalleLecturaDecisiones
			+ "?Folio=" + folio
			+ "&Tipo=" + tipo);
	}
	
	obtenerDocumentoDecisiones(folio) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerDocumentosDecisiones
			+ "?Folio=" + folio);
    }

	verificaPermisoDecisiones(posicion, grupoid)
    {
        console.log(grupoid);
        return this._apiServicio.Get(this._decisionesConstante.URLObtenerPermisoDecisiones + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
    }

	AsignacionAutomaticoDecisiones(numeroEmpleado) {
		return this._apiServicio.Get(this._decisionesConstante.URLAsignacionAutomaticoDecisiones
			+ "?numeroEmpleado=" + numeroEmpleado);
	}

	ObtenerDecisionesNumEmpleado(numeroEmpleado) {
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerDecisionesNumEmpleado
			+ "?numeroEmpleado=" + numeroEmpleado);
	}

	DesasignarDecision(datos) {
		return this._apiServicio.Post(datos, this._decisionesConstante.URLDesasignarDecision);
	}

	//OBTENCION DE LOS LISTADOS DE DIRECCIONES PARA LOS REPORTES
	ObtenerListadoDirecciones(){
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerListadoDirecciones);
	}

	//OBTENCION DE LOS LISTADOS DE AREAS PARA LOS REPORTES
	ObtenerListadoAreas(){
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerListadoAreas);
	}

	//OBTENCION DE LOS LISTADOS DE FUNCIONES PARA LOS REPORTES
	ObtenerListadoFunciones(){
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerListadoFunciones);
	}

	//OBTENCION DE LOS LISTADOS DE PUESTOS PARA LOS REPORTES
	ObtenerListadoPuestos(){
		return this._apiServicio.Get(this._decisionesConstante.URLObtenerListadoPuestos);
	}

	//OBTENCION DEL REPORTE DE RESUMEN DECISIONES
	ObtenerReporteResumen(datos){
		return this._apiServicio.Post(datos, this._decisionesConstante.URLObtenerReporteResumen);
	}

	ObtenerFechasFolio(datos){
		return this._apiServicio.Post(datos, this._decisionesConstante.URLObtenerFechasFolio);
	}

	ActualizarVigenciaDecision(datos){
		return this._apiServicio.Post(datos, this._decisionesConstante.URLActualizarVigenciaDecision);
	}

	TestEnvioCorreo(data)
    {
       return this._apiServicio.Post(data, this._decisionesConstante.URLObtenerTestCorreos);
    }
	TestEnvioCorreo2()
    {
        return this._apiServicio.Get(this._decisionesConstante.URLObtenerTestCorreos2);
    }
	ObtenerDecisionesBase()
    {
        return this._apiServicio.Get(this._decisionesConstante.URLObtenerDecisionesBase);
    }
	GuardarDecisionBase(datos){
		return this._apiServicio.Post(datos, this._decisionesConstante.URLGuardarDecisionesBase);
	}

	EnviarCorreoDecisionAsignacion(folio, codigo, nombre) {
		return this._apiServicio.Get(this._decisionesConstante.URLEnviarCorreosDecisionAsignacion
			+ "?folio=" + folio
			+ "&codigo=" + codigo
			+ "&nombre=" + nombre
		);
	}
}