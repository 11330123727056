import { Injectable } from '@angular/core';
import { map, refCount } from "rxjs/operators";

import {
  ApiService
} from "./api.service";

import {
  MatrizDeComunicacionConstante,
}from "./../config/constantes";

@Injectable({
  providedIn: 'root'
})
export class MatrizComunicacionService {

  constructor( 
    private _constantes: MatrizDeComunicacionConstante,
    private _apiServicio: ApiService,
    ) { }


    obtenerPuestos(){
     return this._apiServicio.Get(this._constantes.URLObtenerPuestos);
    }
    obtenerTemporales(){
      return this._apiServicio.Get(this._constantes.URLObtenerTemporales);
    }
    obtenerTemporalesValidado(datos){
      console.log(datos);
      
      return this._apiServicio.Get(this._constantes.URLObtenerTemporaleValidado
        + '?puesto='+datos.puesto
        + '&puestoEnlace='+datos.puestoEnlace);
    }
    obtenerTemporalesPorTipo(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerTemporalesPorTipo
        +'?tipo='+datos);
    }
    obtenerTemporalesPorId(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerTemporalesPorId
        +'?id='+datos);
    }
    guardarTemporales(datos){
      console.log(datos);
      
      return this._apiServicio.Post(datos,this._constantes.URLAgregarTemporales
        + '?puesto='+datos.puesto
        + '&puestoEnlace= '+datos.Codigo
        + '&nivel='+ datos.nivel
        + '&paraqueLA=' +datos.paraqueLA
        + '&paraqueTuberia=' +datos.paraqueTuberia
        + '&paraqueRPD=' + datos.paraqueRPD
        + '&paraqueProcedimientos=' + datos.paraqueProcedimientos
        + '&paraqueSesiones=' + datos.paraqueSesiones
        + '&tipo='+datos.tipo
        + '&NumeroEmpleado='+datos.tipo
        + '&Estado='+datos.Estado
        )
    }
    borrarTemporales(datos){
      return this._apiServicio.Post(datos,this._constantes.URLEliminarTemporales
        +'?id='+datos)
    }
    actualizarTemporal(datos){
      console.log(datos);      
      return this._apiServicio.Post(datos, this._constantes.URLActualizarTemporales)
    }

    obtenerCatalogo(){
      return this._apiServicio.Get(this._constantes.URLObtenerCatalogo)
    }
    obtenerCatalogoPorPuesto(datos){
      return this._apiServicio.Post(datos,this._constantes.URLObtenerCatalogoPorPuesto+'?puesto='+datos.puesto);
    }
    obtenerCatalogoFiltrado(datos){
      return this._apiServicio.Post(datos,this._constantes.URLObtenerCatalogoPorPuesto
        +'?puesto='+datos.puesto
        +'&NumeroEmpleado='+datos.NumeroEmpleado);
    }
    

    obtenerCatalogoPorPuestoFiltrado(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerCatalogoPorPuesto
        +'?puesto='+datos);
    }

    obtenerCatalogoPorEnlace(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerCatalogoPorEnlace
        +'?puesto='+datos.puesto
        +'&puestoEnlace='+datos.puestoEnlace)
    }
    guardarCatalogo(datos){
      console.log(datos);      
      return this._apiServicio.Post(datos,this._constantes.URLAgregarCatalogo    
        );
    }
    BorrarCatalogo(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBorrarDelCatalogo+'?id='+datos.id);
    }
    BorrarMC(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBorrarMC + '?puesto='+datos.puesto)
    }
    
    guardarJuntasTemporales(datos){
      console.log(datos);      
      return this._apiServicio.Post(datos,this._constantes.URLAgregarJuntasTemporales);
    }
    guardarJuntas(datos){      
      return this._apiServicio.Post(datos,this._constantes.URLAgregarJuntasTemporales
        +'?Nombre='+datos.Nombre
        +'&ParaQue='+datos.ParaQue
        +'&Puesto='+datos.Puesto
        +'&PuestoEnlace='+datos.PuestoEnlace
        );
    }
    obtenerJuntasLA(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJuntasLA
        +'?Puesto='+datos.Puesto
        +'&PuestoEnlace='+datos.PuestoEnlace
        )
    }
    editarJuntas(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarJuntasTemporales);
    }
    eliminarJuntas(datos){
      return this._apiServicio.Post(datos,this._constantes.URLEliminarJuntasTEmporales+'?id='+datos.id);
    }
    actualizarCatalogo(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarCatalogo+'?id='+datos.id)
    }
    obtenerCatalogoTemporal(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerTemporalePorPuesto+
        '?puesto='+datos)
    }
    obtenerEmpleados(datos){
      return this._apiServicio.Get(this._constantes.URLEmpleados+ '?puesto='+datos)
    }
    obtenerPorNumero(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerPorNumero + '?nEmpleado='+datos)
    }
    
    obtenerMCIndividuales() {
      const result = this._apiServicio.Get(this._constantes.ObtenerMCIndividuales);
      return result;
    }

    ObtenerMatrizIndividualPorPuesto(puesto: string) {
      const result = this._apiServicio.Get(this._constantes.ObtenerMatrizIndividualPorPuesto+'?puesto='+puesto);
      return result;
    }

    ObtenerMCPorPuestos() {
      const result = this._apiServicio.Get(this._constantes.ObtenerMCPorPuestos)
      return result
    }

    guardarMatrizDeComunicacion(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarMatrizComunicacion
       +'?Nombre='+datos.Nombre 
       +'&NPosicion='+datos.NPosicion
       +'&Puesto='+datos.Puesto
       +'&NombreEnlace='+datos.NombreEnlace
       +'&NPosicionEnlace='+datos.NPosicionEnlace
       +'&PuestoEnlace='+datos.PuestoEnlace
       +'&CorreoEnlace='+datos.CorreoEnlace
       +'&Nivel='+datos.Nivel
       +'&ParaqueLA='+datos.ParaqueLA
       +'&ParaqueTuberia='+datos.ParaqueTuberia
       +'&ParaqueRPD='+datos.ParaqueRPD
       +'&ParaqueProcedimientos='+datos.ParaqueProcedimientos
       +'&ParaqueSesiones='+datos.ParaqueSesiones
       +'&Activo='+datos.Activo
       +'&Tipo='+datos.Tipo
       +'&Creador='+datos.Creador
       +'&Estatus='+datos.Estatus
       +'&Flujo='+1

       )
    }

  ObtenerNotificacionesPorEmpleado(datos){
    return this._apiServicio.Get(this._constantes.URLNotificacionesPorEmpleado
      +'?Notificado='+datos.Notificado 
      )
  }

  ObtenerMCPorId(datos){
  return this._apiServicio.Get(this._constantes.URLObtenerMCPorID
    +'?Id='+datos.IdMC
    )
  }
  ActualizarNotificacionesMC(datos){
    console.log(datos);
        return this._apiServicio.Post(datos,this._constantes.URLActualizarNotificacionesMC
      +'?IdMC='+datos.IdMC
      +'&Estado='+datos.Estado
      +'&Validador='+datos.Validador
      +'&Centralizador='+datos.Centralizador
      +'&NombreCentralizador='+datos.NombreCentralizador
      +'&PuestoEnlaceSP='+datos.PuestoEnlace
      +'&NombreSolicitanteSP='+datos.NombreSolicitante
      +'&CorreoCentralizador='+datos.CorreoCentralizador
      +'&SolicitanteSP='+datos.Solicitante)
  }
  ActualizarNotificacionCentralizador(datos){
    console.log(datos);    
    return this._apiServicio.Post(datos, this._constantes.URLActualizarCentralizador
      +'?Id='+datos.Id 
      +'&IdMC='+datos.IdMC 
      +'&Correo='+datos.Correo 
      +'&Estado='+datos.Estado 
      +'&Fecha='+datos.Fecha 
      +'&Flujo='+datos.Flujo 
      +'&Herramienta='+datos.Herramienta 
      +'&Motivo='+datos.Motivo 
      +'&NombreSolicitante='+datos.NombreSolicitante 
      +'&Notificado='+datos.Notificado 
      +'&Solicitante='+datos.Solicitante 
      +'&Validador='+datos.Validador 
      )
  }
  
  ValidarMCPorColaborador(datos: { Id: number, IdMC: number, Estatus: string }) {
    const resp = this._apiServicio.Post(datos, this._constantes.URLValidarMCPorColaborador);
    return resp
  }

  RechazarMatriz(datos){
    return this._apiServicio.Post(datos,this._constantes.URLActualizarMC
      +'?Id='+datos.Id 
      +'&Estatus='+datos.Estatus  )
  }

  ObtenerEmpleadoPorPosicion(datos){
    console.log(datos);
    
    return this._apiServicio.Get(this._constantes.URLUsuarioPorPosicion
      +'?nPosicion='+datos.nPosicion
      )
  }
  
  ObtenerPorPares(datos){
    // console.log(datos);
    
    return this._apiServicio.Get(this._constantes.URLObtenerPorPares
      +'?nPosicion='+datos.nPosicion
      +'&nPosicion2='+datos.nPosicion2
      )
  }
  Juntas(datos){
    console.log(datos);    
    return this._apiServicio.Get(this._constantes.URLJuntas
      +'?meetings_id='+datos
      )
  }

  GuardarJuntasMC(datos){
    return this._apiServicio.Post(datos, this._constantes.URLGuardarJuntaMC
      +'?meetings_id='+datos.meetings_id
      +'&meetings_code='+datos.meetings_code
      +'&meetings_name='+datos.meetings_name
      +'&meetings_objective='+datos.meetings_objective
      +'&NPosicion='+datos.NPosicion
      +'&NPosicionEnlace='+datos.NPosicionEnlace
      )
  }

  GuardarJuntasMatrizComunicacion(datos: object[]) {
    const response = this._apiServicio.Post(datos, this._constantes.URLGuardarJuntasMatrizComunicacion);
    return response;
  }

  ObtenerJuntasMC(datos){
    return this._apiServicio.Get(this._constantes.URLObtenerJuntasMC
      +'?NPosicion='+datos.NPosicion
      +'&NPosicionEnlace='+datos.NPosicionEnlace     
      )
  }

  BorrarJuntasMC(datos){
    return this._apiServicio.Post(datos,this._constantes.URLBorrarJuntasMC
      +'?meetings_id='+datos.meetings_id
      )
  }

  BorrarJuntasPorPosiciones(datos: { NPosicion: number, NPosicionEnlace: number }) {
    const resp = this._apiServicio.Post(datos, this._constantes.URLBorrarJuntasPorPosiciones);
    return resp;
  }

  BuscarMCPorEmpleado(datos){
    return this._apiServicio.Get(this._constantes.URLBuscarPorEmpleado
      +'?NPosicion='+datos.nPosicion);
  }

  BuscarPuestosMC(){
    return this._apiServicio.Get(this._constantes.URLBuscarPuestosMC)
  }

  BuscarPuestosSinEstatus() {
    const resp = this._apiServicio.Get(this._constantes.URLBuscarPuestosSinEstatus);
    return resp
  }

  BuscarPuestosMCFiltrado(datos){
    return this._apiServicio.Get(this._constantes.URLBuscarPuestosMCFiltrados
      +'?NumeroEmpleado='+datos.NumeroEmpleado )
  }


  BuscarMCPorPuestos(datos){
    console.log(datos);    
    return this._apiServicio.Get(this._constantes.URLBuscarMCPorPuestos
      +'?Puesto='+datos.Puesto);
  }
  BuscarMCPorPuestosFiltrados(datos){
    console.log(datos);    
    return this._apiServicio.Get(this._constantes.URLBuscarMCPorPuestosFiltrado
      +'?Puesto='+datos.Puesto
      +'&Creador='+datos.Creador);
  }

  BuscarMcPorEmpleado(datos){
    return this._apiServicio.Get(this._constantes.URLBuscarMcPorEmpleado
      +'?Nombre='+datos.Nombre)
  }
  ActualizarMC(datos){
    return this._apiServicio.Post(datos,this._constantes.URLActualizarMC
      +'?Id='+datos.Id)
  }
  BuscarCentralizador(datos){
    return this._apiServicio.Get(this._constantes.URLCentralziador
      +'?ColaboradorPosicionID='+datos.ColaboradorPosicionID)
  }
  BuscarJefe(datos: {PosicionIdColaborador: number}){
    const result = this._apiServicio.Get(this._constantes.URLBuscarJefe
      +'?PosicionIdColaborador='+datos.PosicionIdColaborador );
    return result;
  }

  NotificacionesAdministrador(){
    return this._apiServicio.Get(this._constantes.URLNotificacionesAdministrador)
  }
  ObtenerCatalogoFiltrado(datos){
    console.log(datos);
    return this._apiServicio.Get(this._constantes.URLObtenerCaatalogoFiltrado
      +'?NumeroEmpleado='+datos.NumeroEmpleado  )
  }
  BorrarMatriz(datos){
    console.log(datos);
    
    return this._apiServicio.Post(datos, this._constantes.URLBorrarMatriz
      +'?Id='+datos.Id)
  }
  ObteneRechazados(datos){
    return this._apiServicio.Get(this._constantes.URLObtenerRechazados
      +'?IdMC='+datos.Id
      )
  }
}
