import { NgModule } from '@angular/core';
import { Numbers } from './text.directive';
import { CurrencyPipe } from '@angular/common';
import { NumbersValidator } from './numbers.directive';

@NgModule({
    declarations: [Numbers,NumbersValidator],
    imports: [],
    exports: [Numbers,NumbersValidator],
    providers: [],
})
export class SharedDirectivesModule { }