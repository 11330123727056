import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	AutorizacionConstante
} from "./../config/constantes";

@Injectable()
export class AutorizacionService {
	constructor(
		private _aurotizacionConstante: AutorizacionConstante,
		private _apiServicio: ApiService) {
    }

	Guardar(flujo) {
		return this._apiServicio.Post(flujo, this._aurotizacionConstante.URLAutorizacionGuardar);
	}

	ObtenerTodos() {
		return this._apiServicio.Get(this._aurotizacionConstante.URLAutorizacionObtenerTodos);
	}

	ObtenerDetalleFlujo(flujoID) {
		return this._apiServicio.Get(this._aurotizacionConstante.URLAutorizacionObtenerDetalleFlujo+"?FlujoID="+flujoID);
	}
	
}