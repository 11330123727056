import { Injectable } from '@angular/core';

import { map } from "rxjs/operators";

import {
    ApiService
} from "./api.service";

import {
    ControlDocumentalConstante,
} from "./../config/constantes";

@Injectable()
export class ControlDocumentalService {
    constructor(
        private _controlDocumentalConstante: ControlDocumentalConstante,
        private _apiServicio: ApiService) {
    }

    ConsultarCarpetas(empleadoID, rolCD) {

        return this._apiServicio.Get(this._controlDocumentalConstante.URLConsultarCarpetas
            + "?empleadoID=" + empleadoID
            + "&rolCD=" + rolCD);
    }

    ConsultarSubCarpetas(empleadoID, carpetaID) {

        return this._apiServicio.Get(this._controlDocumentalConstante.URLConsultarSubCarpetas
            + "?empleadoID=" + empleadoID
            + "&carpetaID=" + carpetaID);
    }

    ConsultarRolCD(UsuarioID) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLConsultarRolCD
            + "?UsuarioID=" + UsuarioID)
    }

    ConsultarRolesCD() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLConsultarRolesCD)
    }

    DescargarDocumento(DocumentoID) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentoBase64
            + "?DocumentoID=" + DocumentoID);
    }

    DescargarDocumentoGD(DocumentoID, ruta, extension) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentoBase64GD
            + "?DocumentoID=" + DocumentoID
            + "&ruta=" + ruta 
            + "&extension=" + extension);
    }

    ConsultarRuta(carpetaID) {
        var x = this._apiServicio.Get(this._controlDocumentalConstante.URLConsultarRuta + "?carpetaID=" + carpetaID);
        return x;
    }

    CrearCarpeta(carpeta) {
        return this._apiServicio.Post(carpeta, this._controlDocumentalConstante.URLCrearCarpeta
            + "?NombreCarpeta=" + carpeta.strNombreCarpeta
            + "&PadreID=" + carpeta.intPadre
            + "&UsuarioID=" + carpeta.intUsuarioID
            + "&Ruta=" + carpeta.Ruta);
    }

    AgregarRolCD(rolCD) {

        return this._apiServicio.Post(rolCD, this._controlDocumentalConstante.URLAsignarRol
            + "?RolId=" + rolCD.rolId
            + "&Posicion=" + rolCD.posicion
            + "&UsuarioID=" + rolCD.intUsuarioID
            + "&Especialidad=" + rolCD.especialidad 
            + "&Homologado=" + rolCD.homologado
            + "&Edicion=" + rolCD.editar
            + "&RolColaboradorId=" + rolCD.rolColaboradorId);
    }

    EliminarRolCD(rolCDID) {
        return this._apiServicio.Post(rolCDID, this._controlDocumentalConstante.URLEliminarRol);
    }

    AgregarAreasXUDN(areasUDNCD) {
        return this._apiServicio.Post(areasUDNCD, this._controlDocumentalConstante.URLAgregarAreasXUDN);
    }

    ActualizarRutaDocumento(cambioRuta){
        return this._apiServicio.Post(cambioRuta, this._controlDocumentalConstante.URLActualizarRutaDocumento);
    }

    CambiarNombreCarpeta(carpeta) {
        return this._apiServicio.Post(carpeta, this._controlDocumentalConstante.URLCambiarNombreCarpeta
            + "?NombreCarpeta=" + carpeta.strNombreCarpeta
            + "&CarpetaID=" + carpeta.intCarpetaID
            + "&UsuarioID=" + carpeta.intUsuarioID
            + "&rutaAnterior=" + carpeta.rutaAnterior
            + "&ruta=" + carpeta.ruta);
    }

    CargarDocumento(Documento) {
        return this._apiServicio.Upload(Documento.fileDocumento, this._controlDocumentalConstante.URLCargarDocumento
            + "?CarpetaID=" + Documento.intCarpeta
            + "&NombreArchivo=" + Documento.stringNombreDocumento
            + "&Ruta=" + Documento.stringRuta
            + "&Area=" + Documento.idArea
            + "&SubArea=" + Documento.idSubArea
            + "&UDN=" + Documento.idUDN
            + "&UsuarioID=" + Documento.intUsuarioID
            + "&EsMS=" + Documento.chkMS
            + "&Revision=" + Documento.numRevision
            + '&RutaTemporal=' + '');
    }

    EliminarCarpeta(carpeta) {
        return this._apiServicio.Post(carpeta, this._controlDocumentalConstante.URLEliminarNombreCarpeta
            + "?CarpetaID=" + carpeta.intCarpetaID
            + "&UsuarioID=" + carpeta.intUsuarioID
            + "&ruta=" + carpeta.ruta);
    }

    CambiarEstatusObsoleto(documento) {
        return this._apiServicio.Post(documento, this._controlDocumentalConstante.URLCambiarEstatusObsoleto + "?documento=" + documento);
    }

    ObtenerDoctosCarpeta(carpetaID) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDoctosCarpeta + "?CarpetaID=" + carpetaID);
    }

    ObtenerDoctosEliminados() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDoctosEliminados);
    }

    SubirDocumento(documento) {
        return this._apiServicio.Post(documento, this._controlDocumentalConstante.URLSubirDocumento
            + "?uploaded_file=" + documento.uploaded_file
            + "&Nombre=" + documento.Nombre
            + "&CarpetaID=" + documento.CarpetaID
            + "&Version=" + documento.Version
            + "&UsuarioCreacion=" + documento.UsuarioCreacion
            + "&FechaCreacion=" + documento.FechaCreacion);
    }

    EliminarDocumento(documento) {
        return this._apiServicio.Post(documento, this._controlDocumentalConstante.URLEliminarDocumento + "?documento=" + documento);
    }

    EliminarDocumentoAdmin(documento) {
        return this._apiServicio.Post(documento, this._controlDocumentalConstante.URLEliminarDocumentoAdmin + "?documento=" + documento);
    }

    RestaurarDocumento(documento) {
        return this._apiServicio.Post(documento, this._controlDocumentalConstante.URLRestaurarDocumento + "?documento=" + documento);
    }

    eliminarCarpeta(carpeta) {
        return this._apiServicio.Post(carpeta, this._controlDocumentalConstante.URLEliminarCarpeta + "?carpeta=" + carpeta);
    }

    obtenerListadoDocumentosPorUsuario(idUsuario) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentosPorUsuario + "?UsuarioId=" + idUsuario);
    }

    obtenerListadoDocumentos() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentos);
    }

    obtenerDocumentoPorId(idDocumento) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLobtenerDocumentoPorId + "?IdDocumento=" + idDocumento);
    }

    obtenerListadoDocumentosPorUnidadNegocio(idUnidadesNegocio, idArea, idSubArea, homologado) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentosPorUnidadNegocio
            + "?IdUnidadesNegocio=" + idUnidadesNegocio
            + "&IdArea=" + idArea
            + "&IdSubArea=" + idSubArea 
            + "&Homologado=" + homologado);
    }

    obtenerListadoDocumentosMatrizPorUnidadNegocio(idUnidadesNegocio) { 
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDocumentosMatrizPorUnidadNegocio
            + "?IdUnidadesNegocio=" + idUnidadesNegocio);
    }

    obtenerListaMaestra(datos) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerListaMaestra
            + '?Codigo=' + datos.Codigo
            + '&Nombre=' + datos.Nombre
            + '&Revision=' + datos.Revision
            + '&Area=' + datos.Area
            + '&Retencion=' + datos.Retencion
            + '&FechaInicio=' + datos.FechaInicio
            + '&FechaFin=' + datos.FechaFin
            + '&UDN=' + datos.UDN
            + '&TipoDocumento=' + datos.TipoDocumento
            + '&Activos=' + datos.Activos
            + '&Bajas=' + datos.Bajas
            + '&Obsoleto=' + datos.Obsoleto  
            + '&FiltrosVacios=' + datos.FiltrosVacios );
    }

    verificarRutaCompleta(ruta) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLVerificarRuta +
            "?Ruta=" + ruta);

    }

    actualizarDocumento(registroDocumento) {
        return this._apiServicio.Upload(registroDocumento.fileDocumento, this._controlDocumentalConstante.URLActualizarRegistro
            + "?IdDocumento=" + registroDocumento.DocumentoID
            + "&Codigo=" + registroDocumento.Codigo
            + "&Nombre=" + registroDocumento.Nombre
            + "&NombreOriginal=" + registroDocumento.NombreOriginal
            + "&version=" + registroDocumento.Version
            + "&Extencion=" + registroDocumento.Extencion
            + "&Ruta=" + registroDocumento.stringRuta
            + "&actualizaFecha=" + registroDocumento.actualizaFecha
            + "&IdArea=" + registroDocumento.IdArea            
            + "&IdSubArea=" + registroDocumento.IdSubArea          
            + "&UDN=" + registroDocumento.UDNs  
            + "&IdsUDN=" + registroDocumento.idsUDN  
            + "&IdUsuario=" + registroDocumento.UsuarioModificacion);

    }

    obtenerUDNDocumento(idDocumento) {

        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerUDNDocumento
            + "?IdDocumento=" + idDocumento);

    }

    obtenerReporteLSTipoPermiso() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerReporteLSTipoPermiso);
    }

    obtenerReporteLSCarpetas() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerReporteLSCarpetas);
    }

    obtenerReportePermisosActivos() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerReportePermisosActivos);
    }

    descargarCarpetaCompleta(ruta, nombre) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLDescargarCarpetaCompleta+"?Ruta=" + ruta
            +"&Nombre=" + nombre);
    }

    MoverCarpeta(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLMoverCarpeta);
    }

    //LISTAS DE VERIFICACION
    obtenerListadoPeriodos() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerListadoPeriodos);
    }

    ObtenerListadoEncargados(numero) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerListadoEncargados+"?numero=" + numero);
    }

    ObtenerListadoEncargadosALL(numero) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerListadoEncargadosALL+"?numero=" + numero);
    }

    ObtenerListadoPeriodosEncargadoArea(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLObtenerListadoPeriodosEncargadoArea);
    }

    obtenerListadoUNEDisponiblePorPeriodo(anio) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerListadoUNEDisponiblePorPeriodo+"?anio=" + anio);
    }

    crearPeriodoListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLCrearPeriodoListaVerificacion);
    }

    actualizarFechaPeriodoListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLActualizarFechaPeriodoListaVerificacion);
    }

    liberarPeriodoAreaListaVerificacionAll(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLLiberarPeriodoAreaListaVerificacionAll);
    }

    liberarPeriodoAreaListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLLiberarPeriodoAreaListaVerificacion);
    }

    actualizarPeriodoAreaListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLActualizarPeriodoAreaListaVerificacion);
    }

    cumplirPeriodoAreaListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLCumplirPeriodoAreaListaVerificacion);
    }

    revertirPeriodoAreaListaVerificacion(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLRevertirPeriodoAreaListaVerificacion);
    }

    obtenerAreasPorPeriodoUDN(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLObtenerAreasPorPeriodoUDN);
    }

    obtenerDetalleAreasPorPeriodoUDN(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLObtenerDetalleAreasPorPeriodoUDN);
    }

    obtenerDetalleAreasPorPeriodoUDNLV(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLObtenerDetalleAreasPorPeriodoUDNLV);
    }
    
    obtenerListaVerificacionAll(datos) {
        return this._apiServicio.Post(datos, this._controlDocumentalConstante.URLObtenerListaVerificacionAll);
    }

    obtenerListaVerificacionReporte(data) {
        return this._apiServicio.Post(data, this._controlDocumentalConstante.URLObtenerListaVerificacionReporte);
    }

    obtenerListaVerificacionReporteDetalle(data) {
        return this._apiServicio.Post(data, this._controlDocumentalConstante.URLObtenerListaVerificacionReporteDetalle);
    }

    actualizarNombreLV(data){
        return this._apiServicio.Post(data, this._controlDocumentalConstante.URLActualizarNombreListaVerificacion);
    }

    obtenerReporteProductividad(datos) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerReporteProductividad+"?fechaInicio=" + datos.fechaIncio
        + "&fechaFin=" + datos.fechaFin);
    }

    obtenerSemanasReporteProductividadCD() {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerSemanasReporteProductividadCD);
    }

    verificaPermisoEMP(posicion, grupoid) {
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerPermisoEMP + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
    }

    ObtenerEmpleados_CentralizadorGteArq()
	{
		return this._apiServicio.Get(this._controlDocumentalConstante.URLEmpleados_CentralizadorGteArq);
	}

    obtenerdataDocumento(nDocumento){
        return this._apiServicio.Get(this._controlDocumentalConstante.URLObtenerDataDocumento+"?nDocumento=" + nDocumento);
    }

    ActualizarInfoDocumento(data){
        return this._apiServicio.Post(data, this._controlDocumentalConstante.URLActualizarInfoDocumento);
    }
}
