import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	LoginConstante
} from "./../config/constantes";

@Injectable()
export class LoginService {
	constructor(
		private _loginConstante: LoginConstante,
		private _apiServicio: ApiService) {
    }

    Login(usuario) {
        return this._apiServicio.Post(usuario, this._loginConstante.URLLogin);
    }
	
	OlvidePassword(usuario){
		return usuario;
	}
}