/// crea la constante -> lo ingresa al modulo principal -> crea el servicio -> lo ingresa al index
import { Injectable } from '@angular/core';
import { map, refCount } from "rxjs/operators";
import { Observable, Subject } from 'rxjs';
import { ApiService } from "./api.service";
import { DashboardNotificacionesConstante }from "./../config/constantes";
//import { instances } from 'chart.js';

export  interface Observador{
    flag:boolean;
    Herramientas:string;
    inicio:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificacionesDashboardService {
  private flag:boolean;
  private herramientas: string;
  private inicio:number = 2;

  private observador$: Subject<boolean>;
  private Herramientas$: Subject<string>;

  constructor(
    private _constantes: DashboardNotificacionesConstante,
    private _apiServicio: ApiService
    ){
      this.observador$ = new Subject();
      this.Herramientas$ = new Subject();
    }
//-------------------------- Servicios Observadores
    inicioSesion(i){
      this.inicio = i;
    }
    getInicio(){
      //return this.inicio$.asObservable();
      let i= this.inicio;
      return i;
    }

    observarEvento(bandera){
      //actualizar
      this.flag=bandera;
      this.observador$.next(this.flag)
    }
    getObservable(): Observable<boolean>{
      return this.observador$.asObservable();
    }

   VerHerramientas(herramientas){
      this.herramientas = herramientas
   }

   getHerramientas(){
    let h:any;
    h = this.herramientas;
    return h;
   }
//---------------------------------------------------------------------------------------------------
///////////////////// Acceso Temporal////////////////////////////
AccesoTemporal(Posicion){
  return this._apiServicio.Get(this._constantes.URLNotificaiconesAccesoTEMP+"?Usuario="+Posicion)
}
/////////////////////Notificaciones Historial////////////////////
historialNotificaciones(Posicion,Grupo,mes,año){
  return this._apiServicio.Get(this._constantes.URLNotificacionesHistorial+"?Notificado="+Posicion
  +"&G="+Grupo
  +"&M="+mes
  +"&Y="+año)
}

///////////////////// Ordos//////////////////////////// 
    obtenerTodas(){
      return this._apiServicio.Get(this._constantes.URLObtenerPorUsuario);
    }
    obtenerPorOrdo(empleado){
      return this._apiServicio.Get(this._constantes.URLObtenerPorORDO+"?Notificado="+empleado);
    }
    obtenerPorOrdoArquitecto(){
      return this._apiServicio.Get(this._constantes.URLObtenerPorORDOArquitecto);
    }
    ///////////////////////// Desiciones ///////////////////
    notificacionesDecisiones(Posicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesDecisiones+"?Notificado="+Posicion)
    }
    decisionesLeer(empleado,posicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesDecisionesLeer+"?nEmpleado="+empleado+"&nPosicion="+posicion)
    }
    decisionesTotalLeer(empleado,posicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesDecisionesTotalLeer+"?nEmpleado="+empleado+"&nPosicion="+posicion)
    }
    decisionesAdministrador(Posicion,IdGrupo){
        return this._apiServicio.Get(this._constantes.URLObtenerPorDecisiones+"?Notificado="+Posicion+"&Grupo="+IdGrupo);
    }
    ///////////////////////// Seciones ///////////////////
    obtenerPorSesiones(Posicion, nEmpleado){
      return this._apiServicio.Get(this._constantes.URLObtenerPorSesiones+"?Notificado="+Posicion
        +"&nEmpleado= "+nEmpleado)
    }
    //////////////////////// LA ///////////////////////////
    notificacionesLA(Posicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesLA+"?Notificado="+Posicion)
    }
    notificacionesLAAdmin(Posicion,IdGrupo){
      return this._apiServicio.Get(this._constantes.URLNotificacionesLAAdmin+"?Notificado="+Posicion+"&Grupo="+IdGrupo)
    }
    //////////////////////// JUNTAS ///////////////////////////
    notificacionesJuntasAdmin(nEmpleado,descripcion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesJuntasAdmin+"?nEmpleado="+nEmpleado+"&Rol="+descripcion)
    }

    //////////////////////// JUNTAS COMPROMISOS///////////////////////////
    notificacionesJuntasCompromisosAdmin(nEmpleado,descripcion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesJuntasCompromisosAdmin+"?nEmpleado="+nEmpleado+"&Rol="+descripcion)
    }

    //////////////////////// TOTAL JUNTAS COMPROMISOS///////////////////////////
    obtenerTotalJYC(nEmpleado){
      return this._apiServicio.Get(this._constantes.URLObtenerTotalJYC+"?nEmpleado="+nEmpleado)
    }

     //////////////////////// TOTAL JUNTAS COMPROMISOS ATENDIDOS///////////////////////////
     obtenerAtendidasJYC(nEmpleado){
      return this._apiServicio.Get(this._constantes.URLObtenerAtendidasJYC+"?nEmpleado="+nEmpleado)
    }

    //////////////////////// MODO SK ///////////////////////////
    notificacionesModoSK(nEmpleado, posicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesModoSK+"?nEmpleado="+nEmpleado+"&nPosicion="+posicion)
    }

    notificacionesModoSK_Solicitante(nEmpleado){
      return this._apiServicio.Get(this._constantes.URLNotificacionesModoSK_Solicitante+"?nEmpleado="+nEmpleado)
    }

    notificacionesModoSKTotalizados(nEmpleado,nPosicion){
      return this._apiServicio.Get(this._constantes.URLNotificacionesModoSKTotalizados+"?nEmpleado="+nEmpleado
      +"&nPosicion="+nPosicion)
    }

    //////////////////////// CULTURA DE CUMPLIMIENTO ///////////////////////////

    notificacionesCC_usuario(nEmpleado){
      return this._apiServicio.Get(this._constantes.URLNotificacionesCC_usuario+"?nEmpleado="+nEmpleado)
    }

    notificacionesCC_jefe(nEmpleado){
      return this._apiServicio.Get(this._constantes.URLNotificacionesCC_jefe+"?nEmpleado="+nEmpleado)
    }

    notificacionesCC_rh(nDireccion, nUnidadDeNegocio, nPuesto, nDivisionPersonal, nEmpleado){
      return this._apiServicio.Get(this._constantes.URLNotificacionesCC_rh+"?nDireccion="+nDireccion
      +"&nUnidadDeNegocio="+nUnidadDeNegocio
      +"&nPuesto="+nPuesto
      +"&nDivisionPersonal="+nDivisionPersonal
      +"&nEmpleado="+nEmpleado)
    }

    notificaciones_PropuestadeMejora(NumeroEmpleado, PosicionEmpleado){
      return this._apiServicio.Get(this._constantes.URLNotificaciones_PropuestadeMejora+"?NumeroEmpleado="+NumeroEmpleado
      +"&PosicionEmpleado="+PosicionEmpleado)
    }
}
