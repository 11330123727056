import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

import { IndiceDesempenoConstantes } from '../config/constantes';


@Injectable()
export class IndiceDesempenoService {
    constructor(private _apiServicio: ApiService,
        private _indiceConstantes: IndiceDesempenoConstantes) {}

    CreateComponent(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLCreateComponent);
    }

    GetListComponents(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListComponent)
    }

    DeleteComponet(datos) {
        return this._apiServicio.Put(datos,this._indiceConstantes.URLDeleteComponent);
    }

    CreatePeriod(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLCreatePeriod);
    }

    GetListCatPeriod(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListCatPeriod)
    }

    GetListPeriod(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListPeriod)
    }

    GetListAnioConfig(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListAnioConfig)
    }

    GetListPeriodConfig(anio){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListPeriodConfig + '?anio=' + anio)
    }

    GetPeriodFilter(anio,idCatPEriodo){
        return this._apiServicio.Get(this._indiceConstantes.URLGetPeriodFilter
             + '?anio=' + anio
             + '&id_catPeriodo='+ idCatPEriodo)
    }

    GetCapturePercent(numeroEmpleado,anio,periodo){
        return this._apiServicio.Get(this._indiceConstantes.URLGetCapturePercent
             + '?numeroEmpleado=' + numeroEmpleado
             + '&anio='+ anio
             + '&periodo='+ periodo);
    }

    GetFuncionesReplica(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetFuncionesReplica);
    }

    GetColaboradoresReplica(grupo,anio,catPeriodo,idComponente){
        return this._apiServicio.Get(this._indiceConstantes.URLGetColaboradoresReplica
            + '?grupo=' + grupo
            + '&anio='+ anio
            + '&catPeriodo='+ catPeriodo
            + '&idComponente='+ idComponente);
    }

    CreateIndColaboradores(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLCreateIndColaboradores);
    }

    GetIndColaboradores(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetIndColaboradores);
    }

    DeleteIndColaborador(id){
        return this._apiServicio.Delete(this._indiceConstantes.URLDeleteIndColaborador
            + '?id='+ id);
    }

    GetComponentColaboradorNA(nEmpleado,anio,catPeriodo){
        return this._apiServicio.Get(this._indiceConstantes.URLGetComponentColaboradorNA
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&catPeriodo='+ catPeriodo);
    }

    GetCapturaFOC(nEmpleado,anio,cuatrimestre){
        return this._apiServicio.Get(this._indiceConstantes.URLGetCapturaFOC
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&cuatrimestre='+ cuatrimestre);
    }

    GetCapturaEvaluacion(nEmpleado){
        return this._apiServicio.Get(this._indiceConstantes.URLGetCapturaEvaluacion
            + '?nEmpleado='+ nEmpleado);
    }

    DeletePeriod(idPeriodo) {
        return this._apiServicio.Put({idPeriodo: idPeriodo},this._indiceConstantes.URLDeletPeriod);
    }

    GetPermisoColaborador(nEmpleado){
        return this._apiServicio.Get(this._indiceConstantes.URLGetPermissionColaborador
            + '?nEmpleado='+ nEmpleado);
    }

    GetCapturaAuditoria(nEmpleado,anio,cuatrimestre){
        return this._apiServicio.Get(this._indiceConstantes.URLGetCapturaAuditoria
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&cuatrimestre='+ cuatrimestre);
    }

    CargarDatosExcel(file, nombre) {
        return this._apiServicio.Upload(file, this._indiceConstantes.URLCargaDatosExcel
            + "?archivoOriginal=" + nombre
            );
    }

    GetListHistorialResult(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetHistorialResult)
    }

    ResultadoRanking(anio,idCatPeriodo,namePeriodo){
        return this._apiServicio.Get(this._indiceConstantes.URLRankingIndice
            + '?anio='+ anio
            + '&catPeriodo='+ idCatPeriodo
            + '&nomPeriodo='+ namePeriodo);
    }

    DeleteResultRanking(datos){
        return this._apiServicio.Delete(this._indiceConstantes.URLDeleteResultRanking
            + '?idHistorial='+ datos.idHistorial
            + '&id_catPeriodo='+ datos.id_catPeriodo
            + '&anio='+ datos.anio);
    }

    GetResultadoColaborador(nEmpleado,anio,idCatPeriod){
        return this._apiServicio.Get(this._indiceConstantes.URLGetResultColaborador
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&idCatPeriodo='+ idCatPeriod);
    }

    GetColaboradoresResultado(anio,idCatPeriod){
        return this._apiServicio.Get(this._indiceConstantes.URLGetColaboradoresResult
            + '?anio='+ anio
            + '&idCatPeriodo='+ idCatPeriod);
    }

    CalculoIndividual(anio,idCatPeriodo,namePeriodo,nEmpleado){
        return this._apiServicio.Get(this._indiceConstantes.URLGetCalculoIndividual
            + '?anio='+ anio
            + '&catPeriodo='+ idCatPeriodo
            + '&nomPeriodo='+ namePeriodo
            + '&nEmpleado='+ nEmpleado);
    }

    GetObtenerPlantilla(ruta){
        return this._apiServicio.Get(this._indiceConstantes.URLGetObtenerPlantilla
            + '?Ruta='+ ruta);
    }

    CreatePeriodAnual(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLCreatePeriodAnual);
    }

    GetPeriodFilterAnual(anio,idCatPEriodo){
        return this._apiServicio.Get(this._indiceConstantes.URLGetPeriodFilterAnual
             + '?anio=' + anio
             + '&id_catPeriodo='+ idCatPEriodo)
    }

    GetResultadoColaboradorAnual(nEmpleado,anio,idCatPeriod){
        return this._apiServicio.Get(this._indiceConstantes.URLGetResultColaboradorAnual
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&idCatPeriodo='+ idCatPeriod);
    }

    GetColaboradoresCalibracion(nEmpleado,anio,esAdmin){
        return this._apiServicio.Get(this._indiceConstantes.URLGetColaboradoresCalibracion
            + '?nEmpleado='+ nEmpleado
            + '&anio='+ anio
            + '&esAdmin='+ esAdmin);
    }

    GetListaPremisas(){
        return this._apiServicio.Get(this._indiceConstantes.URLGetListPremisas);
    }

    CreateCalibracion(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLCreateCalibracion);
    }

    UpdateEstatusCalibracion(datos) {
        return this._apiServicio.Post(datos,this._indiceConstantes.URLUpdateEstatusCalibracion);
    }
}