import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	TipoCambioConstante
} from "./../config/constantes";

@Injectable()
export class TipoCambioService {
	constructor(
		private _tipoCambioConstante: TipoCambioConstante,
		private _apiServicio: ApiService) {
    }

	Guardar(tipo) {
		return this._apiServicio.Post(tipo, this._tipoCambioConstante.URLTipoCambioGuardar);
	}

	Actualizar(tipo) {
		return this._apiServicio.Put(tipo, this._tipoCambioConstante.URLTipoCambioActualizar);
	}

	ObtenerTodos() {
		return this._apiServicio.Get(this._tipoCambioConstante.URLTipoCambioObtenerTodos);
	}

	ObtenerUltimaSolicitud() {
		return this._apiServicio.Get(this._tipoCambioConstante.URLTipoCambioObtenerUltimaSolicitud);
	}

}