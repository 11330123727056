import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	SolicitudCambioConstante
} from "./../config/constantes";

@Injectable()
export class SolicitudCambioService {
	constructor(
		private _solicitudCambioConstante: SolicitudCambioConstante,
		private _apiServicio: ApiService) {
    }

	// Guardar(solicitud) {
	// 	return this._apiServicio.Post(solicitud, this._solicitudCambioConstante.URLSolicitudCambioGuardar);
	// }

	Guardar(solicitud) {
		return this._apiServicio.Upload(solicitud, this._solicitudCambioConstante.URLSolicitudCambioGuardar);
	}

	GuardarArchivo(solicitud) {
        return this._apiServicio.Upload(solicitud.fileToUpload, this._solicitudCambioConstante.URLSolicitudCambioGuardar
            + "?ID=" + solicitud.ID
            + "&UsuarioID=" + solicitud.UsuarioID
            + "&cambios=" + solicitud.cambios
            + "&comentarios=" + solicitud.comentarios
			+ "&nombreArchivo=" + solicitud.nombreArchivo
			+ '&HerramientaID=' + solicitud.HerramientaID
			+ '&PosicionID=' + solicitud.PosicionID
			+ '&Nombre=' + solicitud.Nombre
            );
    }

	ActualizarEstado(autorizacion) {
		return this._apiServicio.Post(autorizacion, this._solicitudCambioConstante.URLSolicitudCambioActualizarEstado);
	}

	ObtenerTodoPorUsuarioID(usuarioID,opc,nPosicion) {
		return this._apiServicio.Get(this._solicitudCambioConstante.URLSolicitudCambioObtenerPorUsuarioID + "?usuarioID="+usuarioID + "&opc="+ opc + "&nPosicion="+nPosicion) ;
	}

	ObtenerPorGrupoAutorizaID(grupoID, NumeroEmpleado, EsJefe) {
		return this._apiServicio.Get(this._solicitudCambioConstante.URLSolicitudCambioObtenerPorGrupoAutoriza + "?grupoID="+grupoID+"&NumeroEmpleado="+NumeroEmpleado+"&EsJefe="+EsJefe);
	}

	guardarArchivos(datos) {
		return this._apiServicio.PostArchivos(datos, this._solicitudCambioConstante.URLSolicitudCambioGuardarArchivos);
	}

	ObtenerPorAdministrador() {
		return this._apiServicio.Get(this._solicitudCambioConstante.URLSolicitudCambioObtenerPorAdministrador);
	}

}