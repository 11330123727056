import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input("data")
  data;
  @Input("field")
  field;
  @Input("header")
  header;
  @Input("title")
  title
  @Input("idP")
  idP
  public p1: any;
  constructor() { }

  ngOnInit() {
  }

}
