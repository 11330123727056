import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	CatalogosContante,
	
} from "./../config/constantes";

@Injectable()
export class CatalogoService {
	constructor(
		private _catalogoConstante: CatalogosContante,
		private _apiServicio: ApiService) {
    }

	ObtenerHerramientas() {
		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoObtenerHerramientas);
	}

	ObtenerTiposCambio() {
		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoObtenerTiposCambio);
	}

	ObtenerNivelesCambio() {
		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoObtenerNivelesCambio);
	}

	GuardarHerramienta(IdEdit, HerramientaEdit, TieneAplicacionEdit, ActivoEdit,TieneAplicacionEditD) {


		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoGuardarHerramienta + IdEdit + "&Descripcion=" + HerramientaEdit+ "&TieneAplicacion=" + TieneAplicacionEdit + "&Activo=" + ActivoEdit +"&TieneDocumentos="+ TieneAplicacionEditD);
	}
	
	ObtenerHerramientasAll() {
		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoObtenerHerramientasTodas);
	}

	ObtenerFrecuencias(){
		return this._apiServicio.Get(this._catalogoConstante.URLCatalogoFrecuencias);
	}
	ObtenerHerramientasFiltrado(){
        return this._apiServicio.Get(this._catalogoConstante.URLCatalogoObtenerFiltradas)
    }
}