import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	GrupoFormularioConstante
} from "./../config/constantes";

@Injectable()
export class GrupoFormularioService {
// @Injectable()
// export class GrupoFormularioService {
	constructor(
		private _constantes: GrupoFormularioConstante,
		private _apiServicio: ApiService) {
    }

	// ObtenerTodos() {
	// 	return this._apiServicio.Get(this._constantes.URLGrupos);
	// }
	
    Guardar(dato) {
		return this._apiServicio.Post(dato, this._constantes.URLGuposFormularioCrear);
	}
	
	// Actualizar(id, grupo) {
	// 	return this._apiServicio.Put(grupo, this._constantes.URLGrupos);
	// }
}