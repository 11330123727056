import { Injectable } from '@angular/core';

import { map } from "rxjs/operators";

import { 
    ApiService
} from "./api.service";

import {
	RepositorioConstante
} from "./../config/constantes";

@Injectable()
export class RepositorioService {
	constructor(
		private _repositorioConstante: RepositorioConstante,
		private _apiServicio: ApiService) {
    }
    guardar(datosDocumento) {
        console.log(datosDocumento)
        return this._apiServicio.Post(datosDocumento, this._repositorioConstante.URLRepositorioGuardar);
    }

    guardarArchivo(archivo, nombre, Id_TipoHerramienta, NumEmpleado, VersionDocumento, FechaAplicacion, NombreDocumento ,
        Direccion, Area, Funcion, Puesto) {
            console.log(Direccion, Area, Funcion, Puesto);
        return this._apiServicio.Upload(archivo, this._repositorioConstante.URLRepositorioGuardarArchivo + nombre
            + "&NumEmpleado=" + NumEmpleado
            + "&IdHerramienta=" + Id_TipoHerramienta
            + "&VersionDocumento=" + VersionDocumento
            + "&FechaAplicacion=" + FechaAplicacion 
            + "&NombreDocumento=" + NombreDocumento 
            + "&Direccion=" + Direccion 
            + "&Area=" + Area
            + "&Funcion=" + Funcion
            + "&Puesto=" + Puesto
            );
    }

    guardarAsignacionHerramienta(datosAsignacion) {
        return this._apiServicio.Post(datosAsignacion, this._repositorioConstante.URLRepositorioGuardarAsignacion);
    }

    obtenerDocumentosEmpleados(empleados)
    {
        return this._apiServicio.Post(empleados, this._repositorioConstante.URLRepositorioDoctosEmpleado);
    }

    obtenerDocumentosAsignadosPorEmpleado(empleado)
    {
        return this._apiServicio.Post(empleado, this._repositorioConstante.URLRepositorioDoctosAsignadosEmpleado);
    }

    CambiarEstado(doctos)
    {
        return this._apiServicio.Post(doctos, this._repositorioConstante.URLRepositorioDoctosCambiarEstado);
    }
    CambiarEstadoR(doctos)
    {
        return this._apiServicio.Post(doctos, this._repositorioConstante.URLRepositorioDoctosCambiarEstadoR);
    }

    ObtenerArchivo(documento, esPendienteAutorizar)
    {
        return this._apiServicio.Download(this._repositorioConstante.URLRepositorioObtenerArchivo + documento + "&esPendienteAutorizar="+ esPendienteAutorizar);
    }

    ObtenerUltimaVesionHerramienta(idHerramienta) {
		return this._apiServicio.Get(this._repositorioConstante.URLRepositorioObtenerUltimaVersionHerramienta + idHerramienta);
    }
    
    generarPDF(datos) {
        return this._apiServicio.Post(datos, this._repositorioConstante.URLRepositorioGenerarPDF);
    }

}