import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	UsuarioFormularioConstante
} from "./../config/constantes";

@Injectable()
export class UsuarioFormularioService {
	constructor(
		private _constantes: UsuarioFormularioConstante,
		private _apiServicio: ApiService) {
    }
	
    Guardar(datos) {
		return this._apiServicio.Post(datos, this._constantes.URLUsuarioFormularioCrear);
	}
}