import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: '[numbersvalidator]'
})
export class NumbersValidator {

    constructor(public el: ElementRef) {
        this.el.nativeElement.onkeypress = (evt) => {
            if (evt.which < 47 || evt.which > 58) {
                evt.preventDefault();
            }
        };
    }
}