import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {ReporteRDCConstante} from "./../config/constantes";

@Injectable()
export class ReporteRDCService {
	constructor(
		private _reporteConstante: ReporteRDCConstante,
		private _apiServicio: ApiService) {}

	obtenerReporteRDC(Direccion,Area,Funcion,Puesto,anio, numSemana){
		return this._apiServicio.Get(this._reporteConstante.URLObtenerReporteRDC + "?Direccion=" + Direccion + "&Area=" + Area + "&Funcion=" + Funcion + "&Puesto=" + Puesto + "&anio=" + anio + "&numSemana=" + numSemana );
	}

	obtenerReporteEspecificoRDC(Direccion,Area,Funcion,Puesto,Anio,Semana){
		return this._apiServicio.Get(this._reporteConstante.URLObtenerReporteEspecificoRDC + "?Direccion=" + Direccion + "&Area=" + Area + "&Funcion=" + Funcion + "&Puesto=" + Puesto + "&Anio=" + Anio + "&Semana=" + Semana );
	}

	obtenerReporteEmpresaRDC(Direccion,Area,Funcion,Puesto,anio, numSemana){
		return this._apiServicio.Get(this._reporteConstante.URLobtenerReporteEmpresaRDC + "?Direccion=" + Direccion + "&Area=" + Area + "&Funcion=" + Funcion + "&Puesto=" + Puesto + "&anio=" + anio + "&numSemana=" + numSemana );
	}

	obtenerSemanasRDC(){
		return this._apiServicio.Get(this._reporteConstante.URLobtenerSemanasRDC);
	}
}