import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	EmpleadoConstante
} from "./../config/constantes";

@Injectable()
export class EmpleadoService {
	constructor(
		private _empleadoConstante: EmpleadoConstante,
		private _apiServicio: ApiService) {
    }
	
	ObtenerFiltrado(datosConsulta) {
		return this._apiServicio.Post(datosConsulta, this._empleadoConstante.URLEmpleadoConsultaDoctos);
	}
	
	ObtenerEmpleadosPorFiltro(datosConsulta) {
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsulta + '?IdDireccion=' + datosConsulta.IdDireccion + '&IdArea=' + datosConsulta.IdArea + '&IdFuncion=' + datosConsulta.IdFuncion + '&IdPuesto=' + datosConsulta.IdPuesto);
	}

	ObtenerEmpleadosPorFiltro_(datosConsulta) {
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaVacante + '?IdDireccion=' + datosConsulta.IdDireccion + '&IdArea=' + datosConsulta.IdArea + '&IdFuncion=' + datosConsulta.IdFuncion + '&IdPuesto=' + datosConsulta.IdPuesto);
	}

	ObtenerEmpleadosPorFiltroUDN(datosConsulta) {
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaUDN + '?IdDireccion=' + datosConsulta.IdDireccion + '&IdArea=' + datosConsulta.IdArea + '&IdFuncion=' + datosConsulta.IdFuncion + '&IdPuesto=' + datosConsulta.IdPuesto + '&IdUDN=' + datosConsulta.IdUDN);
	}

	ObtenerEmpleadoPosicionID(posicionID)
	{
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaPorPosicionID + '?PosicionID=' + posicionID);
	}

	ObtenerEmpleadoNumEmpleado(numEmpleado)
	{
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaPorNumEmpleado + '?NumEmpleado=' + numEmpleado);
	}
	obtenerEmpleadosNumero(numEmpleado){
		return this._apiServicio.Get(this._empleadoConstante.URLobtenerEmpleadosNumero + '?NumEmpleado=' + numEmpleado);
	}

	ObtenerDatosRH(unidad)
	{
		return this._apiServicio.Get(this._empleadoConstante.URLDatosRH + '?Unidad=' + unidad);
	}

	ObtenerEmpleadosAll(nombre)
	{
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadosAll+ '?Nombre=' + nombre);
	}
	
	ObtenerEmpleadosCentralizadores()
	{
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadosCentralizadores);
	}

	ObtenerDataOrdoUsuario(nPosicion){
		return this._apiServicio.Get(this._empleadoConstante.URLobtenerDataOrdoUsuario + '?nPosicion=' + nPosicion);
	}
	
	ObtenerEmpleadoPosicionIDActivo(posicionID)
	{
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaPorPosicionIDActivo + '?PosicionID=' + posicionID);
	}

}