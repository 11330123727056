import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import {
	ApiService
} from "./api.service";

import {
	PuestosConstante
} from "./../config/constantes";

@Injectable()
export class PuestosService {
	constructor(
		private _puestosConstante: PuestosConstante,
		private _apiServicio: ApiService) {
    }

	ObtenerEmpresas() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerEmpresas);
	}

	ObtenerAreas() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAreas);
	}

	ObtenerAreasRH(idDireccion) {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAreasRH+ "?id_direccion=" + idDireccion);
	}

	ObtenerFunciones(idDireccion, IdArea) {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerFunciones + IdArea + "&id_direccion=" + idDireccion);
	}

	ObtenerPuestosArea(idArea) {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerPuestosAreas + "?IdArea=" + idArea);
	}

	ObtenerPuestos(idDireccion, idArea, IdFuncion) {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerPuestos + IdFuncion + "&IdArea=" + idArea + "&id_direccion=" + idDireccion);
	}

	obtenerEmpleados(IdPuesto) {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerEmpleados + IdPuesto);
	}

	ObtenerAllFunciones() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllFunciones);
	}

	ObtenerAllPuestos() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllPuestos);
	}

	ObtenerUnidades() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerUnidades);
	}
	

	ObtenerSubAreas(IdArea) {
		return this._apiServicio.Get(this._puestosConstante.URLObtenerSubAreas + "?IdArea=" + IdArea);
	}

	AgregarSubArea(subArea) {
		return this._apiServicio.Post(subArea,this._puestosConstante.URLAgregarSubArea +
			"?IdArea=" + subArea.IdArea +
			"&Descripcion=" + subArea.Descripcion +
			"&Activo=" + subArea.Activo +
			"&IdUsuarioCreacion=" + subArea.IdUsuarioCreacion
		);
	}
	
	ObtenerTodasSubAreas() {
		return this._apiServicio.Get(this._puestosConstante.URLObtenerTodasSubAreas );
	}

	ActualizarSubArea(subArea) {
		return this._apiServicio.Post(subArea, this._puestosConstante.URLActualizarSubArea +
			"?Id=" + subArea.Id +
			"&IdArea=" + subArea.IdArea +
			"&Descripcion=" + subArea.Descripcion +
			"&Activo=" + subArea.Activo +
			"&IdUsuarioModificacion=" + subArea.IdUsuarioModificacion
		);
	}

	EliminarSubArea(datos) {
		return this._apiServicio.Post(datos, this._puestosConstante.URLEliminarSubArea);
	}

	ObtenerTotalDocumentosArea(datos)
	{
		return this._apiServicio.Post(datos, this._puestosConstante.URLObtenerTotalDocumentosArea);
	}

	ReasignarDocumentosArea(datos)
	{
		return this._apiServicio.Post(datos, this._puestosConstante.URLReasignarDocumentosArea);
	}

	ObtenerAreasDecisiones() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAreasDecisiones);
	}

	ObtenerAllFuncionesDecisiones() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllFuncionesDecisiones);
	}

	ObtenerAllPuestosDecisiones() {
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllPuestosDecisiones);
	}

	ObtenerAllFuncionesPorAreas(areas) {
		const areasString = areas.toString();
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllFuncionesPorAreas + 
					"?Areas=" + areasString);
	}

	ObtenerAllPuestosPorFuncion(direcciones,areas,funciones) {
		const direccionesString = direcciones.toString();
		const areasString = areas.toString();
		const funcionesString = funciones.toString();
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllPuestosPorFunciones + 
					"?Direcciones=" + direccionesString +
					"&Areas=" + areasString +
					"&Funciones=" + funcionesString);
	}

	ObtenerAllColaboradoresPuestos(puestos) {
		const puestosString = puestos.toString();
		return this._apiServicio.Get(this._puestosConstante.URLPuestosObtenerAllColaboradoresPuestos + 
					"?Puestos=" + puestosString);
	}

	obtenerPuestosAreaRh(idArea) {
		return this._apiServicio.Get(this._puestosConstante.URLobtenerPuestosAreaRh + "?IdArea=" + idArea);
	}
	
	obtenerDirArea() {
		return this._apiServicio.Get(this._puestosConstante.URLobtenerDirArea);
	}

	obtenerPuestosAreasDir(direcciones,areas){
		const direccionesString = direcciones.toString();
		const areasString = areas.toString();
		return this._apiServicio.Get(this._puestosConstante.URLobtenerPuestosAreasDir + 
					"?Direcciones=" + direccionesString +
					"&Areas=" + areasString 
					)
	}
	ObtenerAllPuestosCK() {
		return this._apiServicio.Get(this._puestosConstante.URLobtenerPuestosCK);
	}

	ObtenerAllColaboradoresModCentralizador() {
		return this._apiServicio.Get(this._puestosConstante.URLobtenerAllColaboradoresModCentralizador);
	}

}