import { Injectable } from '@angular/core';
import { 
	ApiService 
} from "./api.service";

import {
    InformesConstantes
}from "../config/constantes";

@Injectable()
export class InformesService {

  constructor(   
    private _constantes: InformesConstantes,
    private _apiServicio: ApiService) { }

    ObtenerFrecuenciaReporteInforme(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerFrecuenciaReporteInforme +
        '?frecuencia=' + datos.frecuencia +
        '&nPosicion=' + datos.nPosicion);
    }

    ObtenerNomInformes(datos){
        return this._apiServicio.Get(this._constantes.URLObtenerNomInformes +
          '?nomIdentificador=' + datos.nomIdentificador +
          '&nPosicion=' + datos.nPosicion +
          '&frecuencia=' + datos.frecuencia);
    }
    
    ObtenerDocumentInforme(nomArchivo, rutaFisica){
      return this._apiServicio.Download(this._constantes.URLObtenerDocumentInforme +
      '?nomDocumento=' + nomArchivo +
      '&rutaFisica=' + rutaFisica);
  }  

    ObtenerFechaInforme(idsk_veo, nomDocumento){
      return this._apiServicio.Get(this._constantes.URLObtenerFechaInformes +
        '?idsk_veo=' + idsk_veo +
        '&nomDocumento=' + nomDocumento);
    }

    validarNomInforme(nombreDocumento){
      return this._apiServicio.Get(this._constantes.URLValidarNomInforme +
            '?NombreDocumento=' + nombreDocumento);
    }

    cargarInforme(archivo, nombreDocumento, rutaFisica, idInforme, fechaModificacion, nPosicion){
      return this._apiServicio.Upload(archivo, this._constantes.URLCargaInformes +
            '?NombreDocumento=' + nombreDocumento +
            '&rutaFisica=' + rutaFisica +
            '&idInforme=' + idInforme +
            '&fechaModificacion=' + fechaModificacion +
            '&nPosicion=' + nPosicion);
    }

    permisoCargarInformes(nPosicion){
      return this._apiServicio.Get(this._constantes.URLPermisoCargaInformes +
            '?nPosicion=' + nPosicion);
    }

    permisoVerInformes(posicion, grupoid) {
      console.log(grupoid);
      return this._apiServicio.Get(this._constantes.URLPermisoInformes + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
    }

    obtenerRutaDocumento(nombreDocumento){
      return this._apiServicio.Get(this._constantes.URLObtenerRutaDocumento +
            '?NombreDocumento=' + nombreDocumento);
    }

    ObtenerPermisoHerramientasAdmin(empleadoId, grupoid, Herramienta){
      return this._apiServicio.Get(this._constantes.URLObtenerPermisoHerramientasAdmin + '?EmpleadoId=' + empleadoId + '&GrupoID=' + grupoid + '&Herramienta=' + Herramienta);
    }
}
