import { Injectable } from "@angular/core";
import { ContactoConstantes } from "../config/constantes";
import { ApiService } from "./api.service";

@Injectable()
export class ContactoService {
  constructor(
    private _constantes: ContactoConstantes,
    private _apiService: ApiService
  ) {}

  sendEmail(datos) {
    const resp = this._apiService.Post(datos, this._constantes.URLSendEmail);
    return resp;
  }

  GuardarContacto(datos) {
    const resp = this._apiService.Post(datos, this._constantes.URLGuardarContacto);
    return resp;
  }

  ObtenerPreguntasContacto() {
    const resp = this._apiService.Get(this._constantes.URLobtenerPreguntasContacto);
    return resp;
  }

  ActualizarRespuesta(datos) {
    const resp = this._apiService.Post(datos, this._constantes.URLactualizarRespuesta);
    return resp;
  }

  respoEmail(datos) {
    const resp = this._apiService.Post(datos, this._constantes.URLrespoEmail);
    return resp;
  }
}
