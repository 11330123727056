import { Injectable } from '@angular/core';

import { map } from "rxjs/operators";

import {
    ApiService
} from "./api.service";

import {
    TalleresConstante,
} from "./../config/constantes";

@Injectable()
export class TalleresService {
    constructor(
        private _talleresConstante: TalleresConstante,
        private _apiServicio: ApiService) {
    } 
    obtenerTalleresAll() {
        return this._apiServicio.Get(this._talleresConstante.URLObtenerTalleres);
    }

    obtenerTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerTaller + idTaller);
    }

    obtenerParticipantesTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerParticipantesTaller + idTaller);
    }

    obtenerSesionesTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerSesionesTaller + idTaller);
    }

    obtenerEntregablesTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerEntregablesTaller + idTaller);
    }
    crearTaller(datos) {
        return this._apiServicio.Post(datos, this._talleresConstante.URLCrearTaller);
    }

    cancelarTaller(datos){
        return this._apiServicio.Put(datos,this._talleresConstante.URLCancelarTaller);
    }
    aprobarAreasTrabajo(datos){
        return this._apiServicio.Put(datos,this._talleresConstante.URLAprobarAreasTrabajo);
    }
    actualizarTaller(datos){
        return this._apiServicio.Put(datos,this._talleresConstante.URLActualizarTaller);
    }
    obtenerDirectoresSubdirectores(){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerDirectoresSubdirectores);
    }
    obtenerRelacionLideresSoporte(){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerRelacionLideresSoporte);
    }
    obtenerRelacionLideresOperacion(){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerRelacionLideresOperacion);
    }
    obtenerRelacionLideresSoporteByTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerRelacionLideresSoporteByTaller + idTaller);
    }
    obtenerRelacionLideresOperacionByTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerRelacionLideresOperacionByTaller + idTaller);
    }
    obtenerRelacionDirectoresGeneralesByTaller(idTaller){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerRelacionDirectoresGeneralesByTaller + idTaller);
    }
    obtenerCatalogoHerramientas(){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerCatalogoHerramientas);
    }
    obtenerDirecciones(){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerDirecciones);
    }
    obtenerAreas(idDireccion){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerAreas + idDireccion);
    }
    obtenerGteArquitecturaByArea(idArea){
        return this._apiServicio.Get(this._talleresConstante.URLObtenerGteArquitecturaByArea + idArea);
    }
    guardarAreaHerramienta(area) {
		return this._apiServicio.Post(area, this._talleresConstante.URLAreasHerramientasGuardar);
	}
    generarPdf(talleres) {
        return this._apiServicio.Post(talleres,this._talleresConstante.URLLAGeneraPDF);
    }

    ManejoDeFlujoAutorizacionTalleres(objParametros){
        return this._apiServicio.Get(this._talleresConstante.URLManejoDeFlujoAutorizacionTalleres);
    }

    consultaSolicitudesTalleres(objParametros){
        const {opcConsulta,nEmpleado} = objParametros;
        return this._apiServicio.Get(this._talleresConstante.URLconsultaSolicitudesTalleres + `?opcConsulta=${opcConsulta}&nEmpleado=${nEmpleado}`);
    }
}