export * from './api.service';
export * from './authentication.service';
export * from './login.service';
export * from './usuario.service';
export * from './menu.service';
export * from './modulo.service';
export * from './formulario.service';
export * from './grupo.service';
export * from './tablero.service';
export * from './grupoFormulario.service';
export * from './catalogo.service';
export * from './Autorizacion.service';
export * from './TipoCambio.service';
export * from './solicitudCambio.service';
export * from './puesto.service';
export * from './repositorioDocuemtos.service';
export * from './empleado.service';
export * from './LA.service';
export * from './MotivosInasistencia.service';
export * from './usuarioFormulario.service';
export * from './RelacionCentralizadorColaborador.service';
export * from './juntas.service';
export * from './parametros.service';
export * from './reportes.service';
export * from './catalogoDecisiones.service';
export * from './decisiones.service';
export * from './responsabilidadesinherentes.service';
export * from './Ordos.service';
export * from './controlDocumental.service';
export * from './UnidadesDeNegocio.service';
export * from './CatalogoCtrlDoc.service';
export * from './solicitudCtrlDocumental.service';
export * from './permisosCtrlDoc.service';
export * from './RolesControlDocumental.service';
export * from './api.service';
export * from './authentication.service';
export * from './login.service';
export * from './usuario.service';
export * from './menu.service';
export * from './modulo.service';
export * from './formulario.service';
export * from './grupo.service';
export * from './tablero.service';
export * from './grupoFormulario.service';
export * from './catalogo.service';
export * from './Autorizacion.service';
export * from './TipoCambio.service';
export * from './solicitudCambio.service';
export * from './puesto.service';
export * from './repositorioDocuemtos.service';
export * from './empleado.service';
export * from './LA.service';
export * from './MotivosInasistencia.service';
export * from './usuarioFormulario.service';
export * from './RelacionCentralizadorColaborador.service';
export * from './juntas.service';
export * from './parametros.service';
export * from './reportes.service';
export * from './catalogoDecisiones.service';
export * from './decisiones.service';
export * from './responsabilidadesinherentes.service';
export * from './Ordos.service';
export * from './controlDocumental.service';
export * from './UnidadesDeNegocio.service';
export * from './CatalogoCtrlDoc.service';
export * from './solicitudCtrlDocumental.service';
export * from './RolesControlDocumental.service';
export * from './matriz-comunicacion.service';
export * from './gte-arquitectura-procesos.service';
export * from './notificaciones-dashboard.service';
export * from './checklist.service';
export * from './PreguntasFrecuentes.service';
export * from './reporteRDC.service';
export *from './Talleres.service';
export * from './reporteRDC.service';
export * from './reporteRDC.service';
export * from './mejoraPropuesta.service';
export * from './Contacto.service'
export * from './comunicacion.service';
export * from './Rpd.service';
export * from './Tuberias.service';
export * from './hk.service';
export * from './indiceDesempeno.service';
export * from './aarq-juntas.service';
export * from './auditoria-cultura.service';
export * from './Informes.service';

