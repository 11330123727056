import { Injectable } from '@angular/core';
import { 
    ApiService
} from "./api.service";

import {
	LAConstante
} from "./../config/constantes";

@Injectable()
export class LAService {
	constructor(
		private _constantes: LAConstante,
		private _apiServicio: ApiService) {
    }
    guardarSecciones(secciones) {
        return this._apiServicio.Post(secciones, this._constantes.URLLAGuardarSecciones);
    }

    guardarListaActividades(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAGuardarListaActividades);
    }

    consultarSeccionesGenerales() {
        return this._apiServicio.Get(this._constantes.URLLAConsultarSeccionesGenerales );
    }

    consultarSecciones(posicion) {
        return this._apiServicio.Get(this._constantes.URLLAConsultarSecciones + '?posicion=' + posicion);
    }

    consultarActividades(posicion, opc) {
        return this._apiServicio.Get(this._constantes.URLLAConsultarActividades + '?posicion=' + posicion + '&opc=' + opc );
    }

    consultarCapturaActividades(numEmpleado, posicion, anio, semana) {
        return this._apiServicio.Get(this._constantes.URLLAConsultarCapturaActividades + '?posicion=' + posicion + "&anio=" + anio + "&semana=" + semana + "&numempleado=" + numEmpleado);
    }

    generarPdfLA(numEmpleado, posicion, anio, semana,nombre,puesto) {
        return this._apiServicio.Get(this._constantes.URLLAGeneraPDF + '?posicion=' + posicion + "&anio=" + anio + "&semana=" + semana + "&numempleado=" + numEmpleado + "&nombre=" + nombre  + "&puesto=" + puesto);
    }

    GeneraPdfLaReP(numEmpleado, posicion, anio, semana,nombre,puesto) {
        return this._apiServicio.Get(this._constantes.URLLAGeneraPdfLaReP + '?posicion=' + posicion + "&anio=" + anio + "&semana=" + semana + "&numempleado=" + numEmpleado + "&nombre=" + nombre  + "&puesto=" + puesto);
    }
    guardarCapturaActividades(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAGuardarCapturaActividades);
    }

    actualizaCapturaActividades(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLactualizaCapturaActividades);
    }

    guardaComentariosLA(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLguardaComentariosLA);
    }

    guardaActividadesNoRecurrentes(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLguardaActividadesNoRecurrentes);
    }

    cerrarSemana(posicion, anio, semana, usuarioId) {
        var datos = {
            posicion : posicion,
            anio : anio,
            semana : semana,
            usuarioid : usuarioId
        }
        return this._apiServicio.Post(datos, this._constantes.URLLACerrarSemana);
    }

    obtenerDiasInasistencia(posicion, numEmpleado)
    {
        return this._apiServicio.Get(this._constantes.URLLAObtenerDiasInasistencia + '?posicion=' + posicion + '&numEmpleado=' + numEmpleado);
    }

    GuardarDiasInasistencia(datos)
    {
        return this._apiServicio.Put(datos, this._constantes.URLLAGuardarDiasInasistencia);
    }

    ObtenerSemanasSeleccionables(posicionID,numEmpleado)
    {
        return this._apiServicio.Get(this._constantes.URLLAObtenerSemanasSeleccionables + '?posicionId=' + posicionID + '&numEmpleado=' + numEmpleado);
    }

    ObtenerListaActividadesFijas(cFuncion)
    {
        return this._apiServicio.Get(this._constantes.URLLAObtenerActividadesFijas + '?cFuncion=' + cFuncion);
    }

    RemoverDiaInasistencia(IDInasistencia) {
		return this._apiServicio.Post( IDInasistencia, this._constantes.URLDiasInasistenciaRemover);
    }
    
    GuardaActividadAutomatico(datos) {
		return this._apiServicio.Post(datos, this._constantes.URLGuardaActividadAutomatico);
    }

    DesactivarJuntasDeCarnet(posicion) {
		return this._apiServicio.Get(this._constantes.URLDesactivarJuntasDeCarnet + '?posicion=' + posicion);
    }

    DesactivarActividadesRDC_LA(posicion) {
		return this._apiServicio.Get(this._constantes.URLDesactivarActividadesRDC_LA + '?posicion=' + posicion);
    }
    

	GuardaActividadGestionEquipoAutomatico(datos) {
		return this._apiServicio.Post(datos, this._constantes.URLGuardaActividadGestionEquipoAutomatico);
    }
    
    verificaPermisoLA(posicion, grupoid)
    {
        console.log(grupoid);
        return this._apiServicio.Get(this._constantes.URLObtenerPermisoLA + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
    }

    guardarAvanceActividades(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAGuardarAvanceActividades);
    }

    editarActividad(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAEditarActividad);
    }

    eliminarActividad(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAEliminarActividad);
    }

    enviarNotificacion(folio/*,posicionID,nombre*/) {
        return this._apiServicio.Get(this._constantes.URLLAenviarNotificacion + '?folioSolicitud=' + folio/* + '&PosicionID=' + posicionID + '&nombre=' + nombre*/);
    }
    enviarNotificacionRespuesta(folio,respuestaOpc) {
        return this._apiServicio.Get(this._constantes.URLLAenviarNotificacionRespuesta + '?folioSolicitud=' + folio + '&respuestaOpc=' + respuestaOpc);
    }
    ReactivarColaboradoresRDC(datos) {
        return this._apiServicio.Post(datos,this._constantes.URLReactivarColaboradoresRDC /*+ '?nPosicion=' + nPosicion + '&EmpleadosRDC=' + EmpleadosRDC*/);
    }

    DesactivarSeccionRDC(datos) {
        return this._apiServicio.Post(datos,this._constantes.URLdesactivarSeccionRDC);
    }

    ReporteGeneralLA(objRequest) {
        return this._apiServicio.Get(this._constantes.URLReporteGeneralLA, objRequest);
    }

    ReporteGeneraPorDireccion_LA(objRequest) {
        return this._apiServicio.Get(this._constantes.URLReporteGeneraPorDireccion_LA, objRequest);
    }

    ReporteDetalladoLA(objRequest) {
        return this._apiServicio.Get(this._constantes.URLReporteDetalladoLA, objRequest);
    }

    SemanasDisponiblesReportesLA() {
        return this._apiServicio.Get(this._constantes.URLSemanasDisponiblesReportesLA);
    }

    ActualizaContadorDescargaDeReportes(objRequest) {
        return this._apiServicio.Post(objRequest,this._constantes.URLActualizaContadorDescargaDeReportes);
    }

    SemanasSeleccionablesReporteLA_SLA() {
        return this._apiServicio.Get(this._constantes.URLSemanasSeleccionablesReporteLA_SLA);
    }

    obtenerReporteLA_SLA(objRequest) {
        return this._apiServicio.Get(this._constantes.URLobtenerReporteLA_SLA, objRequest);
    }

    guardarActividadesDisenoArquitectura(actividades) {
        return this._apiServicio.Post(actividades, this._constantes.URLLAguardarActividadesDisenoArquitectura);
    }

}