import { Injectable } from '@angular/core';

import { 
	ApiService 
} from "./api.service";

import {
	CatalogoDecisionesConstante
} from "./../config/constantes";

@Injectable()
export class CatalogoDecisionesService{
    constructor(
        private _catalogoDecisionesConstante: CatalogoDecisionesConstante,
        private _apiServicio: ApiService) {   
    }

    ObtenerTodos() {
		return this._apiServicio.Get(this._catalogoDecisionesConstante.URLCatalogoDecisionesTodos);
	}

    ObtenerDetalleCatalogo(idcatalogo) {
        return this._apiServicio.Get(this._catalogoDecisionesConstante.URLCatalogoDecisionesDetalleCatalogo + idcatalogo);
    }
    
    GuardarDetalleCatalogo(registro) {
		return this._apiServicio.Post(registro, this._catalogoDecisionesConstante.URLCatalogoDecisioneGuardarDetalle);
	}
}