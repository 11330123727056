import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import {
  ApiService
} from "./api.service";

import {
  RespoInerentesConstante
}from "./../config/constantes";

@Injectable()
export class RespoinheService {
  constructor(
    private _constantes: RespoInerentesConstante,
    private _apiServicio: ApiService) {
     }

     Buscar(){
       return this._apiServicio.Get(this._constantes.URLObtener);
     }

     BuscarActivos(){
       return this._apiServicio.Get(this._constantes.URLActvas);
     }
     BuscarCargo(){
       return this._apiServicio.Get(this._constantes.URLCargos);
     }
     Borrar(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBorrar+'?id='+datos)
     }
     GuardarIne(datos){
       return this._apiServicio.Post(datos,this._constantes.URLGuardarIne
        + '?Descripcion='+datos.Descripcion
        + '&Cargo='+datos.Cargo
        + '&Activo='+datos.Activo
        );
     }
     Guardar(datos){
       return this._apiServicio.Post(datos,this._constantes.URLGuardar
        + '?IdOrdo='+datos.IdOrdo
        + '&Descripcion='+datos.Descripcion
        );
     }
     Actualizar_dragydrop(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizar_dragydrop);
    }
     BuscarRespos(datos){     
       return this._apiServicio.Get(this._constantes.URLRespos+'?IdORDO='+datos)
     }
     BuscarRespos_Eliminados(datos){     
      return this._apiServicio.Get(this._constantes.URLRespos_Eliminados+'?IdORDO='+datos)
    }
     Actualizar(datos){
       return this._apiServicio.Post(datos,this._constantes.URLActualizar
        + '?id='+datos.Id
        + '&Descripcion='+datos.Descripcion
        + '&Cargo='+datos.Cargo
        + '&Activo='+datos.Activo)
     }
     ActualizarRespo(datos){
      return this._apiServicio.Post(datos,this._constantes.URLAcRespo
       + '?Id='+datos.Id
       + '&Descripcion='+datos.Descripcion
       + '&Editado=1'
       )
    }
    BorrarRespo(datos){
     return this._apiServicio.Post(datos,this._constantes.URLBoRespo
      +'?Id='+datos.Id
      +'&IdOrdo='+datos.IdOrdo
      )
    }

}
