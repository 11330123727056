import { Injectable } from '@angular/core';
import { 
	ApiService 
} from "./api.service";

import {
	UnidadesDeNegocioConstante
} from "./../config/constantes";

@Injectable()
export class UnidadesDeNegocioService {
	constructor(
		private _unidadDeNegocio: UnidadesDeNegocioConstante,
		private _apiServicio: ApiService) {
    } 

	ObtenerTodos() {
		return this._apiServicio.Get(this._unidadDeNegocio.URLUnidadesDeNegocio);
	}
 

}