import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	ParametrosConstante
} from "../config/constantes";

@Injectable()
export class ParametrosService {
	constructor(
		private _parametrosConstante: ParametrosConstante,
		private _apiServicio: ApiService) {
    }

	ObtenerParametros() {
		return this._apiServicio.Get(this._parametrosConstante.URLParametros);
		
	}

	Modificar(datos)
	{
		return this._apiServicio.Post(datos, this._parametrosConstante.URLParametrosModificar);
	}
}