import { Injectable } from '@angular/core';

import { 
    ApiService
} from "./api.service";

import {
	RelacionCentralizadorColaboradorConstante
} from "./../config/constantes";

@Injectable()
export class RelacionCentralizadorColaboradorService {
	constructor(
		private _relacionConstante: RelacionCentralizadorColaboradorConstante,
		private _apiServicio: ApiService) {
    }
    guardar(relacion) {
        return this._apiServicio.Put(relacion, this._relacionConstante.URLRelacionCentralizadorColaboradoGuardar);
    }

    obtenerRelacionCentralizadorColaborador(centralizador)
    {
        return this._apiServicio.Get(this._relacionConstante.URLRelacionCentralizadorColaboradoObtener + centralizador);
    }

    removerRelacionCentralizadorColaborador(idrelacion)
    {
        return this._apiServicio.Post(idrelacion,this._relacionConstante.URLRelacionCentralizadorColaboradoRemover);
    }

    removerRelacionCentralizadorColaboradorCentralizador(posicion)
    {
        return this._apiServicio.Post(posicion,this._relacionConstante.URLRelacionCentralizadorColaboradoRemoverCentralizador);
    }

    obtenerCentralizadorColaborador(datos)
    {
        return this._apiServicio.Post(datos,this._relacionConstante.URLObtenerCentralizadorColaborador);
    }

    obtenerSinCentralizadorColaborador(datos)
    {
        return this._apiServicio.Post(datos,this._relacionConstante.URLObtenerSinCentralizadorColaborador);
    }
}