 import { Injectable } from '@angular/core';

import { 
    ApiService
} from "./api.service";

import {
	ReportesConstante
} from "./../config/constantes";

@Injectable()
export class ReportesService {
	constructor(
		private _reportesConstante: ReportesConstante,
		private _apiServicio: ApiService) {
    }

    consultarColaboradoresSinUsarLA()
    {
        return this._apiServicio.Get(this._reportesConstante.URLReporteColaboradoresSinUsarLA);
    }

    consultarLASinModificaciones()
    {
        return this._apiServicio.Get(this._reportesConstante.URLReporteListaActividadesSinCambios);
    }

    consultarDocumentos1anio()
    {
        return this._apiServicio.Get(this._reportesConstante.URLReporteDocumentos1anio);
    }

    consultarDocumentosColaborador()
    {
        return this._apiServicio.Get(this._reportesConstante.URLReporteDocumentosColaborador);
    }

    consultarNumeroSolicitudesActivas()
    {
        return this._apiServicio.Get(this._reportesConstante.URLconsultarNumeroSolicitudesActivas);
    }
    
    consultarGraficoDocumentosSLA()
    {
        return this._apiServicio.Get(this._reportesConstante.URLconsultarGraficoDocumentosSLA);
    }

    obtenerEstatusFoliosSGC()
    {
        return this._apiServicio.Get(this._reportesConstante.URLobtenerEstatusFoliosSGC);
    }

    consultarGraficoPendientesAdministrador()
    {
        return this._apiServicio.Get(this._reportesConstante.URLconsultarGraficoPendientesAdministrador);
    }

    consultarGraficoSolicitudesUDN()
    {
        return this._apiServicio.Get(this._reportesConstante.URLconsultarGraficoSolicitudesUDN);
    }
}