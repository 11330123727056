import { Injectable } from '@angular/core';
import { 
	ApiService 
} from "./api.service";

import {
  TuberiasConstantes
}from "../config/constantes";

@Injectable()
export class TuberiasService {

  constructor(   
    private _constantes: TuberiasConstantes,
    private _apiServicio: ApiService) { }

    ObtenerFrecuenciaReporteTuberia(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerFrecuenciaReporteTuberia +
        '?frecuencia=' + datos.frecuencia +
        '&nPosicion=' + datos.nPosicion);
    }

    ObtenerDocumentTuberias(nombreDocumento, rutaDocumento){
      return this._apiServicio.Download(this._constantes.URLObtenerDocumentTuberias +
        '?nomDocumento=' + nombreDocumento +
        '&rutaDocumento=' + rutaDocumento);
    }

    verificaPermisoTuberias(posicion, grupoid) {
      return this._apiServicio.Get(this._constantes.URLObtenerPermisoTuberias + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
  }

  ObtenerFechaTuberia(idsk_veo, nomDocumento){
    return this._apiServicio.Get(this._constantes.URLObtenerFechaReporte +
      '?idsk_veo=' + idsk_veo +
      '&nomDocumento=' + nomDocumento);
  }

  ObtenerPermisoHerramientasAdmin(empleadoId, grupoid, Herramienta){
    return this._apiServicio.Get(this._constantes.URLObtenerPermisoHerramientasAdmin + '?EmpleadoId=' + empleadoId + '&GrupoID=' + grupoid + '&Herramienta=' + Herramienta);
  }
}