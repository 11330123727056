import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

import { MejoraPropuestaConstantes } from '../config/constantes';

@Injectable({
    providedIn: 'root'
})

export class MejoraPropuestaService {
    constructor(private _apiServicio: ApiService,
                private _mejorasConstantes: MejoraPropuestaConstantes) {}

    ObtenerDatosEmpleado(numeroEmpleado) {
        return this._apiServicio.Get(this._mejorasConstantes.URLDatosEmpleadoMejora + numeroEmpleado);
    }

    GuardarMejora(mejora) {
        return this._apiServicio.Upload(mejora.fileToUpload, this._mejorasConstantes.URLGuardaMejora
            + "?ID=" + mejora.id
            + "&numeroEmpleado=" + mejora.numeroEmpleado
            + "&fechaCreacion=" + mejora.fechaCreacion
            + "&idEmpresa=" + mejora.idEmpresa
            + "&codigo=" + mejora.codigo
			+ "&idDireccion=" + mejora.idDireccion
			+ '&idUnidadNegocio=' + mejora.idUnidadNegocio
            + '&idPuesto=' + mejora.idPuesto
			+ '&idTipoMejora=' + mejora.idTipoMejora
			+ '&descripcion=' + mejora.descripcion
            + '&nombreArchivo=' + mejora.nombreArchivo
            + '&archivoOriginal=' + mejora.archivoOriginal
            + '&estemporal=' + mejora.estemporal
            + '&estatus=' + mejora.estatus
            + '&tieneimagen=' + mejora.tieneImagen
            );
    }

    ObtenerListadoMejoras(numeroEmpleado,posicion,esAdmin) {
        return this._apiServicio.Get(this._mejorasConstantes.URLDatosListadoMejoras
            + "?NumEmpleado=" + numeroEmpleado
            + "&Posicion=" + posicion
            + "&EsAdmin=" + esAdmin
            );
    }

    ObtenerDatosMejora(idMejora) {
        return this._apiServicio.Get(this._mejorasConstantes.URLDatosMejoras + idMejora);
    }

    UpdateEstatusMejora(idMejora,estatus,fecha) {
        return this._apiServicio.Post({idMejora: idMejora, estatus: estatus, fechaCreacion: fecha},this._mejorasConstantes.URLUpdateEstatusMejoras);
    }

    ObtenerListadoTemporalMejoras(numeroEmpleado) {
        return this._apiServicio.Get(this._mejorasConstantes.URLDatosListadoTemporalMejoras + numeroEmpleado);
    }

    EliminaMejora(idMejora) {
        return this._apiServicio.Delete(this._mejorasConstantes.URLEliminaMejoras + idMejora);
    }

    DescargaDocumentoMejora(ruta,nombreArchivo) {
        return this._apiServicio.Get(this._mejorasConstantes.URLDescargaDocumentoMejoras
            + "?ruta=" + ruta
            + "&documento=" + nombreArchivo
            );
    }

    GestionMejora(mejora) {
        return this._apiServicio.Post(mejora,this._mejorasConstantes.URLGestionMejora);
    }

    ConcluirMejora(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLConcluirMejora);
    }

    EnviarCorreoConcluirlMejoras(datos) {
        return this._apiServicio.Get(this._mejorasConstantes.URLEnviarCorreoConcluirMejora
            + "?folio=" + datos.folio
            + "&tipoCambio=" + datos.tipoCambio
            + "&usuarioUltimaModificacion=" + datos.usuarioUltimaModificacion
            + "&correoCreador=" + datos.correo
            + "&comentarios=" + datos.comentarios
            + "&estatus=" + datos.estatus
            + "&nombre=" + datos.nombreMejora
            + "&solicitante=" + datos.solicitante
            + "&IsSolicitante=" + datos.IsSolicitante
            );
    }

    ObtenerCatalogoMejoras(){
        return this._apiServicio.Get(this._mejorasConstantes.URLObtenerCatalogoMejoras);
    }

    GuardarCatalogoMejoras(datos){
        return this._apiServicio.Post(datos,this._mejorasConstantes.URLGuardarCatalogoMejoras);
    }

    UpdateTemporalMejora(numeroEmpleado) {
        return this._apiServicio.Put({numeroEmpleado: numeroEmpleado},this._mejorasConstantes.URLUpdateTemporalMejora);
    }

    EnviarCorreoCentralizadorMejoras(datos) {
        return this._apiServicio.Get(this._mejorasConstantes.URLEnviarCorreoCentralizador
            + "?folio=" + datos.folio
            + "&posicion=" + datos.posicion
            + "&solicitante=" + datos.solicitante
            + "&nombrePropuesta=" + datos.nombrePropuesta
            );
    }
    enviaCorreoCentralizadorChange(datos){
        return this._apiServicio.Get(this._mejorasConstantes.URLenviaCorreoCentralizadorChange
            + "?folio=" + datos.folio
            + "&posicion=" + datos.posicion
            + "&solicitante=" + datos.solicitante
            + "&nombrePropuesta=" + datos.nombrePropuesta
            );
    }

    EnviarCorreoResponsableMejoras(datos) {
        return this._apiServicio.Get(this._mejorasConstantes.URLEnviarCorreoResponsable
            + "?folio=" + datos.folio
            + "&tipoCambio=" + datos.tipoCambio
            + "&solicitante=" + datos.solicitante
            + "&correo=" + datos.correo
            + "&comentarios=" + datos.comentarios
            + "&nombre=" + datos.nombre         
            );
    }

    UpdateFechaCompromiso(datos){
        return this._apiServicio.Put(datos, this._mejorasConstantes.URLUpdateFechaCompromisoMejora)
    }

    updateResponsable(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLUpdateResponsableMejora);
    }

    CreateCentralizadorTempMejoras(datos){
        return this._apiServicio.Post(datos,this._mejorasConstantes.URLCreateCentralizadorTempMejora);
    }

    ObtenerCentralizadoresTemporalMejoras(esAdmin,posicion) {
        return this._apiServicio.Get(this._mejorasConstantes.URLObtenerCentralizadoresTempMejora
            + "?admin=" + esAdmin
            + "&posicion=" + posicion
            );
    }

    cerrarCentralizadorTemporal(id) {
        return this._apiServicio.Put({id: id},this._mejorasConstantes.URLCerrarCentralizadorTempMejora);
    }

    GetPosicionCentralizador(posicion,fecha) {
        return this._apiServicio.Get(this._mejorasConstantes.URLGetPosicionCentralizador
            + "?posicion=" + posicion
            + "&fecha=" + fecha
            );
    }

    GetAllCentralizadores() {
        return this._apiServicio.Get(this._mejorasConstantes.URLGetAllCentralizadores);
    }

    updateSolicitante(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLUpdateSolicitanteMejora);
    }

    updateEstatus(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLUpdateEstatus);
    }

    updateComentario(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLUpdateComentario);
    }

    changeCentralizador(mejora) {
        return this._apiServicio.Put(mejora,this._mejorasConstantes.URLChangeCentralizador);
    }
    SubirEvidenciaConcluir(datos){
        return this._apiServicio.Post(datos,this._mejorasConstantes.URLSubirEvidenciaConcluir);
    }
    TraerEvidenciaConcluir(FolioMejora){
        return this._apiServicio.Post(FolioMejora,this._mejorasConstantes.URLTraerEvidencia);
    }

    TraerCentralizadores(){
        return this._apiServicio.Get(this._mejorasConstantes.URLTraerTodosCentralizadores);
    }
    
}