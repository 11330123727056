import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from "../guards/Auth.guard";
import { ValidateAccessGuard } from "../guards/validate-access.guard";
import { TableroComponent } from './tablero/tablero/tablero.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '',  redirectTo: 'tablero', pathMatch: 'full'},
            { path: 'configuracionMenu', loadChildren: () => import('./Administracion/configuracion-menu/configuracion-menu.module').then(m => m.ConfiguracionMenuModule),canActivate:[ValidateAccessGuard] },
            { path: 'tablero',loadChildren: () => import('./tablero/tablero.module').then(m => m.TableroModule) },
            { path: 'controlAccesoPortal', loadChildren: () => import('./Administracion/control-acceso-portal/control-acceso-portal.module').then(m => m.ControlAccesoPortalModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlAccesoUsuarios', loadChildren: () => import('./Administracion/control-acceso-usuarios/control-acceso-usuarios.module').then(m => m.ControlAccesoUsuariosModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlTipoCambio', loadChildren: () => import('./Administracion/control-tipo-cambio/control-tipo-cambio.module').then(m => m.ControlTipoCambioModule),canActivate:[ValidateAccessGuard] },
            { path: 'flujoAutorizacion', loadChildren: () => import('./Administracion/control-flujo-autorizacion/flujo-autorizacion.module').then(m => m.FlujoAutorizacionModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlAdminCentralizadores', loadChildren: () => import('./Administracion/control-admin-centralizadores/control-admin-centralizadores.module').then(m => m.AdminCentralizadoresModule),canActivate:[ValidateAccessGuard] },
            { path: 'solicitudCambio', loadChildren: () => import('./Gestion/solicitud-cambio/solicitud-cambio.module').then(m => m.SolicitudCambioModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlAutorizaciones', loadChildren: () => import('./Gestion/control-autorizaciones/control-autorizaciones.module').then(m => m.ControlAutorizacionModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlRepositorioHerramientas', loadChildren: () => import('./Gestion/control-repositorio-herramientas/control-repositorio-herramientas.module').then(m => m.ControlRepositorioHerramientasModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlBitacoraHerramientas', loadChildren: () => import('./Gestion/control-bitacora-herramientas/bitacora-herramientas.module').then(m => m.BitacoraHerramientaModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlGestionDocumental', loadChildren: () => import('./Gestion/control-gestion-documental/gestion-documental.module').then(m => m.GestionDocumentalModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlCatalogoHerramientas', loadChildren: () => import('./Administracion/control-herramientas/control-catalogo-herramientas.module').then(m => m.CatalogoHerramientaModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaDocumentos', loadChildren: () => import('./Herramientas/control-herramienta-documento/control-herramienta-documento.module').then(m => m.ControlHerramientaORDOModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlDisignerLA', loadChildren: () => import('./Gestion/control-designer-la/control-designer-la.module').then(m => m.ControlDisenioLAModule),canActivate:[ValidateAccessGuard] },
            //{ path: 'controlHerramientaControlDocumental', loadChildren: () => import('./Herramientas/control-herramienta-control-documental/control-herramienta-control-documental.module').then(m => m.GestionDocumentalModule) },
            { path: 'controlHerramientaLA', loadChildren: () => import('./Herramientas/control-herramienta-la/control-herramienta-la.module').then(m => m.ControlHerramientaLAModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaORDO', loadChildren: () => import('./Herramientas/control-herramienta-ordo/control-herramienta-ordo.module').then(m => m.ControlHerramientaORDOModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaMODOSK', loadChildren: () => import('./Herramientas/control-herramienta-modosk/control-herramienta-modosk.module').then(m => m.ControlHerramientaMODOSKModule),canActivate:[ValidateAccessGuard]},
            { path: 'controlHerramientaJuntas', loadChildren: () => import('./Herramientas/control-herramienta-juntas/control-herramienta-juntas.module').then(m => m.ControlHerramientaJuntasModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaSesiones', loadChildren: () => import('./Herramientas/control-herramienta-sesiones11/control-herramienta-sesiones11.module').then(m => m.ControlHerramientasesiones11Module),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaFOC', loadChildren: () => import('./Herramientas/control-herramienta-foc/control-herramienta-foc.module').then(m => m.ControlHerramientasFOCModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaTuberia', loadChildren: () => import('./Herramientas/control-herramienta-Tuberias/control-herramienta-tuberias.module').then(m => m.ControlHerramientasTuberiasModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaRPD', loadChildren: () => import('./Herramientas/control-herramienta-RPD/control-herramienta-rpd.module').then(m => m.ControlHerramientasRPDModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaHK', loadChildren: () => import('./Herramientas/control-herramienta-HK/control-herramienta-hk.module').then(m => m.ControlHerramientasHKModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaDesiciones', loadChildren: () => import('./Herramientas/control-herramienta-Desiciones/control-herramienta-desiciones.module').then(m => m.ControlHerramientasDesicionesModule),canActivate:[ValidateAccessGuard] },
            { path: 'controlHerramientaMC', loadChildren:() => import('./Herramientas/control-herramienta-matriz-comunicacion/control-herramienta-matriz-comunicacion.module').then(m => m.ControlHerramientaMatrizComunicacionModule),canActivate:[ValidateAccessGuard]},
            { path: 'controlParametros', loadChildren: () => import('./Administracion/control-parametros/control-parametros.module').then(m => m.ParametrosModule),canActivate:[ValidateAccessGuard] },
            { path: 'reportePosicionesSinCentralizador', loadChildren: () => import('./Reportes/PuestosSinCentralizador/reportePosicionesSinCentralizador.Module').then(m => m.ReportePosicionesSinCentrlizadorModule),canActivate:[ValidateAccessGuard] },
            { path: 'comunicacion', loadChildren: () => import('./Comunicacion/comunicacion.module').then(m => m.ComunicacionModule)},
            { path: 'comunicacionPlaneacion', loadChildren: () => import('./Comunicacion/comunicacion-planeacion/comunicacion-planeacion.module').then(m => m.ComunicacionPlaneacionModule),canActivate:[ValidateAccessGuard] },
            { path: 'comunicacionRepositorio', loadChildren: () => import('./Comunicacion/comunicacion-repositorio/comunicacion-repositorio.module').then(m => m.ComunicacionRepositorioModule),canActivate:[ValidateAccessGuard] },
            { path: 'comunicacionConoce', loadChildren: () => import('./Comunicacion/comunicacion-conoce/comunicacion-conoce.module').then(m => m.ComunicacionConoceModule),canActivate:[ValidateAccessGuard] },
            { path: 'comunicacionAuditoria', loadChildren: () => import('./Comunicacion/comunicacion-audioria/comunicacion-auditoria.module').then(m => m.ComunicacionAuditoriaModule),canActivate:[ValidateAccessGuard] },
            { path: 'preguntasFrecuentes', loadChildren: () => import('./PreguntasFrecuentes/preguntas-frecuentes.module').then(m => m.PreguntasFrecuentesModule)},
            { path: 'contacto', loadChildren: () => import('./Contacto/contacto.module').then(m => m.ContactoModule) },
            { path: 'documentacionEvolucion', loadChildren: () => import('./Herramientas/Documentacion/evolucion/documentacion-evolucion.module').then(m => m.DocumentacionEvolucionModule)},
            { path: 'documentacionImpulsores', loadChildren: () => import('./Herramientas/Documentacion/impulsores/documentacion-impulsores.module').then(m => m.DocumentacionImpulsoresModule)},

            { path: 'reporteLAsinCambios', loadChildren: () => import('./Reportes/ListaActividadesSinCambios/reporteListaActividadesSinCambios.Module').then(m => m.ReporteListaActividadesSinCambiosModule),canActivate:[ValidateAccessGuard] },
            { path: 'reporteLAsinUso', loadChildren: () => import('./Reportes/ListaActividadesSinUso/reporteListaActividadesSinUso.Module').then(m => m.ReporteListaActividadesSinCambiosModule),canActivate:[ValidateAccessGuard] },
            { path: 'reporteHerramientasAsignadas', loadChildren: () => import('./Reportes/HerramientasAsignadas/herramientasAsignadas.Module').then(m => m.HerramientasAsignadasModule),canActivate:[ValidateAccessGuard] },
           // { path: 'nodisponible', loadChildren: () => import('./nodisponible/nodisponible.module').then(m => m.NoDisponibleModule) }            
            { path: 'gobernanza', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/gobernanza/gobernanza.module').then(m => m.GobernanzaModule) },
            { path: 'estructuraDelEquipo', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/estructura-del-equipo/estructura-del-equipo.module').then(m => m.EstructuraDelEquipoModule)},
            { path: 'glosarioDefiniciones', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/glosario-definiciones/glosario-definiciones.module').then(m => m.GlosarioDefinicionesModule) },
            { path: 'repositorioHerramientas', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/repositorio-herramientas/repositorio-herramientas.module').then(m => m.RepositorioHerramientasModule) },
            { path: 'talleresTransversales', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/talleres/talleres.module').then(m => m.TalleresModule) },
            { path: 'talleres', loadChildren: () => import('./Herramientas/Documentacion/recursos-de-apoyo/talleres2/talleres.module').then(m => m.TalleresModule) },
			{ path: 'controlGestionDesiciones', loadChildren: () => import('./Gestion/control-gestion-decisiones/control-gestion-decisiones.module').then(m => m.ControlGestionDecisionesModule),canActivate:[ValidateAccessGuard] },
			{ path: 'reporteDesicionesLectura', loadChildren: () => import('./Reportes/DecisionesLectura/decisiones-lectura.Module').then(m => m.DecisionesLecturaModule),canActivate:[ValidateAccessGuard] },
			{ path: 'reporteMatrizComunicacion', loadChildren: () => import('./Reportes/MatrizComunicacion/matriz-comunicacion.module').then(m => m.MatrizComunicacionModule),canActivate:[ValidateAccessGuard] },
            { path: 'ordos', loadChildren: () => import('./Gestion/control-ordos/control-ordos.module').then(m => m.ControlOrdosModule),canActivate:[ValidateAccessGuard]},
			{ path: 'dashboardControlDocumental', loadChildren: () => import('./Reportes/DashboardControlDocumental/dashboardControlDocumental.Module').then(m => m.DashboardCtrlDocumentalModule),canActivate:[ValidateAccessGuard] },
            // { path: 'nodisponible', loadChildren: () => import('./nodisponible/nodisponible.module').then(m => m.NoDisponibleModule) },
            { path: 'MatrizComunicacion', loadChildren: () => import('./Gestion/control-matriz-comunicacion/control-matriz-comunicacion.module').then(m => m.ControlMatrizComunicacionModule),canActivate:[ValidateAccessGuard]},
            //{ path: 'controlgtearquitectura', loadChildren:()=> import ('./Administracion/control-admin-gtearquitecturaprocesos/control-admin-gtearquitecturaprocesos.module').then(m => m.ControlAdminGtearquitecturaprocesosModule),canActivate:[ValidateAccessGuard]},
            { path: 'reporteRendicionCuentas', loadChildren:()=> import ('./Reportes/ReporteRendicionCuentas/reporte-rendicion-cuentas.module').then(m => m.ReporteRendicionCuentasModule),canActivate:[ValidateAccessGuard]},
            { path: 'reportesLA', loadChildren:()=> import ('./Reportes/reportes-la/reportes-la.component.Module').then(m => m.ReportesLAModule),canActivate:[ValidateAccessGuard]},
			{ path: 'controlPropuestasMejoras', loadChildren:()=> import ('./Gestion/control-propuestas-mejoras/control-propuestas-mejoras.module').then(m => m.ControlPropuestasMejorasModule),canActivate:[ValidateAccessGuard]},
            { path: 'areaTrabajoArquitectura', loadChildren:()=> import ('./Gestion/area-trabajo-arquitectura/area-trabajo-arquitectura.module').then(m => m.AreaTrabajoArquitecturaModule),canActivate:[ValidateAccessGuard]},        
            { path: 'controlchecklist', loadChildren:()=> import ('./Gestion/control-checklist/control-checklist.module').then(m => m.ControlChecklistModule),canActivate:[ValidateAccessGuard]},
            { path: 'controlComunicacion', loadChildren:()=> import ('./Gestion/control-comunicacion/control-comunicacion.module').then(m => m.ControlComunicacionModule),canActivate:[ValidateAccessGuard]},




            { path: 'llenadoDeCheckList', loadChildren:()=> import ('./Gestion/llenadochecklist/llenadochecklist.module').then(m => m.LlenadochecklistModule),},
            { path: 'controlIndiceDesempeno', loadChildren:()=> import ('./Gestion/control-indice-desempeno/control-indice-desempeno.module').then(m => m.ControlIndiceDesempenoModule),},
            { path: 'auditoriaCultura', loadChildren:()=> import ('./AuditoriaCultura/auditoria-cultura/auditoria-cultura.module').then(m => m.AuditoriaCulturaModule),},
            { path: 'controlInformes', loadChildren:()=> import ('./Informes/control-informes.module').then(m => m.ControlInformesModule),},
            { path: 'BitacoraUsuario', loadChildren:()=> import ('./Reportes/reportes-usuario/reportes-usuario.component.Module').then(m => m.ReportesLoginUsuarioModule),},
            

        ]
    },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: '**', redirectTo: 'tablero' }
];