import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
//import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';

//import { SocketService } from '../services/socket.service';
//import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';
//import { Uris } from '../services/Uris';

import { SharedModule } from '../shared/shared.module';
import { ModalNotificacionesComponent } from './modal-notificaciones/modal-notificaciones.component';
import { ListaNotificacionesComponent } from './modal-notificaciones/lista-notificaciones/lista-notificaciones.component';
import { MatIconModule } from '@angular/material';

//Configuraciòn para los sockets
//const config: SocketIoConfig = { url: Uris.API_ENDPOINTSOCKET, options: { path: 'apiportal/socket.io', reconnect: true, transports: ['polling']}};
// const config: SocketIoConfig = { url: Uris.API_ENDPOINT, options: {}};
@NgModule({
    imports: [
        SharedModule,
        MatIconModule,
        //SocketIoModule.forRoot(config)
    ],
    providers: [
        UserblockService,
        //SocketService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        //OffsidebarComponent,
        FooterComponent,
        ModalNotificacionesComponent,
        ListaNotificacionesComponent
    ],
    entryComponents:[
        ModalNotificacionesComponent,
        ListaNotificacionesComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        //OffsidebarComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
