import { Injectable } from '@angular/core';

import {
  ApiService
} from "./api.service";

import {
  GteArquitecturaProcesosConstante
}from "./../config/constantes";

@Injectable({
  providedIn: 'root'
})
export class GteArquitecturaProcesosService {

  constructor(
    private _constantes: GteArquitecturaProcesosConstante,
    private _apiServicio: ApiService
  ) { }

  obtenerGerentes(){
    return this._apiServicio.Get(this._constantes.URLObtenerGerentes)
  }
  guardarRelacion(datos){
    console.log(datos);    
    return this._apiServicio.Post(datos,this._constantes.URLGuardar
      + '?nPosicion='+datos.nPosicion
      + '&IdDireccion='+datos.Id_Direccion
      + '&Descripcion_Direccion='+datos.Descripcion_Direccion
      + '&Id_Area='+datos.IdArea
      + '&Descripcion_Area='+datos.Descripcion_Area
      )
  }
  buscarRelacion(datos){
    return this._apiServicio.Get(this._constantes.URLObtenerRelacion
      + '?nPosicion='+datos)
  }
  eliminarRelacion(datos){
    console.log(datos);
    
    return this._apiServicio.Post(datos,this._constantes.URLEliminarRelacion
      + '?nPosicion='+datos)
  }
  eliminarPorID(datos){
    console.log(datos);
    
    return this._apiServicio.Post(datos,this._constantes.URLEliminarPorID
      + '?Id='+datos)
  }

  buscarAreas(datos){
    console.log(datos);   
    return this._apiServicio.Get(this._constantes.URLBuscarAreas
      + '?Id_Area='+datos)
  }
  buscarDireccionId(datos){
    console.log(datos);   
    return this._apiServicio.Get(this._constantes.URLDireccionPorId
      + '?IdDireccion='+datos)
  }
  valdarRelacion(datos){
    console.log(datos);   
    return this._apiServicio.Get(this._constantes.URLValidarRelaciion
      + '?IdDireccion='+datos.IdDireccion
      + '&Id_Area='+datos.Id_Area)
  }
}
