import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	ModuloConstante
} from "./../config/constantes";

@Injectable()
export class ModuloService {
	constructor(
		private _moduloConstante: ModuloConstante,
		private _apiServicio: ApiService) {
    }
	
	ObtenerTodos() {
		return this._apiServicio.Get(this._moduloConstante.URLModulosFormulario).pipe(map((response: any) => response.json()));
	}
	
    Crear(modulo) {
		return this._apiServicio.Post(modulo, this._moduloConstante.URLModulos).pipe(map((response: any) => response.json()))
	}
	
	Actualizar(id, modulo) {
		return this._apiServicio.Put(modulo, this._moduloConstante.URLModulosFormularioActualizar).pipe(map((response: any) => response.json()))
	}
}