import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	LoginConstante
} from "./../config/constantes";

@Injectable()
export class TableroService {
	constructor(
		//private _formularioConstante: FormularioConstante,
		private _apiServicio: ApiService) {
	}

	getTables(body) {
		//return this.http.post(Uris.API_ENDPOINT + `/api/NotificacionesAlertas/Tables`, body,
		//this.jwt()).pipe(map((response: any) => response.json()));
		return body;
	}
	getNotificaciones(body) {
		//return this.http.post(Uris.API_ENDPOINT + `/api/NotificacionesAlertas/Notificaciones`, body,this.jwt()).pipe(map((response: any) => response.json()));
		return body;
	}
}
