import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import {
	ApiService
} from "./api.service";

import {
	CatalogoCtrlDocumentalConstante,
} from "./../config/constantes";

@Injectable()
export class CatalogoCtrlDocService {
	constructor(
		private _catalogoConstante: CatalogoCtrlDocumentalConstante,
		private _apiServicio: ApiService) {
	}


	ObtenerCatalogos() {
		return this._apiServicio.Get(this._catalogoConstante.URLObtenerCatalogos);
	}

	ObtenerDetallesCatalogos(idCatalogo) {
		return this._apiServicio.Get(this._catalogoConstante.URLObtenerDetallesCatalogos + "?idCatalogo=" + idCatalogo);
	}

	ObtenerDetallePorId(idDetalle) {
		return this._apiServicio.Get(this._catalogoConstante.URLObtenerDetallePorId + "?idDetalle=" + idDetalle);
	}

	ObtenerNiveles() {
		return this._apiServicio.Get(this._catalogoConstante.URLObtenerNiveles);
	}

	ObtenerNivelPorId(idNivel) {
		return this._apiServicio.Get(this._catalogoConstante.URLObtenerNivelPorId + "?idNivel=" + idNivel);
	}
	AgregarNivel(nivel) {
		return this._apiServicio.Post(nivel, this._catalogoConstante.URLAgregarNivel +
			"?CategoriaNivel=" + nivel.CategoriaNivel
			+ "&TiempoAtencionMinimo=" + nivel.TiempoAtencionMinimo
			+ "&TiempoAtencionMaximo=" + nivel.TiempoAtencionMaximo
		);
	}

	AgregarDetalleCatalogo(catalogo) {
		return this._apiServicio.Post(catalogo, this._catalogoConstante.URLAgregarDetalleCatalogo
			+ "?IdPrioridad=" + catalogo.IdPrioridad
			+ "&Descripcion=" + encodeURIComponent(catalogo.Descripcion)
			+ "&Activo=" + catalogo.Activo
			+ "&IdUsuarioCreacion=" + catalogo.IdUsuarioCreacion
			+ "&IdCatalogo=" + catalogo.IdCatalogo
		);
	}


	ActualizarDetalleCatalogo(catalogo) { 
		return this._apiServicio.Post(catalogo, this._catalogoConstante.URLActualizarDetalleCatalogo
			+ "?Id=" + catalogo.Id
			+ "&IdPrioridad=" + catalogo.IdPrioridad
			+ "&Descripcion=" + encodeURIComponent(catalogo.Descripcion)
			+ "&Activo=" + catalogo.Activo
			+ "&IdUsuarioModificacion=" + catalogo.IdUsuarioModificacion
		);
	}

	ActualizarNivel(nivel) {
		return this._apiServicio.Post(nivel, this._catalogoConstante.URLActualizarNivel
			+ "?IdArea=" + nivel.IdArea
			+ "&CategoriaNivel=" + nivel.CategoriaNivel
			+ "&TiempoAtencionMinimo=" + nivel.TiempoAtencionMinimo
			+ "&TiempoAtencionMaximo=" + nivel.TiempoAtencionMaximo
		);
	}

}
