import { Injectable } from '@angular/core';
import { map, refCount } from "rxjs/operators";

import {
  ApiService
} from "./api.service";

import {
  ORDOSConstante
}from "./../config/constantes";

@Injectable()
export class OrdosService {
  constructor(
    private _constantes: ORDOSConstante,
    private _apiServicio: ApiService) {
     }
    
     Buscar()
     {
         return this._apiServicio.Get(this._constantes.URLObtener);
     }

     ObtenerAreas(datos)
     {       
         return this._apiServicio.Get(this._constantes.URLAreas+ '?Id_Direccion='+datos);
     }
    
     ObtenerPuestos(datos)
     {
         return this._apiServicio.Get(this._constantes.URlPuestos+'?Id_Area='+datos);
     }

     GuardadoInicial(datos)
     {
       
       return this._apiServicio.Post(datos,this._constantes.URLGInicial       
        + '?Version='+datos.Version
        + '&Codigo= '+datos.Codigo
        + '&Nombre='+datos.Nombre
        + '&Area='+datos.Area
        + '&Reporta='+datos.Reporta
        + '&UNegocio='+datos.UNegocio
        + '&Fecha='+ datos.Fecha
        + '&Acargo='+ datos.Acargo
        + '&Estado='+ datos.Estado)
     }
    ObtenerID(){
      return this._apiServicio.Get(this._constantes.URlUltimoID);
    }

    ActualizarPaso3(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizar
        + '?Id='+datos.Id
        + '&Objetivo='+datos.Objetivo
        );
    }
    ActualizarPaso4(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizar
        + '?Id='+datos.Id
        + '&FDirectos='+datos.FDirectos
        + '&Organi='+ datos.Organi
        + '&FIndirectos='+datos.FIndirectos
        + '&FInternas='+datos.FInternas
        + '&FExternas='+datos.FExternas
        + '&FInternasDe='+datos.FInternasDe
        + '&FInternasPro='+datos.FInternasPro
        + '&FInternasInfo='+datos.FInternasInfo
        + '&FExternasDe='+datos.FExternasDe
        + '&FExternasPro='+datos.FExternasPro
        + '&FExternasInfo='+datos.FExternasInfo
        );
    }

    ActualizarPaso5(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizar
        + '?Id='+datos.Id
        + '&Escolaridad='+datos.Escolaridad
        + '&Idiomas='+datos.Idiomas
        + '&Conocimientos='+datos.Conocimientos
        + '&Habilidades='+datos.Habilidades
        + '&Expe1='+ datos.Expe1
        + '&ExpeTiempo1='+datos.ExpeTiempo1
        + '&Expe2='+ datos.Expe2
        + '&ExpeTiempo2='+datos.ExpeTiempo2
        + '&Expe3='+ datos.Expe3
        + '&ExpeTiempo3='+datos.ExpeTiempo3
        + '&Expe4='+ datos.Expe4
        + '&ExpeTiempo4='+datos.ExpeTiempo4
        );
    }
    ActualizarFinal(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&FDirectos='+datos.FDirectos
        + '&Organi='+ datos.Organi
        + '&FIndirectos='+datos.FIndirectos
        + '&Laptop='+datos.Laptop
        + '&Pc='+datos.Pc
        + '&Tablet='+datos.Tablet
        + '&Celular='+datos.Celular
        + '&Extension='+datos.Extension
        + '&LDistancia='+datos.LDistancia
        + '&Activo='+datos.Activo
        + '&Expe1='+ datos.Expe1
        + '&ExpeTiempo1='+datos.ExpeTiempo1
        + '&Expe2='+ datos.Expe2
        + '&ExpeTiempo2='+datos.ExpeTiempo2
        + '&Expe3='+ datos.Expe3
        + '&ExpeTiempo3='+datos.ExpeTiempo3
        + '&Expe4='+ datos.Expe4
        + '&ExpeTiempo4='+datos.ExpeTiempo4
        + '&ElaboroN='+datos.ElaboroN
        + '&ElaboroP='+datos.ElaboroP
        + '&ElaboroNP='+datos.ElaboroNP
        + '&Estado='+datos.Estado
        + '&FLujo='+datos.FLujo
        + '&CrearNode='+datos.CrearNode
        );
    }
    ActualizarEstado(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarEstado
        + '?Id='+datos.Id
        + '&Activo='+datos.Activo)
    }

    ActualizarORDO(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&Nombre='+datos.Nombre
        + '&Objetivo='+datos.Objetivo
        + '&FDirectos='+datos.FDirectos
        + '&FIndirectos='+datos.FIndirectos
        + '&FInternas='+datos.FInternas
        + '&FExternas='+datos.FExternas
        + '&FInternasDe='+datos.FInternasDe
        + '&FInternasPro='+datos.FInternasPro
        + '&FInternasInfo='+datos.FInternasInfo
        + '&FExternasDe='+datos.FExternasDe
        + '&FExternasPro='+datos.FExternasPro
        + '&FExternasInfo='+datos.FExternasInfo
        + '&Escolaridad='+datos.Escolaridad
        + '&Idiomas='+datos.Idiomas
        + '&Conocimientos='+datos.Conocimientos
        + '&Habilidades='+datos.Habilidades
        + '&Laptop='+datos.Laptop
        + '&Pc='+datos.Pc
        + '&Tablet='+datos.Tablet
        + '&Celular='+datos.Celular
        + '&Extension='+datos.Extension
        + '&LDistancia='+datos.LDistancia
        + '&Expe1='+datos.Expe1
        + '&ExpeTiempo1='+datos.ExpeTiempo1
        + '&Expe2='+datos.Expe2
        + '&ExpeTiempo2='+datos.ExpeTiempo2
        + '&Expe3='+datos.Expe3
        + '&ExpeTiempo3='+datos.ExpeTiempo3
        + '&Expe4='+datos.Expe4
        + '&ExpeTiempo4='+datos.ExpeTiempo4
        + '&ValidoN='+datos.ValidoN
        + '&ValidoP='+datos.ValidoP
        + '&UsuarioModificacion='+datos.UsuarioModificacion
        + '&FechaModificacion='+datos.FechaModificacion
        + '&CrearNode='+datos.CrearNode

        )
    }
    ActualizarORDOArquitecto(datos){
      datos.Id = datos.IdOrdo;
      datos.IdOrdo = null;
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&Nombre='+datos.Nombre
        + '&Objetivo='+datos.Objetivo
        + '&FDirectos='+datos.FDirectos
        + '&FIndirectos='+datos.FIndirectos
        + '&FInternas='+datos.FInternas
        + '&FExternas='+datos.FExternas
        + '&FInternasDe='+datos.FInternasDe
        + '&FInternasPro='+datos.FInternasPro
        + '&FInternasInfo='+datos.FInternasInfo
        + '&FExternasDe='+datos.FExternasDe
        + '&FExternasPro='+datos.FExternasPro
        + '&FExternasInfo='+datos.FExternasInfo
        + '&Escolaridad='+datos.Escolaridad
        + '&Idiomas='+datos.Idiomas
        + '&Conocimientos='+datos.Conocimientos
        + '&Habilidades='+datos.Habilidades
        + '&Laptop='+datos.Laptop
        + '&Pc='+datos.Pc
        + '&Tablet='+datos.Tablet
        + '&Celular='+datos.Celular
        + '&Extension='+datos.Extension
        + '&LDistancia='+datos.LDistancia
        + '&Expe1='+datos.Expe1
        + '&ExpeTiempo1='+datos.ExpeTiempo1
        + '&Expe2='+datos.Expe2
        + '&ExpeTiempo2='+datos.ExpeTiempo2
        + '&Expe3='+datos.Expe3
        + '&ExpeTiempo3='+datos.ExpeTiempo3
        + '&Expe4='+datos.Expe4
        + '&ExpeTiempo4='+datos.ExpeTiempo4
        )
    }
    

    GuardarExperiencia(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGExpe
        + '?IdOrdo='+datos.IdOrdo
        + '&Descripcion='+datos.Experiencia
        + '&Tiempo='+datos.Tiempo);
    }
    ActualizarExperiencia(datos){      
      return this._apiServicio.Post(datos,this._constantes.URLAExpe
        + '?Id='+datos.Id
        + '&Descripcion='+datos.Descripcion
        + '&Tiempo='+datos.Tiempo
        + '&Editado=1')
    }

    BorrarExpriencia(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBExpe
        + '?Id='+datos.Id);
    }

    ObtenerExperiencia(datos){
      return this._apiServicio.Get(this._constantes.URLOExpe+'?IdOrdo='+datos);
    }

    GuardarHerramientas(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGHer
        + '?IdOrdo='+ datos.IdOrdo
        + '&Cantidad='+ datos.Cantidad
        + '&Herramientas='+datos.Herramientas
        + '&Descripcion='+ datos.Descripcion
        );
    }

    ActualizarHerramientas(datos){
      return this._apiServicio.Post(datos, this._constantes.URLAHer
        + '?Id='+datos.Id
        + '&Cantidad='+datos.Cantidad
        + '&Herramientas='+datos.Herramientas
        + '&Descripcion='+datos.Descripcion
        + '&Editado=1')
    }

    ObtenerHerramientas(datos){
      return this._apiServicio.Get(this._constantes.URLOHer+'?IdORDO='+datos);
    }
    ObtenerHerramientasMiPerfil(datos){
      return this._apiServicio.Get(this._constantes.URLHerMiPerfil+'?nEmpleado='+datos);
    }
    ObtenerHerramientas_Eliminados(datos){
      return this._apiServicio.Get(this._constantes.URLOHer_Eliminados+'?IdORDO='+datos);
    }
    BorrarHerramientas(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBHer+'?Id='+datos.Id)
    }

    OrdoFinal(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerID+'?Id='+datos);
    }
    OrdoNnull(datos){
      return this._apiServicio.Get(this._constantes.URLNnull+ '?Permiso='+datos.permisos+ '&Puesto='+datos.puesto);
    }

    OrdoAsignarNnull(){
      return this._apiServicio.Get(this._constantes.URLAsignarNnull);
    }

    ObtenerPosiciones(){
      return this._apiServicio.Get(this._constantes.URLOPos);
    }
    

    ObtenerAsignados(){
      return this._apiServicio.Get(this._constantes.URLOAsig);
    }
    ObtenerAsignadosId(datos){
      return this._apiServicio.Get(this._constantes.URLAsigID+ '?IdOrdo='+datos)
    }
    ObtenerAsignadosPos(datos){
      return this._apiServicio.Get(this._constantes.URLApPos+ '?Posicion='+datos)
    }
    GuardarAsignados(datos){
      return this._apiServicio.Post(datos,this._constantes.URLGAsig
        + '?IdOrdo='+datos.IdOrdo
        + '&NOrdo='+datos.NOrdo
        + '&Area='+datos.Area
        + '&Posicion='+datos.posicion)
    }

    ObtenerAsignadosFiltrado(datos){
      return this._apiServicio.Get(this._constantes.URLOAsigF
        + '?Posicion='+datos)
    }

    ObtenerEmpleadoPorPuesto(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerEmpleadoPorPuesto + '?cPuesto='+datos)
    }
    ObtenerEmpleadoPorPosicion(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerPorPosicion + '?nPosicion='+datos)
    }

    ObtenerCentralizador(datos){     
      return this._apiServicio.Get(this._constantes.URLObtenerCentralizadorPorID+ '?ColaboradorPosicionID='+datos)
    }
    ObtenerDProceso(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerDProcesos + '?id_Area='+datos)
    }

    ObtenerNotificacionesPorId(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNotificaciones + '?Notificado='+datos)
    }
    ObtenerNotificacionesAqui(){
      return this._apiServicio.Get(this._constantes.URLObtenerArqui)
    }
    ObtenerNotificacionesORDO(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNotificaciones)
    }
    GuardarNotificaciones(datos){
      return this._apiServicio.Post(datos,this._constantes.URLGuardarNotificacion
        + '?IdOrdo='+ datos.IdOrdo
        + '&Notificado='+ datos.Notificado
        + '&Folio='+ datos.Folio
        + '&Herramienta='+ datos.Herramienta
        + '&Solicitante='+ datos.Solicitante
        + '&NOrdo='+ datos.NOrdo
        + '&AOrdo='+ datos.AOrdo
        + '&ReportaA='+ datos.ReportaA      
        + '&Correo='+ datos.Correo
        + '&Estado='+ datos.Estado     
        + '&Flujo='+ datos.Flujo   
        )
    }

    ObtenerUltimoFolio(){
      return this._apiServicio.Get(this._constantes.URLUltimoFolio);
    }
    ObtenerUltimaNotificacion(){
      return this._apiServicio.Get(this._constantes.URLUltimaNoti);
    }
    ObtenerIdArea(datos){
      return this._apiServicio.Get(this._constantes.URLIDAreas+'?Descripcion='+datos);
    }
    
    ObtenerJefe(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerJefe+ '?PosicionIdColaborador='+datos);
    }

    ActualizarFlujo(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizar
       )
    }
    ActualizarFlujoORDO(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarORDO
        +'?Id='+datos.Id
        +'&Estado='+datos.Estado)
    }
    ActualizarPorORDO(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarPorORDO
        + '?IdOrdo='+datos.IdOrdo
        + '&Motivo='+datos.Motivo
        + '&Estado='+datos.Estado
        + '&Flujo='+datos.Flujo
        )
    }
    ActualizarNotificacionFinal(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarPorORDO
        + '?IdOrdo='+datos
        + '&Motivo=Autorizada'
        + '&Estado=3')
    }
    ActualizarORDOFlujo(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&ValidoN='+datos.ValidoN
        + '&ValidoP='+datos.ValidoP
        + '&Estado='+datos.Estado)
    }
    ActualizarORDOFlujoCentralizador(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&RevisoN='+datos.RevisoN
        + '&RevisoP='+datos.RevisoP
        + '&Estado='+datos.Estado)
    }


    ActualizarORDOFlujoRechazados(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&RevisoN='+datos.RevisoN
        + '&RevisoP='+datos.RevisoP
        + '&Estado='+datos.Estado
        + '&FLujo='+datos.FLujo)
    }


    ObtenerRechazados(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerRechazadosPorId
        +'?IdOrdo='+datos)      
    }
    GuardarRechazados(datos){
        console.log(datos);
        
      return this._apiServicio.Post(datos,this._constantes.URLGuardarRechazados
        +'?IdOrdo='+datos.IdOrdo
        +'&Motivo='+datos.Motivo
        +'&PuestoC='+datos.PuestoC
        +'&Correo='+datos.Correo
        +'&Nombre='+datos.Nombre
        +'&Area='+datos.Area
        +'&Reporta='+datos.Reporta)
    }
    ActualizarNoti(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarPorORDO
        + '?IdOrdo='+datos.IdOrdo
        + '&Estado='+datos.Estado
        + '&Motivo='+datos.Motivo
        + '&Nombre='+datos.Nombre
        + '&Area='+datos.Area
        + '&Reporta='+datos.Reporta)
    }
    EnviarAlFlujo(datos){    
      return this._apiServicio.Post(datos, this._constantes.URLActualizarPorORDO
        + '?IdOrdo='+datos.Id
        + '&Solicitante='+datos.Nombre
        + '&Estado=1'
        + '&Flujo='+datos.Flujo)
    }
    EnviarAlFlujoArquitecto(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarPorORDO
        + '?IdOrdo='+datos.Id
        + '&Solicitante='+datos.Nombre
        + '&Estado=2')
    }
    EliminarAsignados(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBAsig
        +'?Id='+datos.Id)
    }

    GuardarAprobados(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarAprobados);
    }
    EliminarAprobados(datos){
      return this._apiServicio.Post(datos, this._constantes.URLBorrarAprobados);
    }
    ObtenerAprobadosFiltrado(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerFiltrados
        +'?IdORDO='+datos);
    }
    ActualizarAprobados(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizarAprobados);
    }

    GuardarOrdosTemporales(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGuardarTemporal);
    }
    ObtenerTemporalPorID(datos){
      return this._apiServicio.Post(datos, this._constantes.URLObtenerTemporalID
        +'?IdORDO='+datos);
    }
    EliminarTemporal(datos){
      return this._apiServicio.Post(datos,this._constantes.URLBorrarTemporal);
    }

    ObtenerEmpleados(){
      return this._apiServicio.Get(this._constantes.URLObtenerEmpleados);
    }
    ObtenerNotificacionesFlujo(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNotificacionPorFlujo
        +'?Notificado='+datos )
    }
    EliminarNotificacion(datos){
      return this._apiServicio.Post(datos,this._constantes.URLEliminarNotificacion
        +'?Id='+datos)
    }
    ActualizarORDOSTemporales(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarTemporales
        + '?Id='+datos    
        + '&Estado=9');
    }
    ActualizarPorORDOTemporales(datos){
      if (datos.Estado == undefined){
        return this._apiServicio.Post(datos, this._constantes.URLActualizarTempPorORDO
          + '?IdOrdo='+datos.IdOrdo
          + '&Motivo='+datos.Motivo
          + '&Flujo='+datos.Flujo
          )
      } else{
      return this._apiServicio.Post(datos, this._constantes.URLActualizarTempPorORDO
        + '?IdOrdo='+datos.IdOrdo
        + '&Motivo='+datos.Motivo
        + '&Estado='+datos.Estado
        + '&Flujo='+datos.Flujo
        )
      }
    }
    ObtenerNotificacionesPorORDO(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerNotPorORDO
        + '?IdOrdo='+datos)
    }
    ObtenerNotificacionesFiltrado(datos){
      return this._apiServicio.Get(this._constantes.URLFiltrados
        + '?IdOrdo='+datos)
    }

    ObtenerDireccion()
    {
        return this._apiServicio.Get(this._constantes.URLDireccion);
    }

    ObtenerBorrador(datos)
    {
      return this._apiServicio.Get(this._constantes.URLBorrador + '?ElaboroN='+datos )
    }

    GuardarBorrador(datos)
    {
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&ElaboroN='+datos.ElaboroN
        + '&ElaboroP='+datos.ElaboroP
        + '&FLujo='+datos.FLujo
        + '&FDirectos='+datos.FDirectos
        + '&FIndirectos='+datos.FIndirectos
        + '&FInternas='+datos.FInternas
        + '&FExternas='+datos.FExternas
        + '&FInternasDe='+datos.FInternasDe
        + '&FInternasPro='+datos.FInternasPro
        + '&FInternasInfo='+datos.FInternasInfo
        + '&FExternasDe='+datos.FExternasDe
        + '&FExternasPro='+datos.FExternasPro
        + '&FExternasInfo='+datos.FExternasInfo
        + '&Escolaridad='+datos.Escolaridad
        + '&Idiomas='+datos.Idiomas
        + '&Conocimientos='+datos.Conocimientos
        + '&Habilidades='+datos.Habilidades
        + '&Laptop='+datos.Laptop
        + '&Pc='+datos.Pc
        + '&Tablet='+datos.Tablet
        + '&Celular='+datos.Celular
        + '&Extension='+datos.Extension
        + '&LDistancia='+datos.LDistancia
        + '&Expe1='+datos.Expe1
        + '&ExpeTiempo1='+datos.ExpeTiempo1
        + '&Expe2='+datos.Expe2
        + '&ExpeTiempo2='+datos.ExpeTiempo2
        + '&Expe3='+datos.Expe3
        + '&ExpeTiempo3='+datos.ExpeTiempo3
        + '&Expe4='+datos.Expe4
        + '&ExpeTiempo4='+datos.ExpeTiempo4
        + '&CrearNode='+datos.CrearNode
        )
    }
    EnviarACatalogo(datos){
      return this._apiServicio.Post(datos,this._constantes.URLActualizarORDO
        + '?Id='+datos.Id
        + '&Estado='+datos.Estado
        + '&FLujo='+datos.FLujo
        )
    }
    obtenerFormularioID(){
      return this._apiServicio.Get(this._constantes.URLObtenerFormularioID);
    }
    obtenerGrupoFormulario(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerGrupoFormulario 
        + '?GrupoID='+datos.GrupoID
        + '&FormularioID='+datos.FormularioID)
    }
    obtenerUsuarioFormulario(datos){   
      return this._apiServicio.Get(this._constantes.URLObtenerUsuarioFormulario
        + '?PosicionID='+datos.PosicionID
        + '&FormularioID='+datos.FormularioID)
    }
    GuardarValidadores(datos)
    {
      return this._apiServicio.Post(datos,this._constantes.URLACtualizarDirecto
        + '?Id='+datos.Id
        + '&ElaboroN='+datos.ElaboroN
        + '&ElaboroP='+datos.ElaboroP
        + '&RevisoN='+datos.RevisoN
        + '&RevisoN='+datos.RevisoN
        + '&ValidoN='+datos.ValidoN
        + '&ValidoP='+datos.ValidoP
        + '&Estado='+datos.Estado
        )
    }
    ObtenerEspeciales(){
      return this._apiServicio.Get(this._constantes.URLObtenerEspeciales)
    }

    ObtenerReporteOrdo(datos) {
      return this._apiServicio.Post(datos, this._constantes.URLObtenerReportesORDO);
    }

    GeneraPdfOrdo(datos){
      return this._apiServicio.Post(datos, this._constantes.URLGeneraPdfOrdo);
    }

    ObtenerTotalIndirectosOrgani(datos){
      return this._apiServicio.Get(this._constantes.URLTotalIndirectosOrgani
        +'?Puestos='+datos.Nombre
        +'&NumeroEmpleado='+datos.NumeroEmpleado)
    }

    ObtenerPuestosOrgani(datos){
      return this._apiServicio.Get(this._constantes.URLPuestosOrgani
        +'?Puestos='+datos.Nombre
        +'&NumeroEmpleado='+datos.NumeroEmpleado)
    }

    ObtenerPuestosOrganiEditable(datos){
      return this._apiServicio.Get(this._constantes.URLPuestosOrganiEditable+'?PuestosEditable='+datos);
    }

    ObtenerPuestoscheckAll(){
      return this._apiServicio.Get(this._constantes.URLPuestoscheckAll);
    }

    actualizarTablaPuestosCheck(datos){
      return this._apiServicio.Post(datos, this._constantes.URLActualizaPuestosCheck);
    }

    guardarNuevaFuncionCheck(datos){
      return this._apiServicio.Post(datos, this._constantes.URLAgregarFuncionCheck);
    }

    EliminarOrdo(datos){
      return this._apiServicio.Post(datos, this._constantes.URLEliminarOrdo);
    }

    ObtenerOrdosEliminados_log(){
      return this._apiServicio.Get(this._constantes.URLObtenerOrdosEliminados_log);
    }

    ObtenerOrdosEliminados(datos){
      return this._apiServicio.Post(datos, this._constantes.URLObtenerOrdosEliminados);
    }

    ObtenerCorreoArqOrganizacionales(){
      return this._apiServicio.Get(this._constantes.URLObtenerCorreoArqOrganizacionales);
    }
  }