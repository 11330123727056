import { Injectable } from '@angular/core';

import { 
    ApiService
} from "./api.service";


import {
	BitacoraConstante
} from "./../config/constantes";

@Injectable()
export class BitacoraService{
    constructor(
		private _constantes: BitacoraConstante,
		private _apiServicio: ApiService) {
    }

    ConsultarTodosBitacora() {
        return this._apiServicio.Get(this._constantes.URLBitacoraObtenerTodos);
    }
    ConsultarTodosPorFuncion(){
        return this._apiServicio.Get(this._constantes.URLBitacoraPorFuncion);
    }
    ConsultarTodosPorDireccion(){
        return this._apiServicio.Get(this._constantes.URLBitacoraPorDireccion);
    }
    ConsultarTodosPorRangFecha(){
        return this._apiServicio.Get(this._constantes.URLBitacoraPorRangoFecha);
    }

    ReporteGeneraPorDireccion_Bit(objRequest) {
        return this._apiServicio.Get(this._constantes.URLBitacoraPorDireccion, objRequest);
    }

    ReporteGeneralBitacora(objRequest){
        return this._apiServicio.Get(this._constantes.URLBitacoraReporteGeneral,objRequest);
    }


}
