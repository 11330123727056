import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	MotivosInasistenciaConstante
} from "./../config/constantes";

@Injectable()
export class MotivosInasistenciaService {
	constructor(
		private _motivosConstante: MotivosInasistenciaConstante,
		private _apiServicio: ApiService) {
    }
	
	ObtenerTodos() {
		return this._apiServicio.Get(this._motivosConstante.URLMotivosInasistenciaObtenerTodosActivos);
	}


	
}