import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: '[numbers]'
})
export class Numbers {

    constructor(public el: ElementRef) {
        this.el.nativeElement.onkeypress = (evt) => {
            if (evt.which < 96 || evt.which > 123) {
                if (evt.which < 64 || evt.which > 91) {
                    evt.preventDefault();
                }
            }
        };
    }
}