import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HKConstantes } from '../config/constantes';

@Injectable({
  providedIn: 'root'
})
export class HkService {

  constructor(private _apiServicio: ApiService,
    private _hkConstantes: HKConstantes) { }


  ObtenerPuestosTokenCI(datos) {
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerPuestosToken);
  }

  ObtenerHKDefaultTokenCI(datos) {
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerHKDefaultToken);
  }

  obtenerSemanasTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerSemanasToken);
  }

  obtenerReporteTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerReporteToken);
  }

  obtenerReporteCuatriTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerReporteCuatriToken);
  }

  obtenerCumplimientoTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerCumplimientoToken);
  }

  obtenerReporteConceptosTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerConceptosToken);
  }

  GuardaCapturaTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIguardaCapturaToken);
  }

  guardaConceptoPadreDistinctTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIguardaConceptoPadreDistinctToken);
  }

  GuardaComentariosTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIguardaComentariosToken);
  }

  GuardaCapturaPptoTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIguardaCapturaPptoToken);
  }

  guardarUltimoHkTokenCI(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIguardarUltimoHkToken);
  }

  verificaPermisoHk(posicion, grupoid) {
      console.log(grupoid);
      return this._apiServicio.Get(this._hkConstantes.URLObtenerPermisoHk + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
  }

  obtenerReporteEvaluacionD(datos){
    return this._apiServicio.PostCI(datos, this._hkConstantes.URLReporteEvaluacionDToken);
  }

  GuardaCapturaEvaluacionTokenCI(datos){
    return this._apiServicio.PostCI(datos, this._hkConstantes.URLGuardaCapturaEvaluacionDToken);
  }

  guardarLogDescargaNiveldeUso(datos){
    return this._apiServicio.PostCI(datos, this._hkConstantes.URLguardarLogDescargaNiveldeUso);
  }

  ObtenerPermisoHerramientasAdmin(empleadoId, grupoid, Herramienta){
    return this._apiServicio.Get(this._hkConstantes.URLObtenerPermisoHerramientasAdmin + '?EmpleadoId=' + empleadoId + '&GrupoID=' + grupoid + '&Herramienta=' + Herramienta);
  }

  // SERVICIOS VERSION 2 HOSHIN KANRI

  ObtenerPuestosTokenCI_V2(datos) {
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerPuestosTokenV2);
  }
  
  ObtenerPuestoFechaToken(datos) {
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerPuestoFechaToken);
  }

  ObtenerHKDefaultTokenCI_V2(datos) {
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerHKDefaultTokenV2);
  }

  obtenerSemanasTokenCI_V2(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerSemanasTokenV2);
  }

  obtenerReporteTokenCI_V2(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerReporteTokenV2);
  }

  obtenerReporteCuatriTokenCI_V2(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerReporteCuatriTokenV2);
  }

  obtenerCumplimientoTokenCI_V2(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerCumplimientoTokenV2);
  }

  obtenerReporteConceptosTokenCI_V2(datos){
    return this._apiServicio.PostCI(datos,this._hkConstantes.URLCIObtenerConceptosTokenV2);
  }

  obtenerReporteEvaluacionD_V2(datos){
    return this._apiServicio.PostCI(datos, this._hkConstantes.URLReporteEvaluacionDTokenV2);
  }
}
