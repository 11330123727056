import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appIntersectionObserver]',
})
export class IntersectionObserverDirective {
  @Output() onIntersect = new EventEmitter<boolean>();
  public observer!: IntersectionObserver;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.createObserver();
  }

  private callback: ConstructorParameters<typeof IntersectionObserver>[0] = (
    entries
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.onIntersect.emit(true);
      } else {
        this.onIntersect.emit(false);
      }
    });
  };

  private createObserver() {
    const options = {
      threshold: 0,
      root: null,
    };

    this.observer = new IntersectionObserver(this.callback, options);
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
