import { Injectable } from '@angular/core';
import { 
	ApiService 
} from "./api.service";

import {
    RpdConstantes
}from "../config/constantes";

@Injectable()
export class RpdService {

  constructor(   
    private _constantes: RpdConstantes,
    private _apiServicio: ApiService) { }

    ObtenerFrecuenciaReporteRpd(datos){
      return this._apiServicio.Get(this._constantes.URLObtenerFrecuenciaReporteRpd +
        '?frecuencia=' + datos.frecuencia +
        '&nPosicion=' + datos.nPosicion);
    }

    ObtenerDocumentRpd(nombreDocumento, rutaDocumento){
      return this._apiServicio.Get(this._constantes.URLObtenerDocumentRpd +
        '?nomDocumento=' + nombreDocumento +
        '&rutaDocumento=' + rutaDocumento);
    }

    verificaPermisoRPD(posicion, grupoid) {
      console.log(grupoid);
      return this._apiServicio.Get(this._constantes.URLObtenerPermisoRpd + '?PosicionID=' + posicion + '&GrupoID=' + grupoid);
  }

  ObtenerFechaRpd(idsk_veo){
    return this._apiServicio.Get(this._constantes.URLObtenerFechaReporte +
      '?idsk_veo=' + idsk_veo);
  }

  ObtenerPermisoHerramientasAdmin(empleadoId, grupoid, Herramienta){
    return this._apiServicio.Get(this._constantes.URLObtenerPermisoHerramientasAdmin + '?EmpleadoId=' + empleadoId + '&GrupoID=' + grupoid + '&Herramienta=' + Herramienta);
  }
}