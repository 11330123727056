import { Injectable } from '@angular/core';
import {
    ApiService
} from "./api.service";

import {
    RolesCtrlDocConstante
} from "./../config/constantes";


@Injectable({
    providedIn: 'root'
})
export class RolesControlDocumentaService {

    constructor(private _rolesCtrlDocConstante: RolesCtrlDocConstante, private _apiServicio: ApiService) {
    }

    ObtenerColaboradoresPorRolId(idRol) {
        return this._apiServicio.Get(this._rolesCtrlDocConstante.URLObtenerColaboradoresPorRol +
            '?IdRol=' + idRol);
    }

    ObtenerColaboradorPorIdUsuario(idUsuario) {
        return this._apiServicio.Get(this._rolesCtrlDocConstante.URLObtenerColaboradorPorUsuarioId +
            '?IdUsuario=' + idUsuario);
    }

    EliminarAsignacion(idAsignacion) {         
        return this._apiServicio.Post(null,this._rolesCtrlDocConstante.URLEliminarAsignacion +
            '?IdAsignacion=' + idAsignacion);
    }

    ConsultarAsignacionesColaborador(idColaborador) { 
        
        return this._apiServicio.Get(this._rolesCtrlDocConstante.URLConsultarAsignacionesColaborador +
            '?IdColaborador=' + idColaborador);
    }
    ObtenerColaboradorPorPosicion(posicion) {
        return this._apiServicio.Get(this._rolesCtrlDocConstante.URLObtenerColaboradorPorPosicion +
            '?Posicion=' + posicion);
    }

}