import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
//import { Subscription } from 'rxjs';
import swal from 'sweetalert';

const screenfull = require('screenfull');
const browser = require('jquery.browser');

declare var $: any;
declare var require: any;


import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster/angular2-toaster';
//import { SocketWebServicesService } from '../../services/socketWebServices.service';
//////////////// Notificaciones Dashboard ////////////////////////////
import {MatIconModule} from '@angular/material/icon';
import { MatDialog,  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalNotificacionesComponent } from '../modal-notificaciones/modal-notificaciones.component';
import {
    NotificacionesDashboardService,
    EmpleadoService,
}from '../../services';
import Swal from 'sweetalert2';

////////////////////////////////////////////////////////////////

import { TableroService } from "../../services";
import { observable } from 'rxjs';
import { parseDate } from 'ngx-bootstrap';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', {static: false}) fsbutton;  // the fullscreen button
    //Variable para guardar la session
    public session: any;

    public puesto: string;
    public numEmpleado: number;
    public idGrupo: number;
    //subscription: Subscription;
    
//////////////// Notificaciones Dashboard ////////////////////////////
Posicion:any;
DataOrdo:any=[];
DataDecisiones:any=[];
DataDecisionesLeer:any=[];
DataSesiones:any=[];
DataLA:any=[];
DataJuntas: any=[];
DataJuntasCompromisos: any=[];
DataModoSK: any=[];
DataModoSK_Solicitante: any=[];

ContadorOrdo:number;
ContadorDecisiones:number;
ContadorDecisionesLeer:number;
ContadorSesiones:number;
ContadorLA:number;
ContadorJuntas:number;
ContadorJuntasCompro:number;
ContadorModoSK:number;
ContadorModoSK_Solicitante:number;
ContadorGeneral:number = null;

InicioSesion: number = 2;
accesoDN: number = 0;
Direccion: any;
UnidadDeNegocio: any;
Puesto: any;
DivisionPersonal: any;
DataCC_rh: any=[];
DataCC_usuario: any=[];
DataCC_jefe: any=[];
ContadorCC_usuario: number;
ContadorCC_jefe: number;
ContadorCC_rh: number;
RollingD: boolean = true;
btnDashboard:boolean = true;
Data_PdM: any=[];
Data_PdM_Solicitante: any=[];
Data_PdM_Centralizador: any=[];
Data_PdM_DuenoProceso: any=[];
Data_PdM_Admin: any=[];
ContadorPdM: number;

//////////////// ///////////////////////// ////////////////////////////


    constructor(
        private _ND: NotificacionesDashboardService,
        private __usuarios:EmpleadoService,

        public dialog: MatDialog,
        private __router: Router,
        private __cockieService: CookieService, 
        public menu: MenuService, 
        public userblockService: UserblockService,
        public settings: SettingsService, 
        private __cookieService: CookieService, //private _socket: SocketWebServicesService,
        private __tableroService: TableroService, 
        private _cdr: ChangeDetectorRef,
        public toasterService: ToasterService) {
        this.session = this.getCookie('session');
        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.InicioSesion = 2

    }
    sweetalertDemo4() {
        swal({
            title: '¿Seguro que deseas salir de la aplicación?',
            icon: "warning",
            text: 'Para volver a acceder necesitaras de tu usuario y contraseña',
            buttons: ["Cancelar", "Aceptar"],
            dangerMode: true,
        }).then((exit) => {
            if (exit) {
                this.__cockieService.set('menuAlterno', JSON.stringify(null), 0, '/',null, false, 'Lax');
                this.__cockieService.set('session', JSON.stringify(null), 0, '/',null, false, 'Lax');
                this.__router.navigate(['/login']);
                this.toasterService.clear();
            }
        });
    }
    ngOnDestroy(){	
    }
    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }
    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }
    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }
    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }
    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }
    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }
    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }
    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        }
        else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }
    //Metodo para obtener la cookie
    getCookie(key: string): Object {
        let cookie: Object = (this.__cookieService.get(key)) ? JSON.parse(this.__cookieService.get(key)) : null;
        return cookie;
    } 

    ngOnInit() {  
        this.isNavSearchVisible = false;
        if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        } 
        this.puesto = this.session.user.Grupo.Descripcion;
        this.numEmpleado = this.session.user.NumeroEmpleado
        this.idGrupo = this.session.user.GrupoID;
        this.InicioMG();
        this.buscarEmpleado();
        
        // this._ND.getObservable().subscribe((respuesta: any) => {
        //   this.ContadorGeneral = null;

        //   if(respuesta == true){
                
        //         this.observador(1);

        //     }
        //     else if(respuesta == false){
        //         this.observador(0);
        //     }
        // }, err => {
        //     console.log(err);
        //     Swal.fire({
        //       icon: 'error',
        //       html:
        //         '<h4>Ocurrió un error. </h4>, ',
        //       showConfirmButton: false,
        //       timer: 2000
        //     })
        //   });
      
    }
/////////////////////////// Notificaciones Dashboard /////////////////////////////////////////////
    InicioMG(){
        this.InicioSesion = this._ND.getInicio()
    } 

    openDashboard(){   
        this.dialog.open(ModalNotificacionesComponent,{
           width:'97vw', 
           maxWidth:'1300px',
            maxHeight:'auto',
            data:{
                dataOrdo:this.DataOrdo,
                dataDecision:this.DataDecisiones,
                dataDecisionLeer:this.DataDecisionesLeer,
                dataSesiones:this.DataSesiones,
                dataLA:this.DataLA,
                dataJuntas:this.DataJuntas,
                DataJuntasCompromisos:this.DataJuntasCompromisos,
                dataModoSk:this.DataModoSK,
                dataModoSK_Solicitante:this.DataModoSK_Solicitante,
                dataCC_usuario:this.DataCC_usuario,
                dataCC_jefe:this.DataCC_jefe,
                dataCC_rh:this.DataCC_rh,
                data_PdM:this.ContadorPdM,
                data_PdM_Solicitante:this.Data_PdM_Solicitante,
                data_PdM_Centralizador:this.Data_PdM_Centralizador,
                data_PdM_DuenoProceso:this.Data_PdM_DuenoProceso,
                data_PdM_Admin:this.Data_PdM_Admin,
                posicion:this.Posicion,
                grupo:this.idGrupo,
                rol:this.session.user.Grupo.Descripcion
            },
            panelClass: 'dialog_ND' });
    } 

    buscarEmpleado() {
      this.__usuarios.ObtenerEmpleadoNumEmpleado(this.session.user.NumeroEmpleado).subscribe((respuesta: any) => {
          console.log(respuesta);
          this.Posicion = respuesta.resultado.nPosicion;
          this.Direccion = respuesta.resultado.cDireccion;
          this.UnidadDeNegocio = respuesta.resultado.cUnidadDeNegocio;
          this.Puesto = respuesta.resultado.cPuesto;
          this.DivisionPersonal = respuesta.resultado.cDivisionPersonal;
          this._ND.AccesoTemporal(this.Posicion).subscribe((Acceso: any) => {
             if (Acceso.NDAcceso.length !=0) {
                 this.accesoDN = 1;
                  this.obtenerDatosDashboard().then(() => {
                      if (this.InicioSesion == 1) {
                          console.log("Datos del dashboard obtenidos");
                          this.RollingD = false;
                          this.btnDashboard = false;
                          this.openDashboard();
                          this.InicioSesion = 2;
                      } else {
                          this.RollingD = false;
                          this.btnDashboard = false;
                          this._cdr.detectChanges();
                          console.log(this.btnDashboard)
                      }
                  }).catch(error => {
                      console.error("Error al obtener datos del dashboard:", error);
                      Swal.fire({
                          icon: 'error',
                          html: '<h4>Ocurrió un error al obtener los datos del dashboard.</h4>',
                          showConfirmButton: false,
                          timer: 2000
                      });
                  });
             }
          }, err => {
              console.log(err);
              Swal.fire({
                  icon: 'error',
                  html: '<h4>Ocurrió un error al obtener el acceso temporal.</h4>',
                  showConfirmButton: false,
                  timer: 2000
              });
         });
      }, err => {
          console.log(err);
          Swal.fire({
              icon: 'error',
              html: '<h4>Ocurrió un error al obtener el empleado.</h4>',
              showConfirmButton: false,
              timer: 2000
          });
      });
  }

      obtenerOrdosN(Posicion){
       /*  this._ND.obtenerPorOrdo(Posicion).subscribe((res:any)=>{
            this.DataOrdo=res.NotificacionesDashboard;
            this.ContadorOrdo= this.DataOrdo.length;
            this.ContadorGeneral=this.ContadorOrdo;
        }); */

        this._ND.obtenerPorOrdo(Posicion).toPromise().then(async (res:any)=>{
            this.DataOrdo = this.AjusteFecha(res.NotificacionesDashboard);
            
            this.ContadorOrdo= this.DataOrdo.length;
            this.ContadorGeneral=this.ContadorOrdo;
            return true;
          }, err => {
            console.log(err);
            swal({
              title: "ERROR",
              text: "Ocurrio un error al obtener la posicion del usuario.",
              icon: "error",
              buttons: { cancel: false, confirm: false },
              dangerMode: false,
              timer: 3000,
            });
          });
    }

      obtenerOrdosNArquitecto(){
        /* this._ND.obtenerPorOrdoArquitecto().subscribe((res:any)=>{
            this.DataOrdo=res.NotificacionesDashboard;
            this.ContadorOrdo= this.DataOrdo.length;
            this.ContadorGeneral=this.ContadorOrdo;

        }); */
        this._ND.obtenerPorOrdoArquitecto().toPromise().then( async(res:any)=>{
            this.DataOrdo = this.AjusteFecha(res.NotificacionesDashboard)
            this.ContadorOrdo= this.DataOrdo.length;
            this.ContadorGeneral=this.ContadorOrdo;
            return true;
          }, err => {
            console.log(err);
            swal({
              title: "ERROR",
              text: "Ocurrio un error al obtener la posicion del usuario.",
              icon: "error",
              buttons: { cancel: false, confirm: false },
              dangerMode: false,
              timer: 3000,
            });
          });
        
    }

    async obtenerDecisiones() {
      try {
          let descripcion = this.session.user.Grupo.Descripcion;
          if (descripcion == "Administrador") {
              const res: any = await this._ND.decisionesAdministrador(this.Posicion, this.idGrupo).toPromise();
              this.DataDecisiones = this.AjusteFecha(res.NotificacionesDashboard);
              this.ContadorDecisiones = this.DataDecisiones.length;
              this.ContadorGeneral += this.ContadorDecisiones;
          } else {
              const res: any = await this._ND.notificacionesDecisiones(this.Posicion).toPromise();
              this.DataDecisiones = this.AjusteFecha(res.NotificacionesDashboard);
              this.ContadorDecisiones = this.DataDecisiones.length;
              this.ContadorGeneral += this.ContadorDecisiones;
          }
          return true;
      } catch (error) {
          console.error(error);
          swal({
              title: "ERROR",
              text: "Ocurrió un error al obtener las decisiones.",
              icon: "error",
              buttons: { cancel: false, confirm: false },
              dangerMode: false,
              timer: 3000,
          });
          return false;
      }
  }

  async obtenerDecisionesLeer() {
    try {
        const res: any = await this._ND.decisionesLeer(this.session.user.NumeroEmpleado, this.Posicion).toPromise();
        this.DataDecisionesLeer = this.AjusteFecha(res.NotificacionesDashboard);
        this.ContadorDecisionesLeer = this.DataDecisionesLeer.length;
        this.ContadorGeneral += this.ContadorDecisionesLeer;
        return true;
    } catch (error) {
        console.error(error);
        swal({
            title: "ERROR",
            text: "Ocurrió un error al obtener las decisiones para leer.",
            icon: "error",
            buttons: { cancel: false, confirm: false },
            dangerMode: false,
            timer: 3000,
        });
        return false;
    }
}

async obtenerSesiones() {
  try {
      const res: any = await this._ND.obtenerPorSesiones(this.Posicion, this.session.user.NumeroEmpleado).toPromise();
      this.DataSesiones = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorSesiones = this.DataSesiones.length;
      this.ContadorGeneral += this.ContadorSesiones;
      return true;
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las sesiones.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

async obtenerLA() {
  try {
      let descripcion = this.session.user.Grupo.Descripcion;
      if (descripcion === "Administrador") {
          const res: any = await this._ND.notificacionesLAAdmin(this.Posicion, this.idGrupo).toPromise();
        //   console.log(res);
          this.DataLA = this.AjusteFecha(res.NotificacionesDashboard);
          this.ContadorLA = this.DataLA.length;
          this.ContadorGeneral += this.ContadorLA;
          return true;
      } else {
          const res: any = await this._ND.notificacionesLA(this.Posicion).toPromise();
          this.DataLA = this.AjusteFecha(res.NotificacionesDashboard);
          this.ContadorLA = this.DataLA.length;
          this.ContadorGeneral += this.ContadorLA;
          return true;
      }
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las notificaciones de LA.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

    async obtenerJuntas() {

      try {
          let descripcion = this.session.user.Grupo.Descripcion;
          const res: any = await this._ND.notificacionesJuntasAdmin(this.session.user.NumeroEmpleado, descripcion).toPromise();
        //   console.log(res);
          this.DataJuntas = this.AjusteFechaJuntas(res.NotificacionesDashboard);
          this.ContadorJuntas = this.DataJuntas.length;
          this.ContadorGeneral += this.ContadorJuntas;
          return true;
      } catch (error) {
          console.error(error);
          swal({
              title: "ERROR",
              text: "Ocurrió un error al obtener las notificaciones de juntas.",
              icon: "error",
              buttons: { cancel: false, confirm: false },
              dangerMode: false,
              timer: 3000,
          });
          return false;
      }
  }

  async obtenerJuntasCompromisos() {
    try {
        let descripcion = this.session.user.Grupo.Descripcion;
        const res: any = await this._ND.notificacionesJuntasCompromisosAdmin(this.session.user.NumeroEmpleado, descripcion).toPromise();
        // console.log(res);
        this.DataJuntasCompromisos = this.AjusteFecha(res.NotificacionesDashboard);
        this.ContadorJuntasCompro = this.DataJuntasCompromisos.length;
        this.ContadorGeneral += this.ContadorJuntasCompro;
        return true;
    } catch (error) {
        console.error(error);
        swal({
            title: "ERROR",
            text: "Ocurrió un error al obtener las notificaciones de juntas compromisos.",
            icon: "error",
            buttons: { cancel: false, confirm: false },
            dangerMode: false,
            timer: 3000,
        });
        return false;
    }
}

async obtenerModoSK() {
  try {
      const res: any = await this._ND.notificacionesModoSK(this.session.user.NumeroEmpleado, this.Posicion).toPromise();
    //   console.log(res);
      this.DataModoSK = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorModoSK = this.DataModoSK.length;
      this.ContadorGeneral += this.ContadorModoSK;
      return true;
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las notificaciones del modo SK.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

async obtenerModoSK_Solicitante() {
  try {
      const res: any = await this._ND.notificacionesModoSK_Solicitante(this.session.user.NumeroEmpleado).toPromise();
    //   console.log(res);
      this.DataModoSK_Solicitante = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorModoSK_Solicitante = this.DataModoSK_Solicitante.length;
      this.ContadorGeneral += this.ContadorModoSK_Solicitante;
      return true;
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las notificaciones del modo SK solicitante.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

async notificacionesCC_usuario() {
  try {
      const res:any = await this._ND.notificacionesCC_usuario(this.session.user.NumeroEmpleado).toPromise();
      //console.log(res);
      this.DataCC_usuario = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorCC_usuario = this.DataCC_usuario.length;
      this.ContadorGeneral += this.ContadorCC_usuario;
      return true;
  } catch (err) {
      console.log(err);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener la posición del usuario cultura cumplimiento.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

async notificacionesCC_jefe() {
  try {
      const res: any = await this._ND.notificacionesCC_jefe(this.session.user.NumeroEmpleado).toPromise();
      this.DataCC_jefe = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorCC_jefe = this.DataCC_jefe.length;
      this.ContadorGeneral += this.ContadorCC_jefe;
      return true;
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las notificaciones del jefe.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

 async notificacionesCC_rh() {
  try {
      const res: any = await this._ND.notificacionesCC_rh(this.Direccion, this.UnidadDeNegocio, this.Puesto, this.DivisionPersonal, this.session.user.NumeroEmpleado).toPromise();
    //   console.log(res);
      this.DataCC_rh = this.AjusteFecha(res.NotificacionesDashboard);
      this.ContadorCC_rh = this.DataCC_rh.length;
      this.ContadorGeneral += this.ContadorCC_rh;
      return true;
  } catch (error) {
      console.error(error);
      swal({
          title: "ERROR",
          text: "Ocurrió un error al obtener las notificaciones del departamento de Recursos Humanos.",
          icon: "error",
          buttons: { cancel: false, confirm: false },
          dangerMode: false,
          timer: 3000,
      });
      return false;
  }
}

async notificaciones_PropuestadeMejora() {
    
    try {
        const res: any = await this._ND.notificaciones_PropuestadeMejora(this.session.user.NumeroEmpleado, this.Posicion).toPromise();
         console.log(res);
        this.Data_PdM = this.AjusteFecha(res.NotificacionesDashboard);
        this.Data_PdM_Solicitante = this.AjusteFecha(res.NotificacionesDashboard).filter(element => element.Rol == 'SOLICITANTE');
        this.Data_PdM_Centralizador = this.AjusteFecha(res.NotificacionesDashboard).filter(element => element.Rol == 'CENTRALIZADOR' && element.textEstatus != 'NULL');
        this.Data_PdM_DuenoProceso = this.AjusteFecha(res.NotificacionesDashboard).filter(element => element.Rol == 'DUENODEPROCESO' && element.textEstatus != 'En Tiempo');
        this.Data_PdM_Admin = this.AjusteFecha(res.NotificacionesDashboard).filter(element => element.Rol == 'ADMINISTRADOR');
        console.log(this.session.user.Grupo.Descripcion)

        if(this.session.user.Grupo.Descripcion == 'Administrador'){
            this.ContadorPdM = this.Data_PdM.length;
            console.log(this.ContadorPdM)
        } else {
            this.ContadorPdM = this.Data_PdM_Solicitante.length + this.Data_PdM_Centralizador.length + this.Data_PdM_DuenoProceso.length;
            console.log(this.ContadorPdM)
        }
        this.ContadorGeneral += this.ContadorPdM;
        return true;
    } catch (error) {
        console.error(error);
        swal({
            title: "ERROR",
            text: "Ocurrió un error al obtener las notificaciones Propuesta de Mejora.",
            icon: "error",
            buttons: { cancel: false, confirm: false },
            dangerMode: false,
            timer: 3000,
        });
        return false;
    }
  }

    contadoresNotificaciones(){
        this.ContadorGeneral = this.DataOrdo.length;
        this.ContadorGeneral = this.ContadorGeneral + this.ContadorDecisiones + this.ContadorDecisionesLeer;
    }

    // observador(op: number){
    //     this.obtenerDatosDashboard();        
    //     if(op == 1)setTimeout(()=>{
    //         this.openDashboard()
    //     },1000);
    // }

    async obtenerDatosDashboard() {
        let a
        //------- ORDOS ----------//
        if (this.session.user.Grupo.Descripcion == 'Arquitecto Organizacional') {
          a =   await  this.obtenerOrdosNArquitecto();
        } else {
            a = await this.obtenerOrdosN(this.Posicion);
        }
        //------- Decisiones ----------//
        a = await this.obtenerDecisiones();

         //------- Decisiones Leer----------//
         a = await this.obtenerDecisionesLeer();
        
        // ------ Sesiones 1:1 -------//
        a = await this.obtenerSesiones();
        
        // ---------- LA --------------//
        a = await  this.obtenerLA();

        // ---------- JUNTAS --------------//
        a = await this.obtenerJuntas();

        // ---------- JUNTAS COMPROMISOS --------------//
        a = await this.obtenerJuntasCompromisos();

        // ---------- MODO SK --------------//
        a = await this.obtenerModoSK();     
        
        // ---------- MODO SK SOLICITANTE--------------//
        a = await this.obtenerModoSK_Solicitante(); 
        
        // ---------- CULTURA CUMPLIMIENTO USUARIO --------------//
        // a = await this.notificacionesCC_usuario(); 

        // ---------- CULTURA CUMPLIMIENTO JEFE --------------//
       // a = await this.notificacionesCC_jefe(); 

        // ---------- CULTURA CUMPLIMIENTO RH --------------//
       // a = await this.notificacionesCC_rh(); 

       // ---------- PROPUESTA DE MEJORA --------------//
       a = await this.notificaciones_PropuestadeMejora();

    }

    AjusteFecha(data){
      data.forEach(e => {
        
        let FechaCorreccion = new Date(e.Fecha);
        FechaCorreccion.setDate(FechaCorreccion.getDate()+2)
        let mes = (FechaCorreccion.getMonth() + 1) < 10 ? '0' + (FechaCorreccion.getMonth() + 1):(FechaCorreccion.getMonth() + 1);
        
       
        let dia = (FechaCorreccion.getDate()) < 10 ? '0' + (FechaCorreccion.getDate()):(FechaCorreccion.getDate());
        let fechaConFormato = `${FechaCorreccion.getFullYear()}-${mes}-${dia}`;
        
        e.Fecha = fechaConFormato
        
      });
      return data;
    }

    
    AjusteFechaJuntas(data){
      data.forEach(e => {
        
        let FechaCorreccion = new Date(e.Fecha);
        FechaCorreccion.setDate(FechaCorreccion.getDate())
        let mes = (FechaCorreccion.getMonth() + 1) < 10 ? '0' + (FechaCorreccion.getMonth() + 1):(FechaCorreccion.getMonth() + 1);
        
       
        let dia = (FechaCorreccion.getDate()) < 10 ? '0' + (FechaCorreccion.getDate()):(FechaCorreccion.getDate());
        let fechaConFormato = `${FechaCorreccion.getFullYear()}-${mes}-${dia}`;
        
        e.Fecha = fechaConFormato
        
      });
      return data;
    }

        
    AjusteFechaJuntasC(data){
      data.forEach(e => {
        
        let FechaCorreccion = new Date(e.Fecha);
        FechaCorreccion.setDate(FechaCorreccion.getDate()+1)
        let mes = (FechaCorreccion.getMonth() + 1) < 10 ? '0' + (FechaCorreccion.getMonth() + 1):(FechaCorreccion.getMonth() + 1);
        
       
        let dia = (FechaCorreccion.getDate()) < 10 ? '0' + (FechaCorreccion.getDate()):(FechaCorreccion.getDate());
        let fechaConFormato = `${FechaCorreccion.getFullYear()}-${mes}-${dia}`;
        
        e.Fecha = fechaConFormato
        
      });
      return data;
    }
//////////////////////////////////////////////////////////////////////////////
}