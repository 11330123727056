import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { ChecklistConstante } from '../config/constantes';

@Injectable()

export class ChecklistService {
	constructor(
		private _checklist: ChecklistConstante,
		private _apiServicio: ApiService) {
    }

    obtenerBloques(){
        return this._apiServicio.Get(this._checklist.URLListadoBloques);
      }
      obtenercatalogoscheck(){
        return this._apiServicio.Get(this._checklist.URLCatalogoCh)
      }
      guardarBloque(bloque) {
        return this._apiServicio.Post( bloque, this._checklist.URLGuardarBloque );    
      }
      actualizarclasificacion(bloque) {
        return this._apiServicio.Put( bloque, this._checklist.URLactualizarclasificacion );    
      }

      obtenerpuntos(){
        return this._apiServicio.Get(this._checklist.URLpuntoevaluar)
      }
      ObtenerTodoPuntosEvaluar(){
        return this._apiServicio.Get(this._checklist.URLlistAllpointevaluate);
      }

      guardarpuntoevaluar(bloque){
        return this._apiServicio.Post( bloque, this._checklist.URLguardarpuntoevaluar);    
      }
      actualizarpunto(bloque) {
        return this._apiServicio.Put( bloque, this._checklist.URLactpuntoevaluar );    
      }

      obtenerListacatalogo(){
        return this._apiServicio.Get(this._checklist.URLobtenerListacatalogo)
      }

      crearcatalogo(bloque){
        return this._apiServicio.Post( bloque, this._checklist.URLcrearcatalogo);    
      }
      actualizarcatalog(bloque) {
        return this._apiServicio.Put( bloque, this._checklist.URLactualizarcatalog );    
      }

      obtenerListadescripcion(){
        return this._apiServicio.Get(this._checklist.URLobtenerListadescripcion)
      }

      creardescripcion(bloque){
        return this._apiServicio.Post( bloque, this._checklist.URLcreardescripcion);    
      }
      actualizardescripcion(bloque) {
        return this._apiServicio.Put( bloque, this._checklist.URLactualizardescripcion );    
      }
      listapuntonevaluar(bloqueid) {
        return this._apiServicio.Get(this._checklist.URLlistapuntonevaluar+ '?idbloque=' + bloqueid  );    
      }
      eliminarclasificacion( decision ){
        return this._apiServicio.Post( decision, this._checklist.URLeliminarclasificacion );
      }
      eliminarcatalogo( decision ){
        return this._apiServicio.Post( decision, this._checklist.URLeliminarcatalogo );
      }
      eliminardescripcion( decision ){
        return this._apiServicio.Post( decision, this._checklist.URLeliminardescripcion );
      }
      eliminarpuntoevaluar( decision ){
        return this._apiServicio.Post( decision, this._checklist.URLeliminarpuntoevaluar );
      }

      obtenerListadescripcionfilstro(bloque,cat){
        let e ={
          bloqueid: bloque,
          catid: cat
        }
        return this._apiServicio.Post(e,this._checklist.URLobtenerListadescripcionfilstro)
      }

      guardarlistado(lista){
        return this._apiServicio.Post(lista,this._checklist.URLguardarlistado)
      }
      consultar(){
        return this._apiServicio.Get(this._checklist.URLconsultarchecklist)
      }

      actualizarlistado(lista){
        return this._apiServicio.Post(lista,this._checklist.URLactualizarchecklist)
      }
      eliminarlistado(lista){
        return this._apiServicio.Post(lista,this._checklist.URLeliminarlistado)
      }
      marcarEnviada(id, nEmpleado){
        return this._apiServicio.Put({id: id, nEmpleado: nEmpleado},this._checklist.URLEnviarlistado)
      }

      enviarCorreoResponsable(nombre,frecuencia,solicitante,posicion){
        return this._apiServicio.Get(this._checklist.URLEnviarCorreoResponsable
          + "?nombre=" + nombre
          + "&frecuencia=" + frecuencia
          + "&solicitante=" + solicitante
          + "&posicion=" + posicion
          )
      }

      rechazarSolicitud(id,motivo){
        return this._apiServicio.Put({id: id, motivo:motivo},this._checklist.URLRechazarLista)
      }
      autorizarSolicitud(objecAutorizacion){
        return this._apiServicio.Put(objecAutorizacion,this._checklist.URLAutorizarLista)
      }
      enviarCorreoRechazar(datos){
        return this._apiServicio.Get(this._checklist.URLEnviarCorreoRechazar
          + "?nombre=" + datos.nombre
          + "&frecuencia=" + datos.frecuencia
          + "&usuarioUltimaModificacion=" + datos.usuarioRechazo
          + "&correo=" + datos.correoSolicitante
          + "&motivoRechazo=" + datos.motivoRechazo
          )
      }
      enviarCorreoAutorizar(datos){
        return this._apiServicio.Get(this._checklist.URLEnviarCorreoAutorizar
          + "?nombre=" + datos.nombre
          + "&frecuencia=" + datos.frecuencia
          + "&usuarioUltimaModificacion=" + datos.usuarioRechazo
          + "&correo=" + datos.correoSolicitante
          )
      }
      obtenerListadoCK(nEmpleado,posicion){
        return this._apiServicio.Get(this._checklist.URLObtenerListadoCK
          + "?nEmpleado=" + nEmpleado
          + "&posicion=" + posicion
          )
      }

      ObtenerListadoPorNumeroEmpleado(nEmpleado){
        return this._apiServicio.Get(this._checklist.URLObtenerListadoPorNumeroEmpleadoCK+ "?nEmpleado=" + nEmpleado);
      }
      //chlistasupervision
      ObtenerTodosCheckList(){
        return this._apiServicio.Get(this._checklist.URLObtenerTodoscheckList);
      }

      obtenerDetalleListadoCK(id){
        return this._apiServicio.Get(this._checklist.URLObtenerDetalleListadoCK
          + "?id=" + id
          )
      }

      ObtenerCheckListAsignados(idPuesto){
        return this._apiServicio.Get(this._checklist.URLObtenerCheckListAsignados + `?idPuesto=${idPuesto}`);
      }
      //@km -TRABAJANDO AQUI
      ObtenerDataColaboradorCheckList(id){
        return this._apiServicio.Get(this._checklist.URLlistColaboradorCheckList + `?dato=${id}`)
      }

      ObtenerCheckListPorID(objConsulta){
        const {idCheckList,nEmpleado,fechaInicio,fechaFin,puestoID,estatus} = objConsulta;
        return this._apiServicio.Get(this._checklist.URLObtenerCheckListPorID + `?idCheckList=${idCheckList}&nEmpleado=${nEmpleado}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&puestoID=${puestoID}&estatus=${estatus}`);
      }

      guardaCapturaCheckList(arrGuardaChecKList){
        return this._apiServicio.Post(arrGuardaChecKList,this._checklist.URLguardaCapturaCheckList);
      }
      
      ObtenerSemanasSeleccionablesCHK(puestoID:number,frecuenciaID:number){
        return this._apiServicio.Get(this._checklist.URLObtenerSemanasSeleccionablesCHK + `?puestoID=${puestoID}&frecuenciaID=${frecuenciaID}`);
      }

      ObtenerCatalogoDeEstatus(){
        return this._apiServicio.Get(this._checklist.URLObtenerCatalogoDeEstatus);
      }

      ObtenerRelacionDeCheckLists(objConsulta){
        const {puestoID,nEmpleado,fechaInicio,fechaFin,estatus,flagTodos} = objConsulta;
        return this._apiServicio.Get(this._checklist.URLObtenerRelacionDeCheckLists + `?puestoID=${puestoID}&nEmpleado=${nEmpleado}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&estatus=${estatus}&flagTodos=${flagTodos}`);
      }

      guardarAccionesCorrectivas(arrayAccionesCorrectivas){
        return this._apiServicio.Post(arrayAccionesCorrectivas,this._checklist.URLguardarAccionesCorrectivas);
      }

      actualizaAccionesCorrectivasChecklist(arrayAccionesCorrectivas){
        return this._apiServicio.Post(arrayAccionesCorrectivas,this._checklist.URLactualizaAccionesCorrectivasChecklist);
      }

      consultarAccionesCorrectivasPorIdCheckList(objConsulta){
        return this._apiServicio.Get(this._checklist.URLconsultarAccionesCorrectivasPorIdCheckList,objConsulta);
      }

      visualizarCheckList(idChecklist: number){
        return this._apiServicio.Get(`${this._checklist.URLvisualizarCheckList}?idCheckList=${idChecklist}`);
      }

      //@Km-EVIDENCIA
    //   subirEvidencia(data) {
    //     console.log("La data que me llega al SERVICE", data);
    //     const imagen = data[0].image; // Suponiendo que la imagen está en la primera posición del array
    //     const ruta = this._checklist.URLUploadEvidencia + "?idCheckList=" + data[0].idCheckList +"&nombre="+data[0].nombre;
    //     return this._apiServicio.PostConImagen( ruta, imagen);
    // }

    subirEvidencia(data){
      return this._apiServicio.Post(data,this._checklist.URLUploadEvidencia+ "?idCheckList=" + data[0].idCheckList +"&nombre="+data[0].nombre);
    }

    subirEvidenciaAC(data){
      //console.log("data de ac service",data.idCheckList)
       return this._apiServicio.Post(data,this._checklist.URLUploadEvidenciAC+ "?idCheckList=" + data.idCheckList +"&nombre="+data.nombre);
    }

  

      // getEvidencia(data){
      //   console.log("Esta es la data",data)
      //   return this._apiServicio.Get(this._checklist.URLGetEvidenciaPorIdCheckList+`?idCheckList=${data.idCheckList}`);
      // }
      getEvidencia(idCheckList,flagEvidencia,idPuntoAEvaluar,AccionCorrectiva){
        console.log("Lo que llega al servicuo",idCheckList.idCheckList)
        return this._apiServicio.Get(this._checklist.URLGetEvidenciaPorIdCheckList+"?idCheckList="+ idCheckList.idCheckList +"&flagEvidencia="+flagEvidencia +"&idPuntoAEvaluar="+idPuntoAEvaluar +"&AccionCorrectiva="+AccionCorrectiva);
      }
      
      getAllEvidencias(){
        return this._apiServicio.Get(this._checklist.URLGetAllEvidencias);
      }

      getEvidenciaCorrectiva(idCheckList,idPuntoAEvaluar,IdAccionCorrectiva){
        return this._apiServicio.Get(this._checklist.URLGetEvidenciasCorrectiva+"?idCheckList="+ idCheckList.idCheckList +"&idPuntoAEvaluar="+idPuntoAEvaluar +"&IdAccionCorrectiva="+IdAccionCorrectiva);
      }
}