import { BrowserModule } from "@angular/platform-browser";
//import { FormsModule } from '@angular/forms';
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { ToasterService } from "angular2-toaster/angular2-toaster";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";
import { RoutesModule } from "./routes/routes.module";
import { AuthGuard } from "./guards/Auth.guard";
import { SharedDirectivesModule } from "./directives/shared-directives.module";

import { CarouselModule } from "ngx-bootstrap/carousel";
//import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from "@ng-select/ng-select";
//import { DatepickerModule,BsDatepickerModule } from 'ngx-bootstrap/datepicker';
//import {DatepickerModule} from "ngx-bootstrap";
import { PdfViewerModule } from "ng2-pdf-viewer";
// import { TooltipModule } from 'ng2-tooltip-directive';
import { MatTooltipModule } from "@angular/material";

import { MatDialogModule } from "@angular/material";


//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
//Constantes
import {
  ConfiguracionConstante,
  LoginConstante,
  ModuloConstante,
  FormularioConstante,
  UsuarioConstante,
  GrupoConstante,
  GrupoFormularioConstante,
  CatalogosContante,
  AutorizacionConstante,
  TipoCambioConstante,
  SolicitudCambioConstante,
  PuestosConstante,
  RepositorioConstante,
  EmpleadoConstante,
  LAConstante,
  MotivosInasistenciaConstante,
  UsuarioFormularioConstante,
  RelacionCentralizadorColaboradorConstante,
  JuntasConstante,
  ParametrosConstante,
  ReportesConstante,
  SesionesConstante,
  CatalogoDecisionesConstante,
  DecisionesConstante,
  RespoInerentesConstante,
  ORDOSConstante,
  CulturaConstantes,
  UnidadesDeNegocioConstante,
  CatalogoCtrlDocumentalConstante,
  ControlDocumentalConstante,
  SolicitudCtrlDocumentalConstante,
  PermisosCtrlDocConstante,
  RolesCtrlDocConstante,
  UsuariosSinDominioConstante,
  DashboardNotificacionesConstante,
  MatrizDeComunicacionConstante,
  GteArquitecturaProcesosConstante,
  PreguntasFrecuentesConstantes,
  ReporteRDCConstante,
  ChecklistConstante,
  TalleresConstante,
  ContactoConstantes,
  ComunicacionConstantes,
  RpdConstantes,
  TuberiasConstantes,
	MejoraPropuestaConstantes,
  HKConstantes,
  JuntasConstantes,
  IndiceDesempenoConstantes,
  AuditoriaCulturaConstantes,
  InformesConstantes,
  BitacoraConstante
} from "./config/constantes";

//Servicios
import {
  ApiService,
  AuthenticationService,
  LoginService,
  GrupoService,
  ModuloService,
  FormularioService,
  UsuarioService,
  TableroService,
  MenuService,
  GrupoFormularioService,
  CatalogoService,
  AutorizacionService,
  TipoCambioService,
  SolicitudCambioService,
  PuestosService,
  RepositorioService,
  EmpleadoService,
  LAService,
  MotivosInasistenciaService,
  UsuarioFormularioService,
  RelacionCentralizadorColaboradorService,
  JuntasService,
  ParametrosService,
  ReportesService,
  CatalogoDecisionesService,
  DecisionesService,
  RespoinheService,
  OrdosService,
  UnidadesDeNegocioService,
  CatalogoCtrlDocService,
  SolicitudCtrlDocumentalService,
  ControlDocumentalService,
  PermisosCtrlDocService,
  RolesControlDocumentaService,
  NotificacionesDashboardService,
  MatrizComunicacionService,
  GteArquitecturaProcesosService,
  PreguntasFrecuentesService,
  ReporteRDCService,
  ChecklistService,
  TalleresService,
  ContactoService,
  MejoraPropuestaService,
  RpdService,
  TuberiasService,
  HkService,
  AArqJuntasService,
  IndiceDesempenoService,
  AuditoriaCulturaService,
  InformesService,
} from "./services";
import { AuthInterceptorService } from "./services/auth-interceptor.service";
import { IntersectionObserverDirective } from "./directives/intersection-observer.directive";
import { ComunicacionService } from "./services/comunicacion.service";
import { BitacoraService } from "./services/bitacora.service";
//import { RelacionCentralizadorColaborador } from './routes/Administracion/control-admin-centralizadores/control-admin-centralizadores.components';
//import { SolicitudCambio } from './routes/Reportes/solicitud-cambio/solicitud-cambio.component';

const APP_CONSTANTES = [
  ConfiguracionConstante,
  LoginConstante,
  ModuloConstante,
  FormularioConstante,
  UsuarioConstante,
  GrupoConstante,
  GrupoFormularioConstante,
  CatalogosContante,
  AutorizacionConstante,
  TipoCambioConstante,
  SolicitudCambioConstante,
  PuestosConstante,
  RepositorioConstante,
  EmpleadoConstante,
  LAConstante,
  MotivosInasistenciaConstante,
  UsuarioFormularioConstante,
  RelacionCentralizadorColaboradorConstante,
  JuntasConstante,
  ParametrosConstante,
  ReportesConstante,
  SesionesConstante,
  CatalogoDecisionesConstante,
  DecisionesConstante,
  RespoInerentesConstante,
  ORDOSConstante,
  CulturaConstantes,
  UnidadesDeNegocioConstante,
  CatalogoCtrlDocumentalConstante,
  ControlDocumentalConstante,
  CatalogoCtrlDocumentalConstante,
  SolicitudCtrlDocumentalConstante,
  PermisosCtrlDocConstante,
  UnidadesDeNegocioService,
  CatalogoCtrlDocService,
  ControlDocumentalService,
  SolicitudCtrlDocumentalService,
  PermisosCtrlDocService,
  RolesControlDocumentaService,
  RolesCtrlDocConstante,
  UsuariosSinDominioConstante,
  DashboardNotificacionesConstante,
  MatrizDeComunicacionConstante,
  GteArquitecturaProcesosConstante,
  PreguntasFrecuentesConstantes,
  ReporteRDCConstante,
  ChecklistConstante,
  TalleresService,
  TalleresConstante,
  ContactoConstantes,
  ComunicacionConstantes,
  RpdConstantes,
  TuberiasConstantes,
  MejoraPropuestaConstantes,
  HKConstantes,
  JuntasConstantes,
  IndiceDesempenoConstantes,
  AuditoriaCulturaConstantes,
  InformesConstantes,
  BitacoraConstante
];

const APP_SERVICIOS = [
  ApiService,
  AuthenticationService,
  CookieService,
  ToasterService,
  LoginService,
  GrupoService,
  ModuloService,
  FormularioService,
  UsuarioService,
  TableroService,
  MenuService,
  GrupoFormularioService,
  CatalogoService,
  AutorizacionService,
  TipoCambioService,
  SolicitudCambioService,
  PuestosService,
  RepositorioService,
  EmpleadoService,
  LAService,
  MotivosInasistenciaService,
  UsuarioFormularioService,
  RelacionCentralizadorColaboradorService,
  JuntasService,
  ParametrosService,
  ReportesService,
  CatalogoDecisionesService,
  DecisionesService,
  RespoinheService,
  OrdosService,
  RolesControlDocumentaService,
  NotificacionesDashboardService,
  MatrizComunicacionService,
  GteArquitecturaProcesosService,
  PreguntasFrecuentesService,
  ReporteRDCService,
  ChecklistService,
  TalleresService,
  ContactoService,
  MejoraPropuestaService,
  ComunicacionService,
  RpdService,
  TuberiasService,
  HkService,
  AArqJuntasService,
  IndiceDesempenoService,
  AuditoriaCulturaService,
  InformesService,
  BitacoraService
];

const APP_MODULOS = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  CoreModule,
  LayoutModule,
  RoutesModule,
  SharedDirectivesModule,
  BrowserAnimationsModule,
  NgSelectModule,
  MatTooltipModule,
  CarouselModule,
  MatDialogModule,
  PdfViewerModule,
  //TooltipModule
  //FormsModule,
  //NgbModule,
  //DatepickerModule.forRoot(),
  //BsDatepickerModule.forRoot(),

  //FontAwesomeModule,
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, IntersectionObserverDirective],
  imports: [
    ...APP_MODULOS,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AuthGuard,
    ...APP_CONSTANTES,
    ...APP_SERVICIOS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
