import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

import { ComunicacionConstantes } from '../config/constantes';

@Injectable({
  providedIn: 'root'
})
export class ComunicacionService {

  constructor(private _apiServicio: ApiService,
    private _comunicacionConstantes: ComunicacionConstantes) { }

  ObtenerComunicados(usaImagen) {
    return this._apiServicio.Get(this._comunicacionConstantes.URLObtenerComunicados + "?imagen=" + usaImagen);
  }

  RegistrarComunicado(comunicado){
    return this._apiServicio.Upload(comunicado.fileToUpload, this._comunicacionConstantes.URLRegistrarComunicado
            + "?idComunicado=" + comunicado.idComunicado
            + "&titulo=" + comunicado.titulo
            + "&descripcion=" + comunicado.descripcion
            + "&url=" + comunicado.url
            + "&fechaPublicacion=" + comunicado.fechaPublicacion
            + "&nEmpleado=" + comunicado.nEmpleado
            + '&fechaRegistro=' + comunicado.fechaRegistro
            + '&imagen=' + comunicado.imagen
            + '&imagenOriginal=' + comunicado.imagenOriginal
            + '&estatus=' + comunicado.estatus
            + '&tieneimagen=' + comunicado.tieneImagen
      );
  }

  UpdateEstatusComunicado(comunicado) {
    return this._apiServicio.Put(comunicado,this._comunicacionConstantes.URLUpdateEstatusComunicado);
  }

  ObtenerBanners(usaImagen) {
    return this._apiServicio.Get(this._comunicacionConstantes.URLObtenerBanners + "?imagen=" + usaImagen);
  }

  RegistrarBanner(banner){
    return this._apiServicio.Upload(banner.fileToUpload, this._comunicacionConstantes.URLRegistrarBanner
            + "?idBanner=" + banner.idBanner
            + "&descripcion=" + banner.descripcion
            + "&url=" + banner.url
            + "&fechaPublicacion=" + banner.fechaPublicacion
            + "&nEmpleado=" + banner.nEmpleado
            + '&fechaRegistro=' + banner.fechaRegistro
            + '&imagen=' + banner.imagen
            + '&imagenOriginal=' + banner.imagenOriginal
            + '&estatus=' + banner.estatus
            + '&tieneimagen=' + banner.tieneImagen
      );
  }

  UpdateEstatusBanner(banner) {
    return this._apiServicio.Put(banner,this._comunicacionConstantes.URLUpdateEstatusBanner);
  }

  ActualizarOrdenBanner(banners) {
    return this._apiServicio.Put(banners,this._comunicacionConstantes.URLActualizarOrdenBanner);
  }
}
