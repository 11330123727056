import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { 
	ApiService 
} from "./api.service";

import {
	GrupoConstante
} from "./../config/constantes";

@Injectable()
export class GrupoService {
	constructor(
		private _grupoConstante: GrupoConstante,
		private _apiServicio: ApiService) {
    }

	ObtenerTodos() {
		return this._apiServicio.Get(this._grupoConstante.URLGrupos);
	}
	
    Crear(grupo) {
		return this._apiServicio.Post(grupo, this._grupoConstante.URLGrupos);
	}
	
	Actualizar(grupo) {
		return this._apiServicio.Put(grupo, this._grupoConstante.URLGrupos);
	}

	ObtenerPuestosGrupo() {
		return this._apiServicio.Get(this._grupoConstante.URLObtenerPuestosGrupo);
	}

	ObtenerAllPuestos() {
		return this._apiServicio.Get(this._grupoConstante.URLObtenerAllPuestos);
	}

	CrearPuestoRol(datos) {
		return this._apiServicio.Post(datos, this._grupoConstante.URLObtenerCreatePuestoRol);
	}

	EliminarPuestoRol(id) {
		return this._apiServicio.Delete(this._grupoConstante.URLObtenerDeletePuestoRol
			+ "?id_puesto_rol=" + id);
	}

	ObtenerReporteColaboradores() {
		return this._apiServicio.Get(this._grupoConstante.URLObtenerReporteColaboradores);
	}
}