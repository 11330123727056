import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import swal from 'sweetalert';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(private router: Router,private _cockieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = (this._cockieService.get('session')) ? JSON.parse(this._cockieService.get('session')) : null;
    let token: string;
    if(currentUser){
       token = currentUser.token;
    }else{
       token = ""
    }
    

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          swal('Ocurrio un error', 'Su sesión ha expirado, favor de iniciar sesión', 'error');
          this.router.navigateByUrl('/login');
        }

        return throwError( err );

      })
    );
  }
}
