import { Component, OnInit } from '@angular/core';
import { UserblockService } from './userblock.service';
import { CookieService } from 'ngx-cookie-service';
import { UsuarioService } from '../../../services/usuario.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    currentUser: any;
    nombreUsuario: string;
    constructor(public userblockService: UserblockService,private __cookieService: CookieService, private usuarioService: UsuarioService) {
        this.user = {
            picture: 'assets/img/user.png'
        };
    }

    ngOnInit() {
        this.currentUser = JSON.parse(this.__cookieService.get('session'));

        /*this.usuarioService.getTbInterfaceUsuarioMaquilasGetByUserId({ usuarioId: this.currentUser.user.ProveedorID?this.currentUser.user.ProveedorID: this.currentUser.user.UsuarioID, 
            tipoUsuario: this.currentUser.user.ProveedorID? "Proveedor": "Usuario" }).subscribe(usuarioMaquila => {
            this.nombreUsuario = this.currentUser.user.ProveedorID? usuarioMaquila.proveedor.Nombre: usuarioMaquila.usuario.Nombre;
        });*/
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
