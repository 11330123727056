import { Injectable } from '@angular/core';
import { 
    ApiService
} from "./api.service";

import {
	JuntasConstante
} from "./../config/constantes";

@Injectable()
export class JuntasService {
	constructor(
		private _constantes: JuntasConstante,
		private _apiServicio: ApiService) {
    }

    ObtenerJuntasPorPosicionID(posicion,nEmpleado) {
        return this._apiServicio.Get(this._constantes.URLJuntasObtenerJuntasPorPosicionID + '?posicion=' + posicion + `&nEmpleado= ${nEmpleado}`);
    }

    ObtenerJuntasPNoRecurrentes(correo,fechaInicio,fechaFin) {
        return this._apiServicio.Get(this._constantes.URLJuntasObtenerJuntasNoRecurrentes + '?Correo=' + correo +'&fechaInicio='+fechaInicio+'&fechaFin='+fechaFin);
    }

    ObtenerFechaInicioSemana(anio, semana) {
        return this._apiServicio.Get(this._constantes.URLJuntasObtenerFechaInicioSemana + '?anio=' + anio +"&semana="+ semana);
    }

    ObtenerjuntaCorreo(correo) {
        return this._apiServicio.Get(this._constantes.URLJuntasObtenerJuntasCorreo + '?correo=' + correo);
    }

}