import { Injectable } from "@angular/core";
import { PreguntasFrecuentesConstantes } from "../config/constantes";
import { ApiService } from "./api.service";

interface Question {
  id?: number;
  pregunta: string;
  respuesta: string;
  comentarioAdicional: string;
  herramienta: string;
  relevancia: number;
  fechaAlta: Date;
}

@Injectable()
export class PreguntasFrecuentesService {
  constructor(
    private _constantes: PreguntasFrecuentesConstantes,
    private _apiService: ApiService
  ) {}

  obtenerPreguntas() {
    const resp = this._apiService.Get(this._constantes.URLObtenerPreguntas);
    return resp;
  }

  guardarPregunta(data: Question) {
    const resp = this._apiService.Post(
      data,
      this._constantes.URLGuardarPregunta
    );
    return resp;
  }

  borrarPregunta(datos: Question) {
    const resp = this._apiService.Post(
      datos,
      `${this._constantes.URLBorrarPregunta}?id=${datos.id}`
    );
    return resp;
  }

  actualizarPregunta(datos) {
    const resp = this._apiService.Post(
      datos,
      this._constantes.URLActualizarPregunta
    );
    return resp;
  }

  enviarCorreo(datos) {
    const resp = this._apiService.Post(datos, this._constantes.URLEnviarCorreo);
    return resp;
  }
}
