import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { data } from 'jquery';
import { CookieService } from 'ngx-cookie-service';

export interface ModalDashboard {
  DataLista:any;
}

@Component({
  selector: 'app-lista-notificaciones',
  templateUrl: './lista-notificaciones.component.html',
  styleUrls: ['./lista-notificaciones.component.scss']
})
export class ListaNotificacionesComponent implements OnInit {
  Titulo:String;
  Lista:any=[];
  ListaCompromisos:any=[];
  ListaDLeer:any=[];
  ListaModoSK_Solicitante:any=[];
  ListaCC_usuario:any=[];
  ListaCC_jefe:any=[];
  Lista_PdM_Solicitante:any=[];
  Lista_PdM_Centralizador:any=[];
  Lista_PdM_DuenoProceso:any=[];
  Lista_PdM_Admin:any=[];
  Icono:string;
  liga:string;
  ligaDecisionesLeer:string;
  Rol:string;
  Hoy= new Date();

  RolIdCtrlDocu:any=[];


  constructor( 
    private __cockieService: CookieService,
    public dialogRef: MatDialogRef<ListaNotificacionesComponent>,
    @Inject(MAT_DIALOG_DATA) public DataLista: ModalDashboard) { }

  ngOnInit() {  
    this.Rol = this.DataLista['Rol']
    this.Titulo=this.DataLista['Titulo'];
    this.Icono = this.DataLista['Icono'];
    this.Lista = this.DataLista['Items'];
    this.ListaCompromisos = this.DataLista['ItemsJC'];
    this.ListaDLeer = this.DataLista['ItemsDLeer'];
    this.ListaModoSK_Solicitante = this.DataLista['ItemsModoSK_Solicitante'];
    this.ListaCC_usuario = this.DataLista['ItemsCC_usuario'];
    this.ListaCC_jefe = this.DataLista['ItemsCC_jefe'];
    this.Lista_PdM_Solicitante = this.DataLista['Items_PdM_Solicitante'];
    this.Lista_PdM_Centralizador = this.DataLista['Items_PdM_Centralizador'];
    this.Lista_PdM_DuenoProceso = this.DataLista['Items_PdM_DuenoProceso'];
    this.Lista_PdM_Admin = this.DataLista['Items_PdM_Admin'];
    console.log("Data Modal ---",this.DataLista);
    
    this.contLiga();
     
  }
  contLiga(){
  
    
    if (this.Titulo === "LA" || this.Titulo === "MODO SK") {
  /* Rutas para ORDOs y LA */
      this.liga = "controlAutorizaciones";
    } else {
      if (this.Titulo === "DECISIONES") {
        /* Rutas para Decisiones */
        if (this.Rol === "Administrador") {/* Valida que rol tiene el usuario para saber que liga poner */
          /* Se asigna la siguente ruta si es administrador */
          this.liga = "controlGestionDesiciones"
        } else {
          /* Se asigna la siguiente ruta si es Centralizador */
          this.liga = "controlAutorizaciones";
        }
        this.ligaDecisionesLeer = "controlHerramientaDesiciones";
      } else {
        if(this.Titulo == "SESIONES 1:1"){
        /* Ruta para Sesiones1:1 */
          this.liga = "controlHerramientaSesiones";
        }

        if(this.Titulo === "JUNTAS"){
          this.liga = "controlHerramientaJuntas";
        }

        if(this.Titulo === "CULTURA DE CUMPLIMIENTO"){
          this.liga = "documentacionImpulsores#cultura";
        }
      }

      if(this.Titulo === "JUNTAS"){
        this.liga = "controlHerramientaJuntas";
      } else {

      }
    }
  }
  
  CloseItems(){
    this.dialogRef.close(ListaNotificacionesComponent);
  }

  redireccionarLeerDecision(idDecision){
    console.log(idDecision)
    let date = new Date(); date.setTime(date.getTime() + 86400000);

  
  }

  redireccionarOrdo(IdOrdo){
    console.log(IdOrdo)

    
  }

  redireccionarHerramienta(Herramienta, Id, Compromiso, Rol){

    let date = new Date(); date.setTime(date.getTime() + 86400000);

    switch(Herramienta){

      case 'DECISIONES':

        let tabInfoD = {
          tabNum: 2,
          tabDescripción: 'LeerDecision',
          idDecision: Id
        }
    
        this.__cockieService.set('tabLeerDecision', JSON.stringify(tabInfoD), date, '/',null, false, 'Lax');
        const newRouteD = 'controlHerramientaDesiciones';
        let urlD = document.URL;
        let routeD = urlD.split('/')[urlD.split('/').length - 1];
        let cleanURLD = urlD.replace(routeD,'');
        window.location.replace(`${cleanURLD}${newRouteD}`);

      break;

      case 'DECISIONES AUTORIZAR':

      let liga = "";
      let tabNum = 0;

      if (this.Rol === "Administrador") {
          /* Se asigna la siguente ruta si es administrador */
          liga = "controlGestionDesiciones";
          tabNum = 1;
        } else {
          /* Se asigna la siguiente ruta si es Centralizador */
          liga = "controlAutorizaciones";
          tabNum = 4;
        }

      let tabInfoDA = {
        tabNum: tabNum,
        tabDescripción: 'DecisionSK',
        idDecision: Id
      }
      console.log(tabInfoDA)
      this.__cockieService.set('tabDecisionSK', JSON.stringify(tabInfoDA), date, '/',null, false, 'Lax');
      const newRouteDA = liga;
      let urlDA = document.URL;
      let routeDA = urlDA.split('/')[urlDA.split('/').length - 1];
      let cleanURLDA = urlDA.replace(routeDA,'');
      window.location.replace(`${cleanURLDA}${newRouteDA}`);

      break;

      case 'ORDOs':

        let tabInfoO = {
          IdOrdo: Id
        }
    
        this.__cockieService.set('AutorizacionesOrdo', JSON.stringify(tabInfoO), date, '/',null, false, 'Lax');
        const newRouteO = 'controlAutorizaciones';
        let urlO = document.URL;
        let routeO = urlO.split('/')[urlO.split('/').length - 1];
        let cleanURLO = urlO.replace(routeO,'');
        window.location.replace(`${cleanURLO}${newRouteO}`);

      break;
    
      case 'SESIONES 1:1':

        let tabInfoS = {
          tabNum: 2,
          tabDescripción: 'CapturaRendicionUsuario',
          idSesiones11: Id,
          Compromiso: Compromiso
        }

        this.__cockieService.set('tabCapturaRendicionUsuario', JSON.stringify(tabInfoS), date, '/',null, false, 'Lax');
        const newRouteS = 'controlHerramientaSesiones';
        let urlS = document.URL;
        let routeS = urlS.split('/')[urlS.split('/').length - 1];
        let cleanURLS = urlS.replace(routeS,'');
        window.location.replace(`${cleanURLS}${newRouteS}`);
  
      break;

      case 'LA':

      let tabInfoLA = {
        IdLA: Id
      }

      this.__cockieService.set('AutorizacionesLA', JSON.stringify(tabInfoLA), date, '/',null, false, 'Lax');
      const newRouteLA = 'controlAutorizaciones';
      let urlLA = document.URL;
      let routeLA = urlLA.split('/')[urlLA.split('/').length - 1];
      let cleanURLLA = urlLA.replace(routeLA,'');
      window.location.replace(`${cleanURLLA}${newRouteLA}`);

      break;

      case 'MODO SK SOLICITANTE':

      let tabInfoModoSKS = {
        tabNum: 2,
        tabDescripción: 'CapturaModoSKS',
        idModoSK_S: Id
      }

      this.__cockieService.set('tabModoSKS', JSON.stringify(tabInfoModoSKS), date, '/',null, false, 'Lax');
      const newRouteModoSKS = 'solicitudCambio';
      let urlModoSKS = document.URL;
      let routeModoSKS = urlModoSKS.split('/')[urlModoSKS.split('/').length - 1];
      let cleanURLModoSKS = urlModoSKS.replace(routeModoSKS,'');
      window.location.replace(`${cleanURLModoSKS}${newRouteModoSKS}`);

      break;

      case 'MODO SK APROBADOR':

      console.log(Rol)
      switch(Rol){
        case 'Aprobador':
          this.RolIdCtrlDocu = 3;
        break;
        case 'Centralizador':
          this.RolIdCtrlDocu = 4;
        break;
        case 'Responsable SGC':
          this.RolIdCtrlDocu = 5;
        break;
        case 'Líder Método Auditoria':
          this.RolIdCtrlDocu = 6;
        break;
        case 'Especialista técnico':
          this.RolIdCtrlDocu = 7;
        break;
        case 'Gerente de Arquitectura':
          this.RolIdCtrlDocu = 8;
        break;
        case 'Gerente de Auditoría de Procesos':
          this.RolIdCtrlDocu = 10;
        break;
        case 'Gte de Modelo de Gestión':
          this.RolIdCtrlDocu = 11;
        break;
        default:
        break;
      }

      let tabInfoModoSKA = {
        tabNum: 2,
        tabDescripción: 'CapturaModoSKA',
        idModoSK_A: Id,
        tabRolId: this.RolIdCtrlDocu
      }
      console.log(tabInfoModoSKA)
      this.__cockieService.set('tabModoSKA', JSON.stringify(tabInfoModoSKA), date, '/',null, false, 'Lax');
      const newRouteModoSKA = 'controlAutorizaciones';
      let urlModoSKA = document.URL;
      let routeModoSKA = urlModoSKA.split('/')[urlModoSKA.split('/').length - 1];
      let cleanURLModoSKA = urlModoSKA.replace(routeModoSKA,'');
      window.location.replace(`${cleanURLModoSKA}${newRouteModoSKA}`);

      break;

      case 'CC USUARIO JEFE':

      let tabInfoCCUJ = {
        tabNum: 2,
        tabDescripción: 'CapturaCCUJ',
        idCCSK: Id
      }

      this.__cockieService.set('tabCCUJ', JSON.stringify(tabInfoCCUJ), date, '/',null, false, 'Lax');
      const newRouteCCUJ = 'documentacionImpulsores#cultura';
      let urlCCUJ = document.URL;
      let routeCCUJ = urlCCUJ.split('/')[urlCCUJ.split('/').length - 1];
      let cleanURLCCUJ = urlCCUJ.replace(routeCCUJ,'');
      window.location.replace(`${cleanURLCCUJ}${newRouteCCUJ}`);

      break;

      case 'CC RH':

      let tabInfoCCRH = {
        tabNum: 3,
        tabDescripción: 'CapturaCCRH',
        idCCSK: Id
      }

      this.__cockieService.set('tabCCRH', JSON.stringify(tabInfoCCRH), date, '/',null, false, 'Lax');
      const newRouteCCRH = 'documentacionImpulsores#cultura';
      let urlCCRH = document.URL;
      let routeCCRH = urlCCRH.split('/')[urlCCRH.split('/').length - 1];
      let cleanURLCCRH = urlCCRH.replace(routeCCRH,'');
      window.location.replace(`${cleanURLCCRH}${newRouteCCRH}`);

      break;

      case 'PROPUESTA DE MEJORA':

      let RutaNew = '';

     if(Rol == 'SOLICITANTE') {
        RutaNew = 'solicitudCambio';
      } else {
        RutaNew = 'controlPropuestasMejoras';
      }

      let tabInfoPropuestadMejora = {
        tabNum: 2,
        tabDescripción: 'CapturaPropuestadMejora',
        idModoPropuestadMejora: Id
      }
      console.log(tabInfoPropuestadMejora)
      this.__cockieService.set('tabInfoPropuestadMejora', JSON.stringify(tabInfoPropuestadMejora), date, '/',null, false, 'Lax');
      const newRoutePropuestadMejora = RutaNew;
      let urlPropuestadMejora = document.URL;
      let routePropuestadMejora = urlPropuestadMejora.split('/')[urlPropuestadMejora.split('/').length - 1];
      let cleanURLroutePropuestadMejora = urlPropuestadMejora.replace(routePropuestadMejora,'');
      window.location.replace(`${cleanURLroutePropuestadMejora}${newRoutePropuestadMejora}`);

      break;

      default:
    }
  }
}