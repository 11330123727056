import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

import { AuditoriaCulturaConstantes } from '../config/constantes';

@Injectable()
export class AuditoriaCulturaService {

  constructor(private _apiServicio: ApiService,
    private _auditoriaConstantes: AuditoriaCulturaConstantes) { }


  CreateBlock(datos) {
    return this._apiServicio.Post(datos,this._auditoriaConstantes.URLCreateBlock);
  }

  GetListBlocks(){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListBlocks)
  }

  DeleteBlock(datos) {
    return this._apiServicio.Put(datos,this._auditoriaConstantes.URLDeleteBlock);
  }

  CreatePeriod(datos) {
    return this._apiServicio.Post(datos,this._auditoriaConstantes.URLCreatePeriod);
  }

  GetListPeriod(){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListPeriod)
  }

  GetListContempla(){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListContempla)
  }

  GetListSelect(){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListSelect)
  }

  GetListAnioConfig(){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListAnioConfig)
  }

  GetListPeriodConfig(anio){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetListPeriodConfig + '?anio=' + anio)
  }

  GetPeriodFilter(anio,idCatPEriodo,nEmpleado,numSemana){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetPeriodFilter
         + '?anio=' + anio
         + '&id_catPeriodo='+ idCatPEriodo
         + '&nEmpleado='+ nEmpleado
         + '&numSemana='+ numSemana)
  }

  GetResultContempla(nEmpleado,idperiodo,idDetPeriodo,idContempla){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetResultContempla
         + '?nEmpleado=' + nEmpleado
         + '&idperiodo='+ idperiodo
         + '&idDetPeriodo='+ idDetPeriodo
         + '&idContempla='+ idContempla)
  }

  GetNumSemana(fecha){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetNumSemana
         + '?fecha=' + fecha)
  }

  ObtenerDocumento(ruta) {
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetObtenerDocument +
      '?Ruta=' + ruta);
  }

  GetPermisoColaborador(nEmpleado){
    return this._apiServicio.Get(this._auditoriaConstantes.URLGetPermissionColaborador
        + '?nEmpleado='+ nEmpleado);
}
  
}
