import { Injectable } from '@angular/core';
// import { Observable, of } from 'rxjs';
// import { map } from "rxjs/operators";
// import { CookieService } from 'ngx-cookie-service';

import { 
	ApiService 
} from "./api.service";

import {
	FormularioConstante,
	UsuarioConstante,
	UsuariosSinDominioConstante,
	EmpleadoConstante,
} from "./../config/constantes";

@Injectable()
export class UsuarioService {
	constructor(
		private _formularioConstante: FormularioConstante,
		private _usuarioConstante: UsuarioConstante,
		private _apiServicio: ApiService,
		private _usuarioSinConstante: UsuariosSinDominioConstante,
		private _empleadoConstante: EmpleadoConstante,) {
	}
	
	consultarCentralizador(nPosicion) {
		return this._apiServicio.Get(this._usuarioConstante.URLconsultarCentralizador + nPosicion);
	}
	
	ObtenerUsuarios() {
		return this._apiServicio.Get(this._usuarioConstante.URLUsuarios);
	}
	
	ObtenerUsuarioPorID(UsuarioID) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerUsuariosID + UsuarioID);
	}

	ObtenerUsuarioPorNumeroEmpleado(NumeroEmpleado) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerUsuarioPorNumeroEmpleado + NumeroEmpleado);
	}

	ObtenerEmpleado(NumeroEmpleado) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerNumeroEmpleado + NumeroEmpleado);
	}

	ObtenerEmpleadoPorPosicionID(PosicionID) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerEmpleadoPorPosicionID + PosicionID);
	}

	ObtenerGrupoUsuario(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerGrupoEmpleado + NumeroEmpleado);
	}

	ObtenerColaboradoresACargo(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerColaboradoresACargo + NumeroEmpleado);
	}

	obtenerNoJefe(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerNoJefe + NumeroEmpleado);
	}

	ActualizarPassword(usuario) {
		return this._apiServicio.Post(usuario, this._usuarioConstante.URLActualizarPassword);
	}

	ResetearPassword(usuario) {
		return this._apiServicio.Post(usuario, this._usuarioConstante.URLResetearPassword);
	}

	ActualizarGrupo(usuario) {
		return this._apiServicio.Post(usuario, this._usuarioConstante.URLUActualizaUsuarios);
	}
	
	getUnselect() {
		return this._apiServicio.Get(this._formularioConstante.URLModulosSinSeleccionar);
	}

	ObtenerUsuariosActivos(activo) {
		return this._apiServicio.Get(this._usuarioConstante.URLUsuariosActivos + activo);
	}

	ObtenerRelacionCentralizador(centralizador) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerRelacionCentralizador + centralizador);
	}

	ObtenerColaboradoresSinCentralizador() {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerColaboradoresSinCentralizador);
	}

	ObtenerUsuariosPorDireccionID(direccionid) {
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerUsuariosPorDireccionID + direccionid);
	}

	ObtenerUsuarioSinPorNumeroEmpleado(numEmpleado) {
		return this._apiServicio.Get(this._usuarioSinConstante.URLObtenerEmpleadoSinUser + "?NumeroEmpleado=" + numEmpleado);
	}

	GuardarDatosSin(datos) {
		return this._apiServicio.Post(datos, this._usuarioSinConstante.URLObtenerEmpleadoSinGuardar);
	}

	ObtenerEmpleadoPorNombre(NombreEmpleado) {
		return this._apiServicio.Get(this._empleadoConstante.URLEmpleadoConsultaPorNombre  + '?nombreEmpleado=' + NombreEmpleado);
	}

	AsignarColaboradorAcargo(datosAsignacion)
	{
		return this._apiServicio.Post(datosAsignacion, this._usuarioConstante.URLAsignarColaboradorAcargo);
	}

	ObtenerColaboradoresTempAcargo(Posicion)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerColaboradoresTempACargo + Posicion);
	}

	consultarrhpermisos(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLconsultarrhpermisos + NumeroEmpleado);
	}

	consultarpermisosdireciones(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLconsultarpermisosdireciones + NumeroEmpleado);
	}

	consultarpermisosarea(NumeroEmpleado)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLconsultarpermisosarea + NumeroEmpleado);
	}

	ObtenerColaboradoresTempLibres()
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerColaboradoresTempLibres);
	}

	guardardato(elem)
	{

		const datos = {
			iddir: elem.iddir,
			idarea: elem.idarea,
			activo: elem.activo,
			noempleado: elem.noempleado

		  }

		return this._apiServicio.Post(datos,this._usuarioConstante.URLguardardato);
	}

	actualizarPermisoRH(elem){
		const datos = {
			iddir: elem.iddir,
			idarea: elem.idarea,
			activo: elem.activo,
			noempleado: elem.noempleado

		  }
		return this._apiServicio.Post(datos,this._usuarioConstante.URLactualizarPermisoRH);

	}
	actualizarPermisoRHGR(elem){
		const datos = {
			iddir: elem.iddir,
			idarea: elem.idarea,
			activo: elem.activo,
			noempleado: elem.noempleado

		  }
		return this._apiServicio.Post(datos,this._usuarioConstante.URLEliminarRh);

	}
	ObtenerJefeTemporal(Posicion)
	{
		return this._apiServicio.Get(this._usuarioConstante.URLObtenerJefeTemporal + Posicion);
	}

	ActualizaPassSin(datos) {
		return this._apiServicio.Post(datos, this._usuarioSinConstante.URLObtenerEmpleadoSinActualizaPassword);
	}

}
